.row-hash {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
    border: 0.75px solid #333946;
    background: #FFF;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    gap: 10px;
}

.text-hash {
    color: var(--body-text, #8e97a8);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.title-multisend {
    font-size: 13px;
    color: #ff4d4fd9;
    font-style: normal;
    font-family: Work Sans;
}

.input-round .multi {
    padding-bottom: 5px;
}

.section-check-balance.mar-top-30 {
    padding-top: 70px;
}

.zero-address {
    display: flex;
    color: #fff;
    align-items: center;
}

.zero-address img {
    width: 18px;
}

.zero-address img:hover {
    cursor: pointer;
}

.btn-action-start {
    border-radius: 6px;
    background: #4a6efb;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 11px 20px;
    border: 1px solid transparent;
    width: 100px;
    cursor: pointer;
}

.btn-action-stop {
    border-radius: 6px;
    background: #f04848;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 11px 20px;
    border: 1px solid transparent;
    width: 100px;
    cursor: pointer;
}

.ant-popover-content .ant-popover-inner {
    border-radius: 6px !important;
    border: 1px solid #282c34 !important;
    background: #1c1f25 !important;
}

.ant-popover .ant-popover-arrow:before {
    background: #1c1f25 !important;
}

.round-btn .btn-add {
    display: flex;
    align-items: center;
}

.round-btn img {
    width: 15px;
    margin-right: 5px;
}

.section-detect-token .ant-tabs-nav-list {
    position: absolute;
    top: 0;
}

.round-tabs-detect {
    margin-top: -75px;
}

.round-tabs-detect .ant-tabs-content {
    margin-top: 40px;
}

.round-tabs-detect .ant-tabs-nav-list {
    width: 400px;
}

.section-check-balance .round-check-balance {
    border-radius: 20px;
    border: 0.7px solid #DEEBED;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    padding: 30px 30px 30px 30px;
}

.section-check-balance .round-check-balance .title {
    background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 20px;
}

.mar-b-20 {
    margin-bottom: 20px;
}

.group-upload {
    display: flex;
    justify-content: space-between;
}

.group-upload .btn-up {
    border-radius: 6px;
    background: #282c34;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 11px 15px;
    border: 1px solid transparent;
    width: 150px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.group-upload .btn-up img {
    margin-right: 5px;
}

.ant-upload-list-item-container .ant-upload-list-item-name {
    color: #fff;
}

.ant-upload-list-item-container svg {
    fill: #fff !important;
}

.ant-upload-list-item-container .ant-btn-icon svg {
    fill: #fff !important;
}

.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-done:hover .ant-btn-icon {
    transform: scale(1);
}

.section-generator {
    border-radius: 20px;
    border: 0.7px solid #DEEBED;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    padding: 30px 30px 30px 30px;
}

.section-generator .row-generator .title {
    color: #fff;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 5px 10px;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
}

.section-generator .row-generator .title-row {
    background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 20px;
}

.section-generator .row-generator .title-row span {
    background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Work Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.511%;
    letter-spacing: 0.36px;
}
.mar-top-30 {
    margin-top: 30px;
}
.section-generator .row-generator .row-warning {
    border-radius: 6px;
    background: rgba(245, 101, 101, 0.05);
    padding: 20px 25px;
}

.section-generator .row-generator .row-warning .box-img img {
    width: 24px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
}

.section-generator .row-generator .row-warning .text {
    color: var(--warning, #dc3545);
    text-align: center;
    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 127.3%;
}

.section-generator .row-generator .row-cash-fee .box-cash {
    background: #1c1f25;
    color: #fff;
    padding: 35px;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.section-generator .row-generator .row-cash-fee .box-cash.active {
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
}

.section-generator .row-generator .row-cash-fee .box-cash.active .content-l {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.section-generator .row-generator .row-cash-fee .box-cash.active .content-l .desc {
    color: #fff;
}

.section-generator .row-generator .row-cash-fee .box-cash.active .btn-fee {
    background: #fff;
    color: #5182fe;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-l {
    width: 50%;
    border-right: 1px solid #333946;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-l .fee-round {
    color: #fff;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 5px 10px;
    width: fit-content;
    margin-right: auto;
    margin-bottom: 25px;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-l .balance {
    color: #fff;
    font-family: Work Sans;
    font-size: 90px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
    margin-bottom: 5px;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-l .desc {
    color: var(--body-text, #8e97a8);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 126.684%;
    /* 20.269px */
    letter-spacing: 0.16px;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-r {
    width: 50%;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-r .list-checked {
    padding-left: 15px;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-r .list-checked li {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-r .list-checked li:last-child {
    padding-bottom: 0;
}

.section-generator .row-generator .row-cash-fee .box-cash .content-r .list-checked li img {
    width: 20px;
    display: block;
    margin-right: 10px;
}

.group-button-fee {
    margin-top: 20px;
    width: 100%;
}

.group-button-fee .btn-fee {
    border-radius: 6px;
    background: #282c34;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 11px 40px;
    border: 1px solid transparent;
    width: 100%;
    cursor: pointer;
}

.section-detail-generator {
    border-radius: 20px;
    border: 0.7px solid #DEEBED;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    padding: 30px 30px 30px 30px;
    margin-top: 120px;
}

.section-detail-generator .title {
    background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 20px;
}

.section-detail-generator .text-eg {
    color: var(--body-text, #8e97a8);
    font-family: Work Sans;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}

.section-detail-generator .text-eg .address {
    color: #fff;
    font-family: Work Sans;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
}

.box-detail-balance {
    border-radius: 10px;

    background: linear-gradient(274.71deg, #69b3bf 4.29%, #57dce6 97.42%);
    padding: 35px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box-detail-balance.res {
    padding: 20px 50px;
}

.box-detail-balance.res .item:nth-child(1)::before {
    display: none;
}

.box-detail-balance.res .item:nth-child(2)::before {
    display: none;
}

.box-detail-balance.res .item .text.res {
    color: #04d482;
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border-radius: 4px;
    background: rgba(4, 212, 130, 0.1);
    padding: 5px;
}

.box-detail-balance .item {
    position: relative;
}
/* 
.box-detail-balance .item:nth-child(1)::before {
    content: "";
    width: 1px;
    height: 100%;
    background: #333946;
    position: absolute;
    right: -68%;
    top: 0;
}

.box-detail-balance .item:nth-child(2)::before {
    content: "";
    width: 1px;
    height: 100%;
    background: #333946;
    position: absolute;
    right: -68%;
    top: 0;
} */

.box-detail-balance .item .row-name {
    display: flex;
    align-items: center;
}

.box-detail-balance .item .row-name .name {
    color: #000;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.2px;
    margin-right: 5px;
}

.box-detail-balance .item .row-name .text {
    color: var(--body-text, #fff);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.box-detail-balance .item .row-name img {
    width: 20px;
    display: block;
}

.section-success-wallet {
    margin-top: 30px;
}

.section-success-wallet .box-img {
    text-align: center;
    margin-bottom: 30px;
}

.section-success-wallet .box-img img {
    width: 42px;
    display: block;
    margin: 0 auto;
}

.section-success-wallet .title {
    color: #fff;
    text-align: center;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.18px;
    margin-bottom: 5px;
}

.section-success-wallet .desc {
    color: var(--body-text, #8e97a8);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
}

.section-success-wallet .box-qrcan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #282c34;
    background: #1c1f25;
    color: #fff;
    padding: 20px;
}

.section-success-wallet .box-qrcan .left {
    width: 150px;
    background: #fff;
    padding: 5px;
    border-radius: 6px;
}

.section-success-wallet .box-qrcan .right {
    width: calc(100% - 170px);
}

.section-success-wallet .box-qrcan .right .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.section-success-wallet .box-qrcan .right .item:last-child {
    margin-bottom: 0;
}

.section-success-wallet .box-qrcan .right .item .flex-l {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 0.75px solid #333946;
    background: #222531;
    padding: 10px;
    width: 93%;
}

.section-success-wallet .box-qrcan .right .item .flex-l .txt-l {
    color: var(--body-text, #8e97a8);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.section-success-wallet .box-qrcan .right .item .flex-l .txt-l img {
    width: 12px;
    margin-right: 5px;
}

.section-success-wallet .box-qrcan .right .item .flex-l .txt-r {
    color: #fff;
    text-align: right;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.section-success-wallet .box-qrcan .right .item .flex-r {
    width: 5%;
}

.section-success-wallet .box-qrcan .right .item .flex-r .btn-copy {
    background: transparent;
    width: 41px;
    height: 41px;
    border: 0px solid transparent;
    padding: 0;
    cursor: pointer;
}

.section-success-wallet .box-qrcan .right .item .flex-r .btn-copy img {
    width: 41px;
}

.round-check-balance .title {
    color: #fff;
    text-align: center;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-bottom: 25px;
}

.round-check-balance .item-form span {
    color: #000;
    text-align: left;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.14px;
}

.round-check-balance .item-form .ant-input-number {
    width: 100%;
}

.round-check-balance .item-form .ant-input-number input {
    border-radius: 6px;
    border: 0.75px solid #333946;
    background: #222531;
    height: 40px;
    color: #8e97a8;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}

.round-check-balance .item-form .txt {
    color: #000;
    text-align: left;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    line-height: 32px !important;
}

.item-form .txt {
    color: #000;
    text-align: left;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    line-height: 32px !important;
}

.round-check-balance .item-form .input-round .row-line {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.round-check-balance .item-form .input-round .row-line .text-line {
    width: 50%;
    border-radius: 10px 0px 0px 10px;
    background: #2b2e3e;
    padding: 16px 10px;
    color: var(--body-text, #8e97a8);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}

.round-check-balance .item-form .input-round .row-line .res-form {
    width: 50%;
    height: 50px;
}

.round-check-balance .item-form .input-round .row-line .res-form input {
    height: 50px !important;
    border-radius: 0 6px 6px 0;
    border: 0px solid #333946 !important;
    margin-left: -1px;
    height: 50px;
}

.btn-add {
    height: auto;
    appearance: none;
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
    border-radius: 20px;
    border: 0;
    font-family: AeonikRegular !important;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}

.btn-add.click-now {
    height: auto;
    appearance: none;
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
    border-radius: 20px;
    border: 0;
    font-family: AeonikRegular !important;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}
.btn-add.click-now:disabled{
    opacity: 0.5;
}
.btn-add.w-180 {
    width: 200px;
}

.ant-input {
    width: 100%;
    height: 40px;
    border: none;
    font-family: AeonikRegular !important;
    border-radius: 6px;
    background: transparent;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10) inset;
    padding-left: 15px;
    color: #000 !important;
    border: none;
}
.title-multisend {
    padding: 20px 0;
    display: block;
    text-align: left;
}
.group-btn  {
    text-align: center;
    margin-top: 20px;
}

.main-section {
    padding-bottom: 130px;
    padding-top: 40px;
    background-position: right !important;
    background-size: auto !important;
}
.w-200 {
    width: 250px !important;
}
.mar-top-20 {
    margin-top: 20px;
}
@media screen and (max-width: 768px) {
    .box-detail-balance {
        display: block;
        padding: 15px !important;
    }
    .section-detail-generator{
        margin-top: 70px;
        padding: 15px;
    }
    .box-detail-balance.res .item .text.res{
        color: #fff;
    }
    .section-check-balance.mar-top-30{
        margin-top: 70px !important;
        padding-top: 0px !important;
    
    }
    .round-check-balance .item-form span{
        overflow: hidden;
    }
    .section-check-balance .round-check-balance{
        padding: 15px;
    }
}

