.anti-bot-block {
    padding: 20px;
    max-width: 100%;
    min-height: 160px;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    border-radius: 12px;
    margin: 30px 0;
}
.anti-bot-block .steps-content-antibot {
    width: 100%;
}
.token-info-anti {
    width: 100%;
    padding: 15px;
    background: #EDF3F4;
    border-radius: 10px;
}
.token-info-anti .token-name {
    width: 100%;
    text-align: left;
    margin-top: 10px;
}
.token-info-anti .token-value {
    width: 100%;
    text-align: right;
    font-family: AeonikMedium;
    font-size: 16px;
    margin-top: 10px;
}
.btn-content {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
.token-info-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 7px;
}
