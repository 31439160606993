.ico-list-page{
    background: url(../common/asset/images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;
}
.project-block {
    width: 100%;
    padding-top: 30px;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.project-content {
    padding-bottom: 50px;
}
.project-title {
    font-family: SVN_GilroyBold;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #121528;
    margin-bottom: 20px;
}
.project-title .btn-create-ido {
    position: absolute;
    right: 30px;
    margin-top: -40px;
    font-size: 14px !important;
}
.projects-tab {
    width: 100%;
}

@media screen and (max-width: 768px) {
}
