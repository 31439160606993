.token-created{
    width: 100%;
}
.token-created .message{
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 15px;
    max-width: 300px;
   
}
.token-created .btn-list{
    width: 100%;
    text-align: center;
    margin-top: 15px;
}
.token-created .btn-list button:nth-child(2){
    margin-left: 10px;
    margin-right: 10px;
}
.max-with-400{ max-width: 400px; margin: auto;}