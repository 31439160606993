// .c-footer {
//     background-color: #22252F;
//     transition: .3s ease-in-out;
//     width: 226px;
//     &.is-toggle {
//         width: 245px;
//         position: absolute;
//         left: 95px;
//         bottom: 5px;
//         border-radius: 10px;
//         box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
//         max-height: 0;
//         &.is-show {
//             max-height: 165px;
//         }
//         .c-footer__content {
//             padding: 20px;
//             width: 100%;
//         }
//     }
//     &__content {
//         padding: 25px 0 20px;
//     }
//     &__social {
//         width: 100%;
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         justify-content: space-between;
//         margin: 0;
//         li {
//             width: 20px;
//             height: 20px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             a {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 svg {
//                     path {
//                         fill: #FDFCFF;
//                         transition: .3s ease-in-out;
//                     }
//                 }
//                 &:hover {
//                     svg {
//                         path {
//                             fill: #2AA0DA;
//                         }
//                     }
//                 }
//                 &.is-cryptorank {
//                     svg {
//                         width: 100%;
//                         rect {
//                             fill: rgba(255, 255, 255, 0.15);
//                             transition: .3s ease-in-out;
//                         }
//                     }
//                     &:hover {
//                         svg {
//                             rect {
//                                 fill: #0587FF;
//                             }
//                             path {
//                                 fill: #fff;
//                             }
//                         }
//                     }
//                 }
//                 &.is-youtube {
//                     &:hover {
//                         svg {
//                             path {
//                                 fill: #FF0000;
//                             }
//                         }
//                     }
//                 }
//                 &.is-face {
//                     &:hover {
//                         svg {
//                             path {
//                                 fill: #2A5297;
//                             }
//                         }
//                     }
//                 }
//                 &.is-m {
//                     &:hover {
//                         svg {
//                             path {
//                                 fill: #000;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     &__price {
//         width: 100%;
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         justify-content: space-between;
//         margin: 25px 0 0;
//         .bg-logo{
//             width: 30px;
//             height: 30px;
//             margin-right: 8px;
//             background-color: #fff;
//             border-radius: 99px;
//         }
//         span {
//             display: flex;
//             flex-wrap: wrap;
//             align-items: center;
//             justify-content: space-between;
//             font-size: 18px;
//             line-height: 18px;
//             color: #FDF5D3;
//             font-weight: bold;
//             img {
//                 display: block;
//                 width: 100%;
//                 height: 100%;
//                 margin-right: 5px;
//                 padding:5px
//             }
//         }
//         a {
//             display: block;
//             font-size: 14px;
//             line-height: 1;
//             color: #FFD60A;
//             text-decoration: underline;
//             transition: .3s ease-in-out;
//             text-underline-offset: 2px;
//             &:focus {
//                 text-decoration: underline;
//             }
//             &:hover {
//                 color: #C8A703;
//                 text-decoration: underline;
//             }
//         }
//     }
//     &__row {
//         width: 100%;
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         justify-content: space-between;
//         margin-top: 25px;
//         span {
//             display: block;
//             font-size: 14px;
//             line-height: 1;
//             color: #C4C4C4;
//         }
//     }
// }

.c-footerHome {
    background: #F1F6FA;
    width: 100%;
    margin: auto;
    &_sologun {
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: AeonikRegular !important;
        font-size: 17px;
    
    }
    .footer-token{
        border-radius: 20px;
        background: #DDE6EC;
        padding: 15px;
        min-height: 150px;
        margin-top: 10px;
        .token-add{
            margin-bottom: 10px;

            .token-name-footer{
                color: #3F666B;
                font-family: AeonikMedium;
                font-size: 16px;
            }
            .token-price-footer{
                color: #3F666B;
                font-family: AeonikRegular;
                font-size: 16px;
            }
        }
        .token-row{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            &:nth-last-child(1){
                margin-bottom: 0px !important;
            }
            span{
                color: #3F666B;
                font-family: AeonikRegular;
                font-size: 16px;
                &:nth-last-child(1){
                    font-family: AeonikMedium !important;
                }
            }
        }
    }
    ul.footer-social{
        margin-top: 15px;
        li{
            display: inline;
            margin-right: 10px;

            span{
                color: #3F666B;
                font-family: AeonikRegular !important;
                font-size: 16px !important;
            }
        }
    }
    @include tablet() {
        width: 100%;
        margin: auto;
    }
    @include sp {
        width: 100%;
        margin: auto;
    }
    &__link {
        padding: 60px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include tablet() {
            padding: 25px 50px;
        }
        @include sp {
            padding: 20px 0px;
            justify-content: space-between;
            display: flex;
        }
    }
    &__row {
        width: calc(100%/6);
        padding-right: 20px;
        @include tablet() {
            width: calc(100%/4);
        }
        @include sp() {
            width: 44%;  
        }
        &.w-2{
            width: calc(100%/3);
            @include tablet() {
                width: 100%;
                margin-bottom: 20px;
            } 
            @include sp {
                margin-bottom: 0px;
                padding-right: 0px;

            }
        }
        + .c-footerHome__row {
            @include sp {
                margin-top: 35px;
            }
        }
        p { 
            color: #0A2C31;
            font-family: AeonikMedium;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0.4px;
            @include sp {
                margin-bottom: 30px;
            }
        }
        ul {
            display: block;
            list-style-type: none; 
            margin-bottom: 0;
            li {
                display: flex;
                + li {
                    margin-top: 15px;
                }
                a {
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.025em;
                    color: #3F666B;
                    font-family: AeonikRegular;
                    transition: .3s ease-in-out;
                    &:hover {
                        color: #36cfea;
                    }
                }
            }
        }
    }
    &__copy {
        background: #0C434B;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFF;
        padding: 20px;
        margin: 0;
        @include tablet() {
            font-size: 14px;
            padding: 13px;
        }
    }
}