.input-round .two-input {
  display: flex;
  align-items: center;
  width: 100%;
}
.input-round .two-input .space-center {
  margin: 0 5px 0 5px;
}

.btn-pick {
  color: rgba(97, 136, 255, 1);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 4px;
  padding: 5px;
  border: none;
  background: rgba(97, 136, 255, 0.1);
  height: 35px;
  width: 90px;
  cursor: pointer;
}
.full-modal {
  width: max-content !important;
}
.btn-copy.custon-gen-wallet {
  margin: 5px 0 0 5px;
}
.text-blue {
  color: #5182fe;
  font-weight: 400;
}
.modal-offline .ant-modal-content {
  border-radius: 10px;
  border: 1px solid #333946;
  background: #13161b;
}
.modal-offline .ant-modal-header {
  background: #13161b;
  color: rgb(255, 255, 255);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.2px;
}
.modal-offline .ant-modal-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}
.modal-offline .ant-modal-body {
  color: rgb(255, 255, 255);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.2px;
}
.modal-offline svg {
  fill: #fff;
}
.gen-warning {
  gap: 5px;
  display: flex;
  align-items: center;
}
.gen-warning img {
  width: 20px;
}
.section-generator {
  margin-top: 120px;
}
.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.colum {
  padding: 10px;
}
.w-6 {
  width: 50%;
}
.choice-network-list{
  display: flex;
  margin-top: 10px;
}
.choice-network-list button.network-choice{
  margin-right: 10px;
  background: #ebf0f6bb;
  color: #3F666B;
  font-family: AeonikRegular !important;
  font-size: 16px !important;
  border: none;
  padding: 5px 15px;
  padding-left: 7px !important;
  border-radius: 7px; 
  cursor: pointer;
}
.choice-network-list button.network-choice img{
  width: 25px;
  margin-right: 5px;
  border-radius: 50%;
  background: #fff;
  padding: 5px;
}
.choice-network-list button.network-choice:hover{
  background: #e0e7efbb;
}
.choice-network-list button.network-active{
  background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
  color: #fff;
}
.choice-network-list button.network-active:hover{
  background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
  opacity: 0.7;
}
@media screen and (max-width: 768px) {
  .choice-network-list{
    display: block;
  }
  .choice-network-list button{
    margin-bottom: 15px;
  }
}