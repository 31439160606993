@mixin max($point) {
    @media screen and (max-width: $point) {
        @content;
    }
}
@mixin min() {
    @media screen and (min-width: 1720px) {
        @content;
    }
}

@mixin tablet() {
    @media screen and (max-width: 1024px) {
        @content;
    }
}
@mixin sp() {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Cuprum:wght@500;600&family=Work+Sans:wght@400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url("https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css");
@import 'layout';
@import 'lightmore';
@import 'marketplace';
@import 'hela-node';
@import 'header';
@import 'footer';
@import 'sidebar';
@import 'home';
@import 'menu';
@import 'component';
@import 'airdrop';
@import 'privates-sale';
@import 'create-token';
@import 'nft-market';
@import 'ino';
@import 'stake-token';
@import 'helascriptions';
@import 'profile';
@import 'popup';
@import 'page';
@import 'aos/dist/aos.css';