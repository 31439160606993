.card-title {
    margin-bottom: 15px;
}
.card-title .ant-card-head-title {
    font-weight: 600;
}
.grp-btn-anti {
    width: 100%;
    text-align: center;
}
.grp-btn-anti button:nth-child(1) {
    margin-right: 10px;
}
.anti-warning-fee {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0px;
    color: #3298dc !important;
}
.btn-content-config {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.card-btn button:nth-child(1) {
    margin-right: 10px;
}
.site-form-item-icon svg{
    color: #a3a3a3;
}
