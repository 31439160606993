.p-home {
    width: 100%;
    transition: 0.3s ease-in-out;
    
    &__banner {
        background: linear-gradient(180deg, rgba(244, 249, 253, 0.1) 0%, rgba(245, 245, 247, 0.1) 100%);
        backdrop-filter: blur(77.8321px);
        border-radius: 90px;
        border: 5px solid rgba(255, 255, 255, 0.3);
        padding: 40px 35px;
        @include tablet() {
            padding: 40px 30px;
        }
        @include sp {
            padding: 15px 25px;
            border-radius: 20px;
        }
        ul {
            list-style-type: none;
            display: flex;
            margin: 20px -19px 0;
            @include tablet() {
                margin: 20px -12px 0;
                justify-content: center;
                flex-wrap: wrap;
            }
            @include sp {
                margin: 25px 0 0;
                display: block;
            }
            li {
                width: calc(100%/3);
                margin: 0 19px;
                background: linear-gradient(165.75deg, #F4F2F2 7.77%, #EAEAEA 94.11%);
                box-shadow: -7.78321px -7.78321px 31.1329px rgba(255, 255, 255, 0.35), 7.78321px 7.78321px 31.1329px -3.11329px rgba(91, 115, 134, 0.78);
                border-radius: 40px;
                text-align: center;
                padding: 12px;
                @include tablet() {
                    margin: 0 12px;
                    min-width: 280px;
                }
                @include sp {
                    width: 100%;
                    min-width: auto;
                    max-width: 280px;
                    margin: 10px auto 0;
                }
                &:nth-child(2n) {
                    background: linear-gradient(108.81deg, #FA9F61 12.71%, #F47A60 87.29%);
                    box-shadow: -7.78321px -7.78321px 31.1329px rgba(255, 255, 255, 0.35), 7.78321px 7.78321px 31.1329px -3.11329px rgba(91, 115, 134, 0.78);
                    h3 {
                        color: #fff;
                    }
                }
                &:nth-of-type(3) {
                    @include tablet() {
                        margin-top: 24px;
                    }
                    @include sp {
                        margin-top: 10px;
                    }
                }
                h3 {
                    font-family: 'Cuprum', sans-serif;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 40px;
                    color: #16181A;
                    padding: 0;
                    margin: 0 0 8px;
                    @include tablet() {
                        font-size: 32px;
                        line-height: 36px;
                    }
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23px;
                    text-align: center;
                    color: #16181A;
                    margin: 8px 0 0;
                    @include tablet() {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                img {
                    width: 75px;
                }
            }
        }
    }
    &__head {        
        min-height: 371px;
        padding-right: 45%;
        padding-top: 70px;
        padding-bottom: 20px;
        position: relative;
        background: url(../images/icon-rocket.png) no-repeat top right;
        background-size: 237px 371px;
        @include tablet() {
            min-height: auto;
            padding: 290px 0 0;
            background: url(../images/icon-rocket.png) no-repeat top center;
            background-size: 164px 257px;
        }
        @include sp {
            padding: 200px 0 0;
            background: url(../images/icon-rocket.png) no-repeat top center;
            background-size: 112px 175px;
        }
        h2 {
            font-family: 'Cuprum', sans-serif;
            font-weight: 700;
            font-size: 48px;
            line-height: 55px;
            color: #FFFFFF;
            margin: 0 0 28px;
            @include tablet() {
                font-size: 36px;
                line-height: 42px;
                margin: 0 0 20px;
                text-align: center;
            }
            @include sp {
                font-size: 32px;
                line-height: 37px;
                margin: 0 0 10px;
            }
        }
        p {
            font-weight: 400;
            font-size: 32px;
            line-height: 48px;
            color: #FFFFFF;
            margin: 0;
            @include tablet() {
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                padding: 0 20%;
            }
            @include sp {
                font-size: 16px;
                line-height: 18px;
                padding: 0;
            }
        }
    }
    &__project {
        background: linear-gradient(180deg, rgba(244, 249, 253, 0.1) 0%, rgba(245, 245, 247, 0.1) 100%);
        backdrop-filter: blur(77.8321px);
        border-radius: 90px;
        border: 5px solid rgba(255, 255, 255, 0.3);
        padding: 52px 35px;
        margin-top: 60px;
        @include tablet() {
            margin-top: 40px;
            padding: 40px 30px;
        }
        @include sp {
            padding: 25px;
            border-radius: 20px;
        }
        h3 {
            font-family: 'Cuprum', sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 46px;
            text-align: center;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 35px;
            @include tablet() {
                font-size: 30px;
                line-height: 35px;
            }
            @include sp {
                flex-direction: column;
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 24px;
            }
            p {
                display: block;
                background: linear-gradient(161.32deg, #F4F2F2 7.28%, #EAEAEA 88.2%);
                box-shadow: -2px -2px 10px rgba(255, 255, 255, 0.85), 2px 2px 10px rgba(91, 115, 134, 0.78);
                border-radius: 10px;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                text-align: center;
                color: #F67519;
                font-family: 'Work Sans', sans-serif;
                padding: 12px 28px;
                transform: skewX(-35deg);
                margin-left: 28px;
                margin-bottom: 0;
                @include tablet() {
                    font-size: 16px;
                    line-height: 19px;
                    padding: 8px 22px;
                }
                @include sp {
                    margin: 10px 0 0;
                }
                span {
                    display: block;
                    transform: skewX(35deg);
                }
            }
        }
    }
    &__link {
        width: 100%;
        text-align: right;        
        margin-top: 26px;
        &.is-left {
            text-align: left;
        }
        a {
            font-size: 20px;
            line-height: 23px;
            color: #3785FA;
            position: relative;
            transition: .3s ease-in-out;
            @include tablet() {
                font-size: 16px;
                line-height: 19px;
            }
            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background: #FFD60A;
                position: absolute;
                left: 0;
                bottom: -2px;
                opacity: 0;
                transition: .3s ease-in-out;
            }
            &:hover {
                color: #FFD60A;
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    &__img {
        margin-top: 60px;
        @include tablet() {
            margin: 40px -50px 0;
            width: calc(100% + 100px);
        }
        @include sp {
            margin: 40px -25px 0;
            width: calc(100% + 50px);
        }
        img {
            width: 100%;
        }
    }
    &__qa {
        margin-top: 60px;
        margin-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
        @include tablet() {
            display: block;
            margin: 40px 0;
        }
        .c-qa {
            width: calc((100%/3)*2);
            @include tablet() {
                width: 100%;
                margin-top: 35px;
            }
            &__item {
                + .c-qa__item {
                    margin-top: 8px;
                }
            }
            &__title {
                padding: 20px 36px 20px 0;
                border-bottom: 1px solid #71717A;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                color: #FFFFFF;
                position: relative;
                cursor: pointer;
                @include tablet() {
                    font-size: 16px;
                    line-height: 19px;
                    padding: 15px 36px 15px 0;
                }
            }
            &__arrow {
                display: block;
                width: 18px;
                height: 9px;
                position: absolute;
                top: calc(50% - 5px);
                right: 0;
                img {
                    display: block;
                    width: 100%;
                    transition: .3s ease-in-out;
                }
                &.is-open {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
            &__body {
                overflow: hidden;
                max-height: 0;
                transition: .3s ease-in-out;
                &.is-open {
                    max-height: 240px;
                }
            }
            &__content {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #C4C4C4;
                padding: 0 0 14px;
                @include tablet() {
                    font-size: 14px;
                    line-height: 20px;
                }
                span {
                    color: #3785FA;
                }
                a {
                    color: #3785FA;
                    position: relative;
                    transition: .3s ease-in-out;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: #3785FA;
                        position: absolute;
                        left: 2px;
                        bottom: -1px;
                        transition: .3s ease-in-out;
                    }
                    &:hover {
                        color: #FFD60A;
                        &:after {
                            background: #FFD60A;
                        }
                    }
                }
            }
        }
    }
    &__ttl {
        width: calc(100%/3);
        margin: 0;
        font-family: 'Cuprum', sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 46px;
        color: #FFFFFF;
        padding-right: 12px;
        @include tablet() {
            width: 100%;
            font-size: 30px;
            line-height: 35px;
            padding: 0;
        }
        @include sp {
            font-size: 24px;
            line-height: 28px;
        }
    }
}
.p-launchpad{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    @include sp {
        padding-top: 100px;
    }
    .launchpad-banner{
        padding: 50px 0;
        padding-bottom: 0px;

        @include sp {
            padding-top: 0px;
        }
        .ido-title{
            font-family: AeonikBold;
            font-size: 62px;
            background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: 40px;
            @include sp {
                font-size: 30px;
                margin-top: 0px;
            }
        }
        .ido-description{
            color: #0C434B;
            font-family: Aeonik;
            font-size: 18px;
            margin-top: 0px;
            @include sp {
                margin-bottom: 15px;
            }
        }
        .ido-banner-img{
            width: auto;
            @include sp {
                display: none;
            }
            img{
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .launchpad-title{
        color: #0A2C31;
        font-family: AeonikMedium;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.64px;
        margin-top: -20px;
        margin-bottom: 20px;

        @include sp {
            display: none;
        }
    }
    .community-end {
        margin-top: 50px;
        border-radius: 20px;
        background: url(../images/bg-com-end.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 50px;
        padding: 50px;

        .end-info {
            .end-title {
                color: #FFF;
                font-family: AeonikBold;
                font-size: 26px;
                line-height: normal;
                letter-spacing: 0.72px;
            }

            .end-des {
                color: #FFF;
                font-family: Aeonik;
                font-size: 16px;
            }
        }

        .end-btn {
            display: inline-flex;
            padding: 14px 30px;
            justify-content: center;
            margin-top: 15px;
            align-items: center;
            text-align: center;
            border-radius: 30px;
            background: #FFF;

            a{
                color: #3BD5C1;
                text-align: center;
                font-family: AeonikRegular;
                font-size: 16px;
            }
            
        }
                .end-btn:hover {
                    color: #fff;
                    background: #3BD5C1;
                    border: 1px solid #fff;
        
                    a {
                        color: #fff;
                        font-family: AeonikRegular;
                        font-size: 16px;
                    }
                }
    }
}
.p-launchpadDetail {
    // background: url(../images/bg-ido.png);
    // background-position: center;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    padding-top: 30px;
    padding-bottom: 50px;

    @include sp{
        padding-top: 165px;
    }

    .banner-detail{
        background: url(../images/ido-detail-bg.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        min-height: 300px;
        // position: absolute;
        width: 100%;

        img{
            width: auto;
            opacity: 0.7;

            &:nth-child(1){
                position: absolute;
                left: 5%;
                top: 20%;

            }
            &:nth-child(2){
                position: absolute;
                right: 5%;
                top: 10%;
            }
        }

        @include sp{
            display: none;
        }
    }

   
    .launchpadDetail-info{
        margin-top: -20%;
        position: relative;
        // border-radius: 20px;
        // border: 0.7px solid #DEEBED;
        // background: #FFF;
        // padding: 15px;
        // box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    }
    .launchpadDetail-round {
        margin-top: 20px;
        width: 100%;
    }
}

.p-airdrop {
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        width: calc(100% - 370px);
        @include sp {
            //display: block;
            margin-bottom: 25px;
            width: 100%;
            padding-bottom: 86px;
        }
        h3 {
            width: calc(100% - 100px);
            font-size: 40px;
            line-height: 1;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            font-weight: bold;
            color: #fff;
            margin: 0;
            padding: 0;
            @include sp {
                //width: 100%;
                font-size: 22px;
                //margin-bottom: 20px;
            }
        }
        .p-stake__display {
            @include sp {
                margin-top: 0;
            }
        }
    }
    &__search {        
        width: 350px;
        position: absolute;
        top: 0;
        right: 0;
        @include max(1180px) {
            top: 15px;
        }
        @include tablet {
            top: 0;
        }
        @include max(899px) {
            top: 15px;
        }
        @include sp {
            width: 100%;
            top: 60px;
        }
        input {
            width: 100%;
            appearance: none;
            font-size: 16px;
            line-height: 1;
            color: #74757C;
            padding: 13px 50px 13px 15px;
            border: 1px solid #71717A;
            border-radius: 5px;
            background-color: transparent;
            transition: 0.3s ease-in-out;
            &:focus-visible {
                outline: none;
                border: 1px solid #FFD60A;
                box-shadow: 4px 4px 4px rgba(255, 214, 10, 0.2);
            }
        }
        button {
            width: 24px;
            height: 24px;
            appearance: none;
            border: 0;
            background-color: transparent;
            padding: 0;
            margin: 0;
            position: absolute;
            top: calc(50% - 12px);
            right: 10px;
            cursor: pointer;
            img {
                width: 24px;
            }
        }
    }

    .c-tabs {
        .ant-tabs-tab {
            padding-top: 0;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            font-size: 22px;
            @include sp {
                font-size: 20px;
            }
            + .ant-tabs-tab {
                margin-left: 55px;
                @include sp {
                    margin-left: 25px;
                }
            }
            &:hover {
                color: #FFD60A;
                &:after {
                    width: 100%;
                }
            }
        }
        &__nav {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            @include sp {
                display: block;
                width: 100%;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 1000;
                background-color: #22252F;
                max-height: 0;
                transition: .3s ease-in-out;
            }
            &.is-open {
                @include sp {
                    max-height: 348px;
                }
            }
            li {
                padding-bottom: 10px;
                position: relative;
                @include sp{
                    padding-bottom: 0;
                }
                + li {
                    margin-left: 55px;
                    @include max(1280px) {
                        margin-left: 38px;
                    }
                    @include tablet {
                        margin-left: 30px;
                    }
                    @include sp {
                        margin-left: 0;
                    }
                }
                &:after {
                    content: '';
                    width: 0;
                    height: 3px;
                    background: #FFD60A;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: all 0.2s ease-in-out;
                    @include sp {
                        display: none;
                    }
                }
                a {
                    font-size: 22px;
                    line-height: 1;
                    color: #C4C4C4;
                    text-transform: uppercase;
                    text-decoration: none;  
                    transition: all 0.2s ease-in-out;
                    @include min {
                        font-size: 24px;
                    }
                    @include sp {
                        font-size: 18px;
                        display: block;
                        padding: 20px 25px;
                    }
                }
                &:hover,
                &.is-active {
                    &:after {
                        width: 100%;
                    }
                    a {
                        color: #FFD60A;
                    }
                }
                &.is-close {
                    background: #32353E;
                    padding: 20px 60px 20px 25px;
                    font-size: 18px;
                    line-height: 1;
                    color: #FDFCFF;
                    span {
                        display: block;
                        width: 28px;
                        height: 28px;
                        padding: 2px;
                        position: absolute;
                        right: 20px;
                        top: calc(50% - 14px);
                        img {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .c-list {
        &__head {
            text-transform: uppercase;
        }
        // &__item {
        //     padding-bottom: 61px;
        // }
        &__img {
            overflow: hidden;
        }
        &__status {
            width: 160px;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            padding: 13px 0;
            left: auto;
            right: -50px;
            top: 10px;
            transform: rotate(45deg);
            &:before {
                display: none;
            }
            &.Upcoming {
                background-color: #FFD60A;
                color: #000;
            }
            &.Ongoing {
                background-color: #F67519;
                color: #fff;
            }
            &.Ended {
                background-color: #F73D1E;
                color: #fff;
            }
        }
        &__body {
            padding: 12px 15px 20px;
        }
        &__row {
            span {
                &:last-child {
                    text-transform: none;
                    line-height: 18px;
                    @include sp {
                        line-height: 16px;
                    }
                }
            }
        }
        &__detail {
            text-align: right;
            padding: 0;
            margin: 0;
            @include sp {
                margin: 15px 0 0;
            }
            a {
                color: #FFD60A;
                font-size: 18px;
                line-height: 1;
            }
        }
        &__footer {
            p {
                display: flex;
                // flex-wrap: wrap;
                align-items: center;
                background-color: #32353E;
                width: 100%;
                padding: 13px 15px;
                border-radius: 5px;
                span {
                    display: block;
                    margin: 0;
                    &:first-child {
                        font-size: 16px;
                        line-height: 18px;
                        color: #C4C4C4;
                        width: 85px;
                        img {
                            width: 18px;
                            margin-left: 15px;
                        }
                    }
                    &:last-child {
                        width: calc(100% - 100px);
                        text-align: right;
                    }
                }
            }
            .c-btn {
                width: 100%;
            }
        }
    }
    .c-btnWallet {
        width: 100%;
        height: auto;
        margin: 0;
    }
}

.p-airdropDetail {
    padding: 35px 0 70px;
    @include sp {
        padding: 0 0 70px;
    }
    h2 {
        font-size: 26px;
        font-weight: bold;
        color: #fff;
        line-height: 32px;
        text-align: center;
        margin-bottom: 15px;
        @include sp {
            font-size: 24px;
            line-height: 28px;
        }
    }
    ul {
        padding: 15px 30px;
        margin: 0 auto;
        max-width: 685px;
        width: 100%;
        border: 1px solid #22252F;
        background: #32353E;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp {
            flex-wrap: wrap;
            padding: 10px;
        }
        li {
            font-size: 16px;
            color: #C4C4C4;
            line-height: 20px;
            display: flex;
            align-items: center;
            padding: 0 25px;
            letter-spacing: 0.025em;
            @include sp {
                padding: 0 20px;
            }
            + li {
                border-left: 1px solid #22252F;
            }
            img {
                width: 20px;
                margin-right: 10px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:nth-child(2) {
                @include sp {
                    padding-right: 0;
                }
            }
            &:last-child {
                padding-right: 0;
                @include sp {
                    width: 100%;
                    justify-content: center;
                    margin-top: 15px;
                    padding: 0;
                    border-left: 0;
                }
            }
        }
    }
    p {
        font-size: 14px;
        line-height: 1;
        color: #fff;
        margin: 35px 0 0;
        padding: 0;
        text-align: center;
        span {
            font-size: 16px;
            color: #FFD60A;
            font-weight: bold;
        }
    }
    .c-btn {
        width: 220px;
        margin: 15px auto 0;
        border-radius: 5px;
        display: block;
    }
}

.p-stake {
    .c-tabs {
        .ant-tabs-nav {
            margin: 0;
        }
        .ant-tabs-nav-wrap {
            border-bottom: 1px solid #32353E;
        }
        .ant-tabs-tab {
            font-size: 22px;
            padding-top: 0;
            @include sp {
                font-size: 20px;
            }
            + .ant-tabs-tab {
                margin-left: 35px;
            }
            &:hover {
                color: #FFD60A;
                &:after {
                    width: 100%;
                }
            }
        }
        .ant-tabs-content {
            padding-top: 45px;
            @include sp {
                padding-top: 15px;
            }
        }
    }
    &__head {
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @include sp {
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 20px;
        }
    }
    &__display {
        @include sp {
            order: 2;
            margin-top: 15px;
        }
        button {
            appearance: none;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            padding: 0;
            width: 25px;
            + button {
                margin-left: 25px;
            }
            img {
                width: 25px;
                transition: .3s ease-in-out;
            }
        }
    }
    &__search {
        width: 260px;
        position: relative;
        @include sp {
            width: 100%;
            order: 1;
        }
        input {
            width: 100%;
            appearance: none;
            font-size: 16px;
            line-height: 1;
            color: #74757C;
            padding: 13px 50px 13px 15px;
            border: 1px solid #71717A;
            border-radius: 5px;
            background-color: transparent;
            transition: 0.3s ease-in-out;
            &:focus-visible {
                outline: none;
                border: 1px solid #FFD60A;
                box-shadow: 4px 4px 4px rgba(255, 214, 10, 0.2);
            }
        }
        button {
            width: 24px;
            height: 24px;
            appearance: none;
            border: 0;
            background-color: transparent;
            padding: 0;
            margin: 0;
            position: absolute;
            top: calc(50% - 12px);
            right: 10px;
            cursor: pointer;
            img {
                width: 24px;
            }
        }
    }
}

.p-ino {
    .c-popup {
        &__box {
            padding: 45px 45px 30px;
            position: relative;
            @include sp {
                width: calc(100% - 50px);
                padding: 30px 15px 20px;
            }
        }
        &__close {
            top: 20px;
            right: 36px;
        }
        &__name {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 23px;
            line-height: 27px;
            letter-spacing: 0.025em;
            color: #FDFCFF;
            margin-bottom: 34px;
            @include sp {
                font-size: 20px;
                line-height: 23px;
                margin-bottom: 25px;
            }
            img {
                display: block;
                width: 180px;
                margin-right: 25px;
                @include sp {
                    width: 100px;
                }
            }
        }
        &__des {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #FDFCFF;
            margin: 0 0 56px;
            @include sp {
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 25px;
            }
        }
        .c-btn {
            width: 100%;
        }
    }
}

.p-gameHub {
    margin: -35px -25px 25px -40px;
    transition: 0.3s ease-in-out;
    @include tablet() {
        width: calc(100% + 50px);
        margin: -22px -25px 30px -25px;
    }
    @include sp {
        //padding: 0 25px 0 40px;
    }
    &__banner {
        position: relative;
    }
    &__cate {
        padding: 45px 25px 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include max(1280px) {
            padding: 45px 25px 0;
        }
        @include sp {
            padding: 20px 25px 0;
        }
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            li {
                display: block;
                margin: 0 10px;
                @include max(1280px) {
                    margin: 0 6px;
                }
                @include sp {
                    margin: 10px 5px 0;
                }
                a {
                    display: block;
                    font-size: 18px;
                    line-height: 21px;
                    letter-spacing: 0.025em;
                    color: #74757C;
                    padding: 12px 20px;
                    border-radius: 10px;
                    transition: .3s ease-in-out;
                    white-space: nowrap;
                    &.is-active {
                        background: #32353E;
                        color: #FDFCFF;
                    }
                    &:hover {
                        background: #22252F;
                    }
                }
            }
        }
        label {
            display: block;
            width: 350px;
            margin-top: 25px;
            position: relative;
            input {
                width: 100%;
                background: transparent;
                border: 1px solid #71717A;
                border-radius: 5px;
                font-size: 16px;
                line-height: 1;
                padding: 13px 35px 13px 15px;
                color: #74757C;
                &:focus-visible {
                    outline: none;
                    border: 1px solid #FFD60A;
                }
            }
            button {
                position: absolute;
                top: calc(50% - 12px);
                right: 11px;
                display: block;
                width: 24px;
                height: 24px;
                padding: 0;
                margin: 0;
                border: 0;
                background: transparent;
                cursor: pointer;
                img {
                    width: 100%;
                }
            }
        }
    }
    &__box {
        margin-top: 55px;
        padding: 0 25px 0 40px;
        @include max(1280px) {
            margin-top: 40px;
        }
        @include sp {
            padding: 0 25px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: 0 0 35px;
        @include max(1280px) {
            font-size: 22px;
            line-height: 26px;
            margin: 0 0 30px;
        }
        @include sp {
            font-size: 20px;
            line-height: 23px;
        }
    }
    &__btn {
        text-align: center;
        button {
            appearance: none;
            border: 0;
            padding: 10px 60px;
            background: #32353E;
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #C4C4C4;
            cursor: pointer;
        }
        .ant-spin {
            margin-right: 10px;
            line-height: 1;
        }
    }
    &__pagination {
        display: flex;
        justify-content: flex-end;
        .ant-pagination {
            .ant-pagination-prev,
            .ant-pagination-next {                
                .ant-pagination-item-link {
                    color: #fff;
                    background: #32353E;
                    border-radius: 2px;
                    border: 1px solid #32353E;
                }
                &.ant-pagination-disabled {
                    .ant-pagination-item-link {
                        opacity: .3;
                    }
                }
                &:hover {
                    .ant-pagination-item-link {
                        color: #1A1C1E;
                        border: 1px solid #FFD60A;
                        background: #FFD60A;
                    }
                }
            }
            .ant-pagination-item  {
                background: #32353E;
                opacity: 0.3;
                border-radius: 2px;
                border: 1px solid #32353E;
                a {
                    color: #FDFCFF;
                }
                &:hover,
                &.ant-pagination-item-active {
                    opacity: 1;
                    background: #FFD60A;
                    border: 1px solid #FFD60A;
                    a {
                        color: #1A1C1E;
                    }
                }
            }
        }
    }
}

.p-gameHubDetail {
    display: flex;
    padding-bottom: 75px;
    @include tablet {
        display: block;
    }
    &__left{
        width: 65%;
        @include tablet {
            width: 100%;
        } 
        .btn-back{
            display: flex;
            align-items: center;
            margin-bottom: 42px;
            img {
                display: block;
                width: 32px;
                height: 32px;
            }
        }
        .text-back{
            color:#fff;
            margin-left: 16px;
            font-size: 16px;
        }      
    }
    &__banner {
        margin-top: 25px;
        .c-sliderNav {
            width: calc(100% - 84px);
            position: unset;
            margin: 20px auto 0;
        }
    }
    &__video {
        margin-top: 25px;
        height: 500px;
        position: relative;
        @include sp {
            height: 280px;
        }
    } 
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include tablet{
            display: block;
        }
        .title{
            display: flex;
            align-items: center;
            width: 65%;
            @include tablet{
                width: 100%;
            }
        }
        .text-banner{
            color:#FFFFFF;
            font-size: 30px;
            margin-right: 20px;
            font-weight: 600;
            @include sp{
                font-size: 26px;
            }
            .check{
                width: 22px;
                height: 22px;
                margin-left:10px ;
            }
        } 
        .btn-play{
            width: 30%;
            background: transparent;
            border: 2px solid #FFD60A;
            border-radius: 10px;
            padding:10px;
            text-align: center;
            @include tablet{
                width: 100%;
                margin-top: 15px;
            }
            .text-play{ 
                color: #FFD60A;  
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.01em;
            }
        }
    }
    &__right{
        width: 35%;
        margin-left: 18px;
        padding: 16px;
        background: #22252F;
        border-radius: 3px;
        @include tablet {
            width: 100%;
            margin-left: 0px;
            margin-top: 20px;
        }
        .main-text{
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #9F9F9F;
            margin-bottom: 14px;
        }
        .chart{
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
        .price-img{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .logo{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .price {
                font-weight: 600;
                font-size: 20px;
                line-height: 22px;
                letter-spacing: 0.3px;
                color: #FFFFFF;
                margin: 0 10px;
            }
            .percent {
                font-weight: 600;
                font-size: 12px;
                line-height: 22px;
                letter-spacing: 0.3px;
                &.down {
                    color: #FA4464;
                }
                &.up {
                    color: #40BA7F;
                }
            }
        }
        .tab-info-item{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            .left-text{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #9F9F9F;
                @include tablet{
                    font-size: 12px;
                }
            }
            .right-text{ 
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
                text-align: right;
                @include tablet{
                    font-size: 12px;
                }
            }
            .percent-text{
                font-weight: 600;
                font-size: 10px;
                line-height: 22px;
                letter-spacing: 0.3px;
                margin-left: 6px;
                &.down {
                    color: #FA4464;
                }
                &.up {
                    color: #40BA7F;
                }
            }
            .right-volume{
                display: flex;
                align-items: center;
            }
            .media {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                max-width: 250px;
                li {
                    display: block;
                    width: 24px;
                    margin: 0 15px 16px;
                    img {
                        width: 24px;
                        display: block;
                    }
                }
            }
        }
        .aboutGame{
            display: block;
            background: #101011;
            padding:15px;
            border: 0.75px solid #595959;
            border-radius: 2px;
            .header{
                display: block;
                color:#F9C801;
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.025em;
                text-transform: uppercase;
            }
            .description{ 
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.025em;
                color: #C4C4C4;
                span {
                    color: #C4C4C4 !important;
                }
            }
        }
    }
}

.p-security {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    min-height: 800px;
    @include sp{
        padding-top: 60px;
    }

    .is-mt24 {
        margin-top: 24px;
    }
    .is-mb8 {
        margin-bottom: 8px;
    }
    .is-mt8 {
        margin-top: 8px;
    }
    &__box {
        padding: 32px 72px;
        h2 {
            font-weight: 700;
            font-size: 32px;
            line-height: 45px;
            text-align: center;
            color: #313132;
            margin-bottom: 56px;
            padding: 0;
        }
        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #313132;
            padding: 0;
            margin-bottom: 24px;
        }
        h4 {
            font-weight: 700;
            font-size: 20px;
            line-height: 29px;
            text-align: left;
            color: #313132;
            padding: 0;
            margin-bottom: 8px;
        }
        h5 {
            font-style: italic;
            font-weight: 500;
            font-size: 18px;
            line-height: 29px;
            text-decoration: underline;
            text-underline-offset: 3px;
            color: #313132;
            margin-bottom: 8px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #313132;
            margin: 0;
            + p {
                margin-top: 21px;
            }
            a {
                color: #313132;
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
    &__content {
        + .p-security__content {
            margin-top: 40px;
        }
        ul {
            margin: 8px 0 0;
            list-style: none;
            li {
                font-weight: normal;
                font-size: 14px;
                line-height: 32px;
                color: #313132;
                padding-left: 40px;
                position: relative;
                &::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    background: #313132;
                    border-radius: 50%;
                    position: absolute;
                    top: 15px;
                    left: 24px;
                }
                span {
                    font-weight: 600;
                }
                a {
                    color: #313132;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }
            }
        }
    }
}