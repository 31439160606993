.market-page{
    background: url(../images/bg-marketplace.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 50px;

    @include sp {
        background-size: cover;
    }

    .mint-nft{
        background: #FFFFFF;
        box-shadow: 2px 12px 36px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        padding: 15px;
        margin-top: 30px;

        .mint-title{
            font-family: AeonikMedium;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            color: #1E2329;
            margin-bottom: 5px;
            line-height: normal;

            @include sp{
                margin-top: 15px;
            }
        }
        .mint-info{
            background: #FAFAFA;
            border-radius: 16px;
            padding: 10px;
        }

        .nft-img{
            
            margin: auto;
            text-align: center;
            img{
                width: auto;
                max-width: 100%;
                margin: auto;
                max-height: 307px;
            }
        }
        
    }

    .mint-list{
        margin-top: 25px;

        .mint-list-title{
            text-align: center;
            font-family: AeonikMedium;
            font-size: 40px;
            color: #000;

            @include sp {
                font-size: 35px;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            span{
                background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #25c3db 93.62%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                letter-spacing: 0.5px;
            }
        }

        .mint-nft-item{
            padding: 10px;
            background: #FAFAFA;
            border-radius: 24px;
            margin-top: 15px;
            margin-bottom: 15px;

            .nft-img{
                margin-bottom: 15px;
                img{
                    width: 100%;
                    max-height: 372px;
                }
                .nft-id{
                    background: #FAFAFA;
                    color: #000;
                    position: absolute;
                    padding: 5px;
                    color: #0A2C31 !important;
                    font-family: AeonikRegular !important;
                    border-bottom-right-radius: 10px;
                }
            }
            .logo-mint img{
                border: 1px solid #25c3db;
                border-radius: 100%;
            }
        }
    }
}
.d-inline-block{
    display: inline-block;
}
.bg-black{
    background: #000;
}