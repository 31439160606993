.border-radius-10{
    border-radius: 10px !important;

    img{
        border-radius: 10px !important;
    }
}
.float-right{
    float: right;
}
.helanode-page{
    background: url(../images/bg-marketplace.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding-top: 150px;
    padding-bottom: 50px;

    @include sp {
        background-size: cover;
        padding-top: 100px;
    }

    .helanode-info{
        padding: 15px;
        background: #fff;
        box-shadow: 2px 12px 36px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        margin-bottom: 15px;

        .node-banner{
            margin-bottom: 15px;
            background: url(../images/bg-helanode.png);
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: 300px;
            display: flex;
            align-content: center;
            justify-content: center;

            @include sp{
                height: auto;
            }

            img{
                max-width: 300px;
                width: auto;
                margin: auto;
            }
        }
        .node-title{
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            line-height: 25px;
            margin-bottom: 10px;
        }
        .node-description{
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            color: #707A8A;
            margin-bottom: 15px;
        }
    }
    .helanode-info-detail{
        padding: 15px;
        background: #fff;
        box-shadow: 2px 12px 36px rgba(0, 0, 0, 0.08);
        border-radius: 24px;

        .node-info-title{
            font-family: AeonikRegular !important;
            font-size: 28px;
            line-height: 38px;
            color: #1E2329;
            margin-bottom: 0px;
        }

        .custom-slt .ant-select-selector .ant-select-selection-item{
            text-align: left !important;
        }
        .form-border{
            border: 1px solid #E9EFF5;
            border-radius: 20px;
            padding: 15px;
        }
        .grp-input{
            
            .ant-input-wrapper{
                background: #EDF3F4;
                box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
                border-radius: 100px;
                padding: 2px 5px;

                .ant-input-group-addon{
                    background: transparent;
                    border: none !important;
                    width: 30px;
                    padding: 2px;
                    
                }
                .ant-input{
                    height: 32px;
                    background: transparent !important;
                    border: none !important;
                    box-shadow: none !important;
                    font-size: 16px !important;
                    padding-left: 0px;
                    padding-right: 0px;
                    text-align: center !important;
                }
            }
        }
    }

    .btn-grid{
        display: inline-flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px;
        width: 23px;
        height: 23px;
        border-radius: 8px;
        background-color: transparent;
        border: 0px;
        color: #0A2C31;
        cursor: default;
        transition: background-color 0.3s ease-in-out 0s;

        &:hover{
            cursor: pointer;
            background-color: rgba(170, 170, 170, 0.372);
        }
    }
    .border-line{
        border: 1px solid #0A2C31;
        border-radius: 5px;
        padding: 2px;
        display: flex;
    }
    .btn-grid-active{
        background-color: #0A2C31;
        color: #fff;
    }
    .grid-item{
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        padding: 12px;
        margin-bottom: 15px;
        cursor: pointer;
    }


    .nodes{
        margin-top: 30px;
        padding: 0px 0px;

        @include sp{
            margin-top: 30px;
        }
        
        .nodes-list{
            // text-align: center;
            margin-top: 20px;
            .border-active{
            // border: 2px solid rgba(20, 224, 136, 0.771) !important;
                background: rgb(50 154 172 / 21%) !important;   
            }

            .border-soldout{
            background: rgb(90 192 202 / 46%) !important;
            }

            .nodes-item{
                border: 1px solid #E9EFF5;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 15px;
                margin-bottom: 25px;
                cursor: pointer;
                box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

                &:hover{
                    background: rgb(50 154 172 / 21%) !important;
                }
            
                .node-top{
                    padding: 15px;
                    @include sp{
                        padding: 15px;
                    }
                    .node-title{
                        font-weight: 600;
                        line-height: normal;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin-bottom: 20px;
                    }
                    .nodes-info{
                        font-size: 16px !important;
                        margin-bottom: 15px;
                    }
                }
                .node-bottom{
                    padding: 25px;
                    border-top: 1px solid #E9EFF5;
                    @include sp{
                        padding: 15px;
                    }
                }

                .no-available{
                    border: 2px solid #000;
                    padding: 10px 25px;
                    color: rgb(217, 20, 20);
                    font-size: 20px;
                    font-weight: bold;
                    width: fit-content;
                    position: absolute;
                    margin-top: 130px;
                    background: rgba(255, 255, 255, 0.623);
                    z-index: 999;
                    margin-left: 50px;
                    transform: rotate(-20deg);
                }
            }

            img{
                max-width: 100%;
                width: auto;
            }
        }
        }

        .node-progress{
        .ant-progress{
            margin-bottom: 5px;

            .ant-progress-inner{
            height: 10px !important;
            .ant-progress-bg{
                height: 10px !important;
            }
            }
        }
        
        }
    
    .social{
        margin-top: 15px;
        padding-top: 10px;
        padding-bottom: 0;
        border-top: 1px solid #dadada;
        @include sp{
            padding-top: 15px;
            padding-bottom: 15px;
        }

        a{
            color: rgba(68, 68, 68, 1) !important;

            img{
                width: 22px;
                height: 22px;
            }
        }

    }
    
}
.time-count{
        width: fit-content;
        // margin: auto;
        color: rgba(229, 231, 235, 0.6);
    }
    .time-count{
        .time-item{
            letter-spacing: 0.5px;
            background: #EDF3F4;
            color: #3F666B !important;
            padding: 4px;
            border-radius: 3px;
            font-size: 16px;
            margin-left: 2px;
            margin-right: 2px;
        }
        
    }
.btn-increase{
    font-family: AeonikMedium !important;
    border: none !important;
    font-size: 20px;
    height: 30px;
    width: 30px;
    line-height: normal;
    border-radius: 100%;
    background: #fff;
    border: none !important;
    cursor: pointer;

    &:disabled{
        opacity: 0.5;
        cursor:no-drop;
    }
}
.table-node{
    .ant-table-thead > tr > th{
        background: transparent !important;
    }
    .ant-table-row{
        background: #FAFAFA !important;
        // background: transparent;
        border-radius: 10px;
    }
    .ant-table-tbody > tr{
        // border: none !important;

        &:hover{
            cursor: pointer;
            background: rgb(235, 235, 235) !important;
        }
    }
    .ant-table-tbody > tr > td{
        // border: none !important;
    }
    .ant-table-thead > tr > th{
        border: none !important;

        
    }
}
.node-progress .ant-progress-text {
    position: absolute;
    top: -15px;
}

.node-progress .ant-progress-outer {
    padding-right: 0px !important;
}

.node-progress .ant-progress-bg {
    height: 12px !important;
    // background: linear-gradient(270deg, #ee7620 0%, #ffd540 101.35%) !important;
    // background-color: transparent !important;
}

.node-progress .ant-progress-inner {
    background-color: #e6eef7 !important;
}
.collapse-cus{
    background: #fff !important;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    border-radius: 15px !important;
}