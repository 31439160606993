.upload-block {
    padding: 15px;
    max-width: 1200px;
    min-height: 260px;
    background: #ffffff;
    box-shadow: 0px 0px 4px #89d4c7, inset 0px 4px 24px #e0efff;
    border-radius: 12px;
    margin: auto;
    margin-top: 30px;
}

.upload-title {
    width: 100%;
    text-align: center !important;
    margin-bottom: 50px;
}

.image-upload-review {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.image-upload-review img {
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 0px 4px #89d4c7, inset 0px 4px 24px #e0efff;
    border-radius: 12px;
}

.text-upload-image {
    margin-right: 5px;
    margin-left: 2px;
    color: #7b7979;
    font-size: 12px;
}

.upload-icon-copy {
    margin-left: 5px;
    font-size: 20px;
    color: red;
}

@media screen and (max-width: 768px) {}