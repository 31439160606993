
.create-token-page {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .create-token-title {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 35px;
        letter-spacing: 0.5px;
        background: var(--d, linear-gradient(92deg, #3dbcab 9.22%, #0C434B 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .create-step {
        padding-bottom: 50px;

        .sub-title-steps{
            color: #0C434B !important;
            text-align: left;
            font-size: 18px;
            font-family: AeonikMedium;
            margin-bottom: 15px;
        }
    }
}
