.p-home{
    margin-top: 100px;
}
.text-blue-white{
    color: #ACCCD0 !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.intro-banner{
    @include sp {
        display: none;
    }

}
.home-banner{
    background-image: url('../images/banner-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 500px;
    padding-top: 100px;
    @include sp {
        margin-top: 70px;
    }

    .main-upswap{
        border: 3px solid #CADADD;
        border-radius: 20px;
        padding: 5px 20px;
        width: fit-content;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        color: #5B8F97;

        @include sp {
            position: relative;
            z-index: 2;
        }
    }
    .banner-title{
        // background: linear-gradient(91.68deg, #5EE7D5 9.22%, #0C434B 93.62%);
        // background: var(--d, linear-gradient(92deg, #a9f2e8 9.22%, #0C434B 93.62%));
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: #3BD5C1;
        text-align: center;
        font-family: AeonikBold;
        font-size: 62px;
        min-height: 200px;
        margin: auto;
  
        @include sp {
            font-size: 40px;
            width: 100%;
            position: relative;
            z-index: 2;
        }
    }
    .banner-desciption{
        margin: auto;
        margin-top: 15px;
        color: #0C434B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        width: 70%;

        @include sp {
            width: 100%;
            position: relative;
            z-index: 2;
        }
    }
    .banner-btn-list{
        margin-top: 45px;
        text-align: center;
        margin-bottom: 15px;
        @include sp {
            position: relative;
            z-index: 2;
        }

        a{
            border-radius: 20px;
            border: 1.5px solid #3BD5C1;
            padding: 10px 15px;
            background: #FFF;
            font-family: AeonikRegular;

            &:nth-child(1){
                margin-right: 10px;
                background: #3BD5C1;
                color: #fff;
            }
            &:nth-child(2) {
                color: #3BD5C1;
            }
        }
    }
    .token-list{
        width: 50%;
        margin: auto;
        @include sp {
            width: 100%;
            position: relative;
            z-index: 2;
        }
    }
    .banner-logo {
        text-align: center;
        width: 570px;
        min-height: 250px;
        margin: auto;
        // position: absolute;
        background-image: url('../images/upswap-banner-logo-bg.png');
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
        @include sp {
            width: 100%;
        }

        .banner-logo-img{
            margin-top: 160px;
            position: absolute;
            width: inherit;
            
            @include sp {
                left: 0px;
            }
            img {
                width: auto;

                animation-name: spin;
                animation-duration: 12s, 12s, 12s;
                animation-iteration-count: infinite, infinite, infinite;
                transform: rotateX(6deg) rotateY(6deg) rotateZ(6deg);
            }
        }
    }
    .banner-caculator{
        background: #EEF7F9;
        min-height: 200px;
        padding: 70px 0;

        .caculator-item{
            padding: 20px;
            min-height: 100px;
            border-radius: 15px;
            text-align: center;
            margin-top: 20px;

            .caculator-num{
                color: #0A2C31;
                text-align: center;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.8px;
            }
        }
        .bg-caculator-1{
            background-image: url('../images/bg-caculator-1.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .bg-caculator-2 {
            background-image: url('../images/bg-caculator-2.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .bg-caculator-3 {
            background-image: url('../images/bg-caculator-3.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .bg-caculator-4 {
            background-image: url('../images/bg-caculator-4.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
    .banner-block-left{
        text-align: center;
        margin: auto;

        img{
            width: auto;
            margin: auto;
        };
        @include sp {
            position: absolute;
            left: 8%;
            opacity: 0.2;
            left: 0;
            margin-top: 3%;
        }
    }
    .banner-block-right {
        position: absolute;
        right: 8%;
        margin-top: 15%;
        @include sp {
            display: none;
        }
        img {
            width: auto;
        }

    }
}
.home-project{
    padding: 50px 0;

    .project-banner{
        width: auto;
        
        .banner-item{
            border-radius: 20px;
            border: 0.7px solid #DEEBED;
            background: rgba(32, 34, 33, 0.03);
            
            @include sp {
                margin-top: 15px;
            }
            img{
                width: 100%;
                border-radius: 10px;
            }
            .banner-title{
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 10px;
                text-align: center;
                color: #0EAD98;
                text-align: center;
                font-family: AeonikMedium;
                font-size: 22px;
            }
            .banner-description {
                color: #3F666B;
                font-family: AeonikRegular;
                text-align: center;
                font-size: 16px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 20px;
            }
        }
    }
    .project-upcoming{
        padding-top: 20px;
        padding-bottom: 20px;

        .upcoming-title{
            color: #0A2C31;
            font-family: AeonikMedium;
            font-size: 30px;
            letter-spacing: 0.64px;
            margin-top: 30px;
            margin-bottom: 20px;
            @include sp {
                font-size: 20px;
            }
            a{
                font-size: 16px !important;
                float: right;
                font-weight: 400;
            }
        }
        .upcoming-item{
            border-radius: 20px;
            border: 0.7px solid #DEEBED;
            background: #FFF;
            padding: 15px;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
            margin-top: 15px;

            .info{

                .title-head{
                    display: block;
                    width: 100%;
                    float: left;

                    @include sp {
                        float: left;
                        width: 100%;
                        margin-top: 15px;
                    }   
                    img{
                        float: left;
                        border: 1px solid #ccc;
                        border-radius: 100%;
                    }
                    .title{
                        color: #0A2C31;
                        font-family: AeonikMedium;
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-left: 10px;
                        float: left;
                    }
                    span.symbol{
                        color: #096ECB;
                        font-family: AeonikRegular;
                        font-size: 16px;
                        line-height: normal;
                        margin-left: 10px;
                        margin-top: 5px;
                        float: left;
                    }
                    .status{
                        float: right;
                        border-radius: 20px;
                        background: transparent;
                        color: #096ECB;
                        text-align: center;
                        font-family: AeonikRegular;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 4px 10px;

                        @include sp {
                            display: none;
                        }
                    }
                }
                .description{
                    color: #3F666B;
                    font-family: Aeonik;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    width: 60%;
                    float: left;
                    margin-top: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    @include sp {
                        width: 100%;
                    }
                }
                .info-foot{
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    @include sp {
                        position: relative;
                        display: flex;
                        width: 100%;
                    }

                    .info-foot-left{
                        width: 100%;
                        display: flex;
                        margin-top: 15px;
                        .network-info{
                            padding: 5px;
                            padding-right: 10px;
                            border-right: 1px solid #CADADD;
                            color: #3F666B;
                            font-size: 14px;
                            @include sp {
                                display: grid;
                                width: 100%;
                            }

                            span{
                                color: #0A2C31;
                                font-family: AeonikMedium;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.36px;
                                text-transform: capitalize;
                            }
                        }
                        .raise-info{
                            padding: 5px;
                            padding-left: 10px;
                            color: #3F666B;
                            @include sp {
                                display: grid;
                                width: 100%;
                            }

                            span {
                                color: #0A2C31;
                                font-family: AeonikMedium;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.36px;
                            }
                        }
                    }
                    .info-foot-right {
                        width: 100%;
                        text-align: right;
                        margin-top: 15px;
                        @include sp {
                            margin-top: 25px;
                            margin-bottom: 10px;
                            text-align: center;
                        }
                        
                        a{
                            border-radius: 20px;
                            border: 1.5px solid #3BD5C1;
                            padding: 5px 10px;
                            color: #3BD5C1;
                            font-family: AeonikRegular;
                                font-size: 15px;
                            @include sp {
                                padding: 10px 13px;
                            }
                            
                        }
                    }
                }
            }
        }
        .upcoming-item:hover {
            border: 1px solid #3BD5C1;
            box-shadow: 0 0 2px #5ffce0db, inset 0 4px 24px #e0efff;
            border-radius: 12px;
        }
    }
    .earn-staking {
        margin-top: 70px;
        padding-bottom: 20px;

        .earn-staking-title {
            color: #0A2C31;
            font-family: AeonikMedium;
            font-size: 30px;
            letter-spacing: 0.64px;
            margin-top: 20px;
            margin-bottom: 20px;
            @include sp {
                font-size: 23px;
            }

            a {
                font-size: 15px !important;
                float: right;
                font-weight: 400;
                margin-top: 10px;
                background-color: rgba(18, 99, 241, 0.16);
                padding: 3px 8px;
                border-radius: 5px;

                @include sp {
                    font-size: 14px;
                }
            }
        }

        .earn-item{
            padding: 15px;
            border-radius: 20px;
            border: 0.7px solid #DEEBED;
            background: #FFF;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
            margin-top: 15px;

            .logo-chain{
                padding-top: 6px;
                img{
                    width: 35px;
                }
                .sub-logo{
                    margin-left: -10px;
                }
            }
            .title{
                color: #0A2C31;
                font-family: AeonikMedium;
                font-size: 20px;
                line-height: normal;
                padding-top: 10px;
            }
            .price{
                border-radius: 20px;
                background: rgba(59, 213, 193, 0.20);
                padding: 5px 10px;
                text-align: center;
                margin-top: 10px;
            }
            .apr-info{
                @include sp {
                    margin-top: 10px;
                    padding-left: 15px;
                }
                .apr-title{
                    color: #3F666B;
                    font-family: Aeonik;
                    font-size: 14px;
                    line-height: normal;
                    letter-spacing: 0.28px;
                    margin-bottom: 10px;
                }
                .apr-value{
                    color: #3BD5C1;
                    font-family: AeonikRegular;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.36px;
                    span {
                        font-family: AeonikRegular;
                        font-size: 16px;
                    }
                }
            }
            a.btn-farm{
                color: #3BD5C1;
                text-align: center;
                font-family: AeonikRegular;
                font-size: 16px;
                border-radius: 10px;
                line-height: 35px;
                border: 1.5px solid #3BD5C1;    
                display: block;
                // margin-top: 10px;
                @include sp {
                    margin-top: 10px;
                }
            }
        }
        .earn-item:hover{
            // border: 1px solid #3BD5C1;
            box-shadow: 0 0 2px #5ffce0db, inset 0 4px 24px #e0efff;
            border-radius: 12px;
        }
        .staking-item{
            padding: 15px;
            border-radius: 20px;
            border: 0.7px solid #DEEBED;
            background: #FFF;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
            margin-top: 15px;
            .staking-logo-chain {
                padding-top: 6px;

                img {
                    width: 50px;
                }

                .staking-sub-logo {
                    width: 30px;
                    margin-top: -20px;
                    margin-left: -10px;
                }
            }
            .staking-title {
                color: #0A2C31;
                font-family: AeonikMedium !important;
                font-size: 20px;
                line-height: normal;
            }

            .staking-description {
                margin-top: 10px;
                color: #3F666B !important;
                line-height: normal;
            }
            .staking-apr-info {
                @include sp {
                    margin-top: 10px;
                    padding-left: 15px;
                }
                .staking-apr-title {
                    color: #3F666B;
                    font-family: Aeonik;
                    font-size: 14px;
                    line-height: normal;
                    letter-spacing: 0.28px;
                    margin-bottom: 10px;
                }

                .staking-apr-value {
                    color: #3BD5C1;
                    font-family: AeonikRegular;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.36px;

                    span{
                        font-family: AeonikRegular;
                        font-size: 16px;
                    }
                }
            }

            a.staking-btn-farm {
                color: #3BD5C1;
                text-align: center;
                font-family: AeonikRegular;
                font-size: 16px;
                border-radius: 10px;
                line-height: 35px;
                border: 1.5px solid #3BD5C1;
                display: block;
                margin-top: 10px;
            }
        }
        .staking-item:hover {
            // border: 1px solid #3BD5C1;
            box-shadow: 0 0 2px #5ffce0db, inset 0 4px 24px #e0efff;
            border-radius: 12px;
        }
    }
}
.tool-to-grow{
    background-image: url('../images/why-choose-bg.png');
    background-size: 100% 100%;
    background-position: center bottom;
    background-repeat: no-repeat;

    padding-top: 50px;
    padding-bottom: 60px;

    .tool-to-grow-title{
        color: #FFF;
        text-align: center;
        font-family: AeonikBold !important;
        font-size: 50px;
        line-height: normal;
        letter-spacing: 1px;
        max-width: 700px;
        margin: auto;
        margin-bottom: 15px;

        span{
            color: #3BD5C1;
            font-family: AeonikBold;
            font-size: 50px;
            letter-spacing: 1px;
        }
    }
    .grow-item{
        margin-top: 15px;

        .grow-img{
            
            margin: auto;
            margin-bottom: 20px;
            text-align: center;
            height: 190px;

            img{
                width: 100%;
                max-width: 184px;
                
            }
        }
        .grow-title{
            color: #3BD5C1;
            text-align: center;
            font-family: AeonikMedium;
            font-size: 26px;
            letter-spacing: 0.52px;
            height: 41px;
        }
        .grow-description{
            color: #0A2C31;
            text-align: center;
            font-family: Aeonik;
            font-size: 18px;
            margin-top: 15px;
            min-height: 130px;
        }
        .grow-button{
            text-align: center;
            float: left;
            width: 100%;
            padding-bottom: 10px;
            padding-top: 10px;
            margin-top: 15px;
            a{
                border-radius: 1000px;
                border: 1.5px solid #3BD5C1;
                padding: 10px 15px;
                color: #3BD5C1;
                width: fit-content !important;
            }
        }
    }
}
.backer{
    background: #fff;
    padding-top: 70px;
    padding-bottom: 70px;

    .backer-title {
        color: #FFF;
        text-align: center;
        font-family: AeonikBold !important;
        font-size: 50px;
        line-height: normal;
        letter-spacing: 1px;
        max-width: 700px;
        margin: auto;
        margin-bottom: 50px;

        span {
            background: linear-gradient(93deg, #34a7b4 37.75%, #4ec8b8 59.97%, #17c9cc 84.64%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: AeonikBold;
            font-size: 50px;
            letter-spacing: 1px;
        }
    }

    .backer-description{
        color: #0A2C31;
        text-align: center;
        font-family: Aeonik;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .backer-item{
        text-align: center;
        align-items: center;
        vertical-align: middle;
        margin-bottom: 30px;

        img {
            width: auto;
            max-width: 100%;
            height: 45px;
        }
    }
    ul{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        li{
            text-align: center;
            align-items: center;
            vertical-align: middle;
            width: 20%;
            float: left;
            height: 50px;
            margin-bottom: 30px;

            svg{
                width: 100%;
            }
            

            @include sp {
                width: 50%;
                margin-bottom: 20px;
            }

            img{
                width: 100%;
            }
        }
    }
}
.community{
    padding-bottom: 50px;

    .commu-block-left {
        position: absolute;
        left: 0;
        margin-top: 3%;
        // z-index: 1;

        img {
            width: auto;
        }

        ;

        @include sp {
            opacity: 0.3;
            left: 0;
            margin-top: 3%;
        }
    }

    .commu-block-right {
        position: absolute;
        right: 0;
        opacity: 1;
        margin-top: 10%;

        @include sp {
            opacity: 0.35;
            right: 0;
            margin-top: 90%;
        }

        img {
            width: auto;
        }

    }

    .community-title{
        color: #0A2C31;
        text-align: center;
        font-family: AeonikBold;
        font-size: 40px;
        letter-spacing: 0.8px;
    }
    .community-description{
        margin: auto;
        color: #0A2C31;
        text-align: center;
        font-family: AeonikRegular;
        font-size: 26px;
        letter-spacing: 0.52px;
        margin-bottom: 20px;
        max-width: 80%;
        position: relative;
        
        @include sp {
            max-width: 100%;
        }
    }
    .community-description-sub{
        max-width: 80%;
        margin: auto;
        color: #3F666B;
        text-align: center;
        font-family: Aeonik;
        font-size: 16px;

        @include sp {
            max-width: 100%;
        }
    }
    .community-list{
        max-width: 80%;
        margin: auto;
        margin-top: 20px;

        @include sp {
            max-width: 100%;
        }

        .community-item {
            width: auto;
            // height: 145px;
            flex-shrink: 0;
            text-align: center;
            padding: 20px;
            border-radius: 20px;
            
            @include sp {
                margin-bottom: 20px;
            }

            .community-img{
                width: auto;
            }
            .community-title{
                margin-top: 15px;
                color: #FFF;
                text-align: center;
                font-family: AeonikMedium;
                font-size: 20px;
            }
            .community-des{
                color: #FFF;
                text-align: center;
                font-family: Aeonik;
            }
        }
    
        // .bg-purple {
        //     background: linear-gradient(109deg, #9493FF 1.7%, #6563FF 96.25%);
        // }
    
        // .bg-black {
        //     background: linear-gradient(109deg, #4A4A4A 1.7%, #2F2F2F 96.25%);
        // }
    
        // .bg-blue {
        //     background: linear-gradient(109deg, #4CC2FC 1.7%, #2D9DD3 96.25%);
        // }
    }
    .community-end{
        margin-top: 50px;
        border-radius: 20px;
        background: url(../images/bg-com-end.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 50px;    
        padding: 50px;

        .end-info{
            .end-title{
                color: #FFF;
                font-family: AeonikBold;
                font-size: 26px;
                line-height: normal;
                letter-spacing: 0.72px;
            }
            .end-des {
                color: #FFF;
                font-family: Aeonik;
                font-size: 16px;
            }
        }
        .end-btn{
            display: inline-flex;
            padding: 14px 30px;
            justify-content: center;
            margin-top: 15px;
            align-items: center;
            text-align: center;
            border-radius: 30px;
            background: #FFF;

            a {
                color: #3BD5C1;
                text-align: center;
                font-family: AeonikRegular;
                font-size: 16px;
            }
        }
        .end-btn:hover {
            color: #fff;
            background: #3BD5C1;
            border: 1px solid #fff;

            a{
                color: #fff;
                font-family: AeonikRegular;
                    font-size: 16px;
            }
        }
    }
    
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}