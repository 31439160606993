.page-helascriptions{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .p-helascriptions-title {
        
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include sp {
            display: block;
        }
        span.title{
            color: #0A2C31;
            font-family: AeonikMedium;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.64px;
            @include sp {
                font-size: 28px;
                line-height: 45px;
            }
        }

        
    }
    .helascriptions-top {
        margin-top: 15px !important;
        padding: 15px;
        border-radius: 10px;
        padding-bottom: 7px !important;
        border-bottom: 1px solid #cadadd;
        // background: #fff;
    }
    .projects-btn-status button {
        width: auto;
        height: 35px;
        background: transparent;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 15px;
        padding: 0 15px;
        color: #3F666B;
        font-family: AeonikRegular !important;
        font-size: 16px !important;
        border: none;
        border-bottom: 3px solid transparent;
    }
    .projects-btn-status button.active,
    .projects-btn-status button:hover {
        background: transparent;
        border-radius: 0;
        color: #0ead98;
        border: none;
        border-bottom: 3px solid #0ead98;
        cursor: pointer;
    }
    .project-filter,
    .project-filter form {
        width: 100%;
        text-align: right;
    }
    .helascription-item {
        border-radius: 20px;
        border: 0.7px solid #D5E2E4;
        background: #FFF;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
        margin-bottom: 15px;
        padding: 15px;

        
        .item-row{
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            span{
                color: #3F666B;
                text-align: right;
                font-family: AeonikRegular;
                font-size: 16px;
            }
        }


    }

    
}
.btn-refresh{
    border-radius: 7px;
    padding: 5px 10px;
    border: 1px solid #0EAD98;
    background: transparent;
    cursor: pointer;
    color: #0EAD98;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 14px;    
    line-height: normal;
}
.helascription-content {
    border-radius: 16px;
    background: #EDF3F4;
    padding: 15px;
    min-height: 100px;

    code {
        p {
            margin-bottom: 5px;
            color: #3F666B !important;
        }
    }
}
.helascription-code{
    padding: 15px;
    border-radius: 20px;
    border: 0.7px solid #D5E2E4;
    background: #FFF;
}
.helascription-detail-code{
    height: 390px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include sp{
        height: auto !important;
    }
}

.page-helascription-market {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .helascription-market-title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: AeonikMedium;
        font-size: 45px;
        line-height: normal;
        letter-spacing: 0.5px;
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #062b31 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include sp {
            font-size: 35px;
        }
    }

    .go-detail{
        cursor: pointer;

        &:hover{
            opacity: 0.8;
        }
    }

    .projects-top {
        margin-top: 15px !important;
        padding: 15px 0px;
        border-radius: 10px;
        padding-bottom: 7px !important;
        border-bottom: 1px solid #cadadd;
        // background: #fff;
    }
    .project-content{
        width: 100%;
        margin-top: 15px;

        .helascription-header{
            padding: 15px;
            @include sp {
                display: none;
            }
        }
        .helascription-mk{

            .mk-item{
                border-radius: 10px;
                border: 0.7px solid #DEEBED;
                background: #FFF;
                box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
                padding: 15px;
                margin-bottom: 5px;
            }
            
        }
    }
    .projects-btn-status button {
        width: auto;
        height: 35px;
        background: transparent;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 15px;
        padding: 0 15px;
        color: #3F666B;
        font-family: AeonikRegular !important;
        font-size: 16px !important;
        border: none;
        border-bottom: 3px solid transparent;
    }

    .projects-btn-status button.active,
    .projects-btn-status button:hover {
        background: transparent;
        border-radius: 0;
        color: #0ead98;
        border: none;
        border-bottom: 3px solid #0ead98;
        cursor: pointer;
    }

    .project-filter,
    .project-filter form {
        width: 100%;
        text-align: right;

        @include sp {
            margin-top: 10px;
        }
    }
}
.helascription-totken-detail-page{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }
    .ant-progress-text{
        color: #0ead98;
        font-family: AeonikMedium !important;
        font-size: 16px !important;
    }
    .ant-progress-show-info .ant-progress-outer {
        margin-right: calc(-2em - 15px) !important;
    }
    .ant-progress-inner {
        background-color: #d6eaea !important;
    }

    .helascription-totken-detail {
        margin-top: 15px;
    }
    .card-airdrop {
        border-radius: 10px;
        border: 0.7px solid #DEEBED;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

        .ant-card-head-title {
            color: #0C434B !important;
            text-align: left;
            font-family: AeonikMedium;
            font-size: 20px;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }

        .ant-card-extra {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .airdrop-line {
            padding: 10px 0;
            border-bottom: 1px solid rgb(232, 232, 232);


        }

        .airdrop-line:nth-last-child(1) {
            border-bottom: none !important;
        }

        .airdrop-btn-grp {
            margin-bottom: 10px;

            .airdrop-btn {
                margin-bottom: 10px;
                margin-right: 10px;
                width: 100%;
                height: auto;
                border: 1px solid transparent;
                background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
                color: #fff;
                border-radius: 15px;
                font-size: 16px;
                line-height: normal;
                font-family: AeonikMedium;
                text-align: center;
                padding: 10px 20px;
                cursor: pointer;

                &:disabled {
                    opacity: 0.5;
                    background: #0EAD98;
                    color: #fff;
                    text-align: center;
                    font-family: AeonikMedium;
                    font-size: 16px;
                    cursor: not-allowed;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    
}
.person-item {
    border-radius: 20px;
    border: 0.7px solid #D5E2E4;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    margin-bottom: 15px;
    padding: 15px;
}