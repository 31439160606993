.mb-0{
    margin-bottom: 0px !important;
}
.main-nft-profile {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 0px;
    margin-bottom: 100px;
    margin-top: 90px;

    @include sp {
        padding-top: 100px;
        margin-top: 0px;
    }

    .nft-item{
        border-radius: 15px;
        border: 1px solid #DEEBED;
        background: #FFF;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
        padding: 13px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        .nft-img{
            margin-bottom: 10px;
            img {
                width: 100%;
                border-radius: 15px;
            }
            
        }
        .nft-info{
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            span{
                color: #3F666B;
                font-family: Aeonik;
                line-height: normal;
                font-size: 15px;

                &:nth-last-child(1){
                    font-family: AeonikRegular;
                    font-size: 16px;
                }
            }
            
        }
    }
    .nft-profile-user{
        display: flex;

        .profile-avatar{
            margin-right: 7px;
            width: 150px;
            height: 150px;
            margin-top: -75px;
            position: relative;
            z-index: 999;
            @include sp {
                width: 100px;
                height: 100px;
                margin-top: 10px;
            }
            
            img{
                border-radius: 1000px;
                border: 8px solid #EDF3F4;
                width: 100%;
            }
        }
        .profile-info{
            padding: 10px;
            @include sp {
                padding-top: 40px;
            }

            .profile-name{
                color: #3F666B;
                font-family: AeonikRegular;
                line-height: normal;
                font-size: 18px;
                margin-bottom: 10px;
                @include sp {
                    font-size: 16px;
                }
            }
            .profile-wallet{
                color: #3F666B;
                font-family: Aeonik;
                line-height: normal;
                font-size: 17px;
                @include sp {
                    font-size: 16px;
                }
                span{
                    font-family: AeonikRegular !important;
                    background: #F3F4F6;
                    padding: 5px 15px;
                    border-radius: 10px;
                }
            }
        }
    }
}
.nft-item {
    border-radius: 15px;
    border: 1px solid #DEEBED;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    padding: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    cursor: pointer;

    .nft-img {
        margin-bottom: 10px;

        img {
            width: 100%;
            border-radius: 15px;
        }

        
    }

    .nft-info {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        span {
            color: #3F666B;
            font-family: Aeonik;
            line-height: normal;
            font-size: 16px;

            &:nth-last-child(1) {
                font-family: AeonikRegular;
                font-size: 17px;
            }
        }
    }
}
.modal-list-done{
    width: 100% !important;
    max-width: 700px !important;

    .modal-nft{
        border-radius: 24px;
        border: 1px solid #DEEBED;
        background: #FFF;
        margin-bottom: 15px;
        padding: 10px;

        .modal-nft-img{
            margin: auto;
            img{
                width: 100%;
                border-radius: 10px;
            }
        }
        .modal-nft-info{

            .nft-info-title{
                color: #1E2329;
                font-family: AeonikRegular;
                font-size: 16px;
                line-height: normal;
                letter-spacing: 0.16px;
                margin-bottom: 10px;
            }
            .nft-info-name{
                color: #1E2329;
                font-family: AeonikMedium;
                font-size: 24px;
                line-height: normal;
                margin-bottom: 30px;
            }
            .nft-info-label{
                color: #707A8A;
                font-family: Aeonik;
                font-size: 14px;
                line-height: normal;
                margin-bottom: 10px;
            }
            .nft-info-price{
                display: flex;
                color: #3F666B;
                font-family: AeonikMedium;
                font-size: 20px;
                line-height: normal;
                img{
                    margin-right: 5px;
                    width: 20px;
                }
            }
        }
    }
    .modal-list-info{
        border-radius: 24px;
        border: 1px solid #E9EFF5;
        background: #F3F4F6;
        padding: 15px;

        .label-info{
            color: #3F666B;
            font-family: Aeonik;
            font-size: 16px;
        }
        .label-value {
            color: #3F666B;
            font-family: AeonikRegular;
            font-size: 16px;
        }
    }
}
.list-for-sale-block{
    .choose-type{
        color: #1E2329;
        font-family: AeonikMedium;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 10px;
    }
    .type-of-sale{
        margin-top: 10px;

        ul{
            padding-left: 0px;

            li{
                border-radius: 10px;
                border: 1px solid #DBE1EA;
                background: #FFF;
                margin-bottom: 10px;
                padding: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                &.active{
                    border: 1px solid #3BD5C1;
                    background: rgba(59, 213, 193, 0.05);

                    span {
                        .icon-check {
                            color: #3BD5C1 !important;
                        }
                    }
                }
                .sale-info{
                    p{
                        margin-bottom: 5px;
                    }
                }
                
            }
            
        }
    }
}