@font-face {
    font-family: AeonikRegular;
    src: url(../fonts/Aeonik/Aeonik-Regular.otf);
}
@font-face {
    font-family: AeonikMedium;
    src: url(../fonts/Aeonik/Aeonik-Medium.otf);
}
@font-face {
    font-family: Aeonik;
    src: url(../fonts/Aeonik/Aeonik-Light.otf);
}
@font-face {
    font-family: AeonikBold;
    src: url(../fonts/Aeonik/Aeonik-Bold.otf);
}

html {
 
    .c-infoWallet{
        &__logo p span{
            color:#16181A !important;
        }
        &__row p{
            color:#16181A !important;
        }
        &__rank{
            background:#fff !important;
            li{
                & img{
                filter: none;
                }
                & p{
                    color: #3F666B;
                    font-family: AeonikMedium;
                    font-size: 18px;
                }
            }
        }
        &__txt{
            color: #3F666B;
            font-family: Aeonik;
            font-size: 18px;
            margin-top: 15px;
        }
    }
    .c-btn--border{
        background: transparent;
        &:hover{
            background:#EDF3F4;
        }
    }
    .text-center{
        text-align: center;
    }
    .c-list{
        &__tag{
            span{
                color: #000
            } 
        }
        &__item.bg{
            background: radial-gradient(circle, #8E9EAB 10%, #EEF2F3 80%);
        } 
        &__body{ 
            &.bg{
            background: #FFFFFF;
            }
            & .ino-list .list-item .name-item {
                color:#181824
            }
            & .ino-list .list-item .img-item{
                border-radius: 99px; 
                background: radial-gradient(circle, #8E9EAB 10%, #EEF2F3 80%);
            }
        }
        &.is-joinedClaim {
            & .c-list__item{
                background: #f6f8fa !important;
            } 
            & .c-list__logo{
                background:#FFFFFF !important;
            }
            & .c-list__title h3{
                color:#16181A;
            }
        }
        &-ino__head{
            color:#000
        }
    } 
    .countdown{
        color: rgb(46, 194, 194);
        font-family: AeonikRegular;
    }
    .c-tabs__nav {
        li{ 
            &:hover {
                a{
                    color: #16181A;
                }
            }
           
            &:after{
                background: #16181A;
            }
        }
        .is-active a{
            color: #16181A; 
            &:hover{
                color: #16181A;  
            }
        }
    }
    .c-statusRow{
        background: #FFFFFF;
    }
    .c-tabs__navSP{
        background: #FFFFFF;
    }
    .pani-list .ant-pagination .ant-pagination-prev .ant-pagination-item-link, .pani-list .ant-pagination .ant-pagination-next .ant-pagination-item-link{
        background: rgb(46, 194, 194);
    }
    .pani-list .ant-pagination .ant-pagination-item a{
        color:#8B8C92
    }
    .c-inodetail{
        // &__main_title{
        //     color:#16181A
        // }
        &__right .count{
            color:rgb(46, 194, 194)
        }
        &__price{
            background-color:#FFFFFF;
            transition: 0.3s ease-in-out;
            box-shadow: 0px 5px 5px rgb(0 0 0 / 15%);
        }
    } 
    // .c-inoType{
    //     &-item{
    //         background:#FFFFFF;
    //         box-shadow: 0px 5px 5px rgb(0 0 0 / 15%);
    //         &:hover{
    //             background: #2A2D3A; 
    //             & .name{
    //                 color:#FFD60A
    //             }
    //             & .total{
    //                 color:#FFFFFF
    //             } 
    //         }
    //         &.active{
    //             background: #2A2D3A; 
    //             & .name{
    //                 color:#FFD60A
    //             }
    //             & .total{
    //                 color:#FFFFFF
    //             } 
    //         }
    //         &-nameTotal{
    //             & .name{
    //                 color:#000
    //             }
    //         }
    //     }
    // }
    .c-progress-ino{
        &__icon{
            background:#FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &__barIno{
            background:#E9ECEF;
            &.running{
                background: linear-gradient(270deg, #18dcd2 0%, rgba(9, 169, 203, 0.00) 102.21%);
            }
        } 
    }
    .ino_amount{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        // & .buy-now{
        //     background: #E8E8E8 ;
        //     &.active{
        //         background: #c5d9dd;
        //     }
        // }
        // &__BoxMax .max {
        //    color: #F67519
        // }
        &__estimated .price{
            color:#313132;
        }
    }
    .c-project{
        background: #F6F8FA;
        // .itemheader{
        //     background: #F6F8FA;
        //     border: 1px solid #C4C4C4;
        //     &.active{
        //         background: #2A2D3A;
        //     } 
        // }
        & .detail{
            background: #F6F8FA ; 
               & .flex span{
                color: #313132;
               } 
        }
    }
    .c-whatBox{ 
        &__head p.is-des{
            color:#C4C4C4
        }
        &__item{
            background: #FFFFFF;
        } 
        & .is-name {
            color:#000
        }
        & .is-color {
            color:#000 !important
        }
        & .is-des {
            color:#000
        }
    }
    .c-timeLine {
        background: #fff;
    }
    .tab-myorder_border{ 
        .total-box .text-total{
            color:#000
        }
        // .tab-myorder{
        //     background: linear-gradient(180deg, #FFE6B1 0%, #FFE6B1 100%);
        //     .title{
        //         color:#F67519
        //     }
        // }
        .list-myorder{
            background: #fff;
            & .myorder__item{
                .title{
                    font-weight: 400;
                }
                span{
                    color:#313132;
                    font-weight: 600;
                } 
                &.is-box{
                    color: #313132;
                }
            }
        }
    }
    .btn-upcoming{
        background: #f0f0f0 !important;
    }
    .p-airdroplist__head{
        h3{
            color:#000
        }
    }
    // .c-inoType-item.disable {
    //     background: #bfbfbf !important;
    //     &:hover{
    //         .name{
    //             color:#000
    //         }
    //         .total{
    //             color:#74757C
    //         }
    //     }
    // }
    .c-timeLine{
        background: #F6F8FA;
        &__item{
            background: #cfdddf;
            &:nth-child(2n){
                background: #fff;
            } 
            h3{
                color:#313132
            }
            p{
                color:#8B8C92
            }
        } 
    }
    
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1280px;
    }
}
body {
    font-family: Aeonik !important;
    color: #3F666B;
    font-size: 16px !important;
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #22252F;
    } 
    &::-webkit-scrollbar-thumb {
        background-color: #FFD60A;
        border-radius: 2px;
    }
}
.p-app {
    width: 100%;
    height: 100vh;    
    
    transition: 0.3s ease-in-out;
    @include tablet {
        padding-left: 0;
    }
    &.is-toggle {
        padding-left: 110px;
        @include tablet {
            padding-left: 0;
        }
    }
}
.l-main {
    width: 100%;
    padding: 35px 25px 25px 40px;
    height: 100vh;
    @include min {
        padding: 35px 75px 75px 75px;
    }
    @include tablet {
        padding: 100px 25px 30px;
    }
}