.project-item-close {
    height: auto;
    min-height: auto !important;
}

.project-block-detail {
    
    background: url(../../common/asset/images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;
}

/* .project-block-detail::before {
    background: #00a3ff;
    opacity: 0.5;
    filter: blur(509px);
    content: "";
    right: 0px;
    height: 500px;
    width: 500px;
    display: block;
    position: absolute;
    top: -70px;
}

.project-block-detail::after {
    background: #00a3ff;
    opacity: 0.5;
    filter: blur(509px);
    content: "";
    height: 500px;
    width: 500px;
    display: block;
    position: absolute;
} */

.detail-left {
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 15px;
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 10px;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.detail-left-img {
    width: 100%;
    padding: 7px;
}

.detail-left-img img {
    width: 100%;
}

.detail-time {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    text-align: center;
}

.text-time {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    text-align: center;
    color: #595b6b;
}

.detail-progress-percent .ant-progress-text {
    visibility: hidden;
}

.detail-progress-percent {
    margin-top: 5px;
}

.loading-center {
    width: 100%;
    text-align: center;
}

.ml-10 {
    margin-left: 10px;
}

.detail-progress-percent .ant-progress-outer {
    padding-right: 0px !important;
}

.detail-progress-percent .ant-progress-bg {
    height: 12px !important;
    background: linear-gradient(270deg, #ee7620 0%, #ffd540 101.35%) !important;
    background-color: transparent !important;
}

.detail-progress-percent .ant-progress-inner {
    background-color: #e6eef7 !important;
}

.project-total-sell {
    width: 100%;
    text-align: left;
}

.project-total-sell span {
    color: #9295ab;
    font-size: 14px;
}

.project-total-sell span:nth-child(2) {
    float: right;
}

.grp-btn-claim {
    /* max-width: 150px; */
    margin: auto;
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.time-coundown {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    float: left;
}

.process-tc {
    width: 145px;
    text-align: center;
    margin: auto;
}

.process-tc .timer-count {
    float: left;
    width: 35px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #0EAD8C;
    font-family: AeonikMedium !important;
}

.process-tc .timer-count small {
    display: block;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #3F666B;
    margin-left: -5px;
}

.detail-right {
    width: 100%;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

}

.detail-title {
    font-family: AeonikMedium !important;
    font-size: 24px;
    line-height: 30px;
    color: #3F666B !important;
}

.detail-title .network-right {
    float: right;
}

.detail-title .network-right img {
    height: 20px;
    margin-right: 0px;
}

.detail-title .network-right span {
    margin-right: 5px;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.edit-ido {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #595b6b;
    display: inline-block;
}

.edit-ido span {
    float: left;
    margin-top: 4px;
    margin-right: 3px;
}

.detail-status {
    width: 100%;
    margin-top: 10px;
}

.detail-status .icon {
    color: #9295ab !important;
    font-size: 22px;
}

.detail-social {
    float: right;
    margin-left: 7px;
    font-size: 22px !important;
}

.detail-social svg {
    color: #386dea;
    font-size: 22px !important;
}

.detail-social svg:hover {

    color: #ee7620;
}

.detail-description {
    width: 100%;
    color: #595b6b;
    margin-top: 15px;
}

hr.hr-cus {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0.5px solid #b4b7ce !important;
    width: 100%;
    float: left;
}

.detail-sub-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #121528;
    margin-bottom: 8px;
    width: 100%;
    float: left;
}

.detail-text {
    width: 100%;
    margin-top: 15px;
    color: #3F666B;
    float: left;
    font-size: 16px;
}

.detail-text .left {
    width: 30%;
    float: left;
}

.detail-text .right {
    width: 70%;
    float: left;
}

.detail-text .right span {
    color: #ee7620;
}

.caculator-vs-schedule {
    font-size: 20px !important;
    color: #ee7620 !important;
}

.detail-info-tabs {
    width: 100%;
    padding: 15px;
}

.tab-detail-info {
    margin-top: 7px;
    width: 100%;
    font-size: 16px;
}

.tab-detail-info span:nth-child(1) {
    color: #595b6b;
}

.tab-detail-info span:nth-child(2) {
    float: right;
}

.info-tab-cus .ant-tabs-tab {
    color: #595b6b;
    font-family: AeonikRegular !important;
    font-size: 16px;
}

.info-tab-cus .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0EAD98 !important;
}

.info-tab-cus .ant-tabs-ink-bar {
    background: #0EAD98;
}

.btn-tab-detail {
    height: 40px;
    border: 1px solid #b4b7ce;
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.btn-tab-detail span {
    color: #121528 !important;
}

.btn-tab-detail:hover {
    border: 1px solid #0EAD98;
}

.btn-tab-detail:hover span {
    color: #0EAD98 !important;
}

.progress-number {
    font-size: 14px !important;
    color: #0EAD98 !important;
    font-family: Aeonik !important;
}

.color-chart-text {
    color: #0EAD98 !important;
    font-family: Aeonik !important;
}

.btn-cancel-pool {
    border: 1px solid #ff3b6b;
}

.card-ico-detail {
    border-radius: 7px;
}

.card-ico-detail .ant-card-head-title {
    font-family: AeonikRegular !important;
    font-size: 20px;
}

.card-ico-detail .ant-card-body {
    padding-top: 5px !important;
}

.btn-cancel-pool span {
    color: #ff3b6b !important;
}

.detail-buy {
    width: 100%;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.detail-buy form .ant-form-item {
    margin-bottom: 10px !important;
}

.btn-buy {
    border-radius: 5px !important;
}

.m-10 {
    margin: 10px 0 10px 0;
}

.token-info-detail {
    max-width: 100% !important;
    margin-top: 0px !important;
}

@media screen and (max-width: 768px) {
    .detail-text .left {
        width: 35%;
        float: left;
    }

    .detail-text .right span {
        word-break: break-all;
    }

    .detail-text .right {
        width: 65%;
        float: left;
        padding-left: 10px;
    }

    .project-block-detail {
        padding-left: 15px;
        padding-right: 15px;
    }

    .project-block-detail::before {
        width: 90%;
    }

    .project-block-detail::after {
        width: 90%;
    }
}