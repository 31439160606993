html {
    // .c-header {
    //     background: #fff;
    //     transition: 0.3s ease-in-out;
    // }
    .c-sidebar {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        &.is-toggle {
            .c-nav {
                li.is-active {
                    svg path {
                        stroke: #F67519;
                    }
                    &:after {
                        background-color: #F67519;
                    }
                }
            }
        }
        // &__logo {
        //     border-bottom: 2px solid #EEEEEE;
        //     transition: 0.3s ease-in-out;
        //     a{
        //         background-image: url("../images/logo-den.svg"); 
        //     }
        //     &.footer{
        //         border-bottom: 0px !important; 
        //         padding: 0px;
        //         margin-bottom: 8px;
        //         height: auto;
        //     }
        // }
        // &__toggle {
        //     background: #F5F5F5;
        //     transition: 0.3s ease-in-out;
        //     img {
        //         filter: brightness(0.5);
        //         transition: 0.3s ease-in-out;
        //     }
        // }
        // &__footer {
        //     border-top: 2px solid #EEEEEE;
        //     transition: 0.3s ease-in-out;
        // }
        // &__content {
        //     &::-webkit-scrollbar {
        //         width: 1px;
        //         background-color: #eee;
        //     } 
        //     &::-webkit-scrollbar-thumb {
        //         background-color: #FFD60A;
        //         border-radius: 1px;
        //     }
        // }
    }
    .c-btn {
        &:disabled {
            background: #E8E8E8;
            color: #8B8C92;
        }
    }
    .c-nav {
        li {
            a {
                color: #646670;
                transition: 0.3s ease-in-out;
                &:hover {
                    background-color: #E8E8E8;
                }
            }
            &.is-active {
                a {
                    &:hover {
                        background-color: #FFD60A;
                    }
                }
                .c-nav__sub {
                    a {
                        &:hover {
                            background-color: #E8E8E8;
                        }
                    }
                }
            }
        }
        &__sub {
            li {
                &.is-active {
                    a {
                        color: #F67519;
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .c-crossChain {
        @include sp {
            display: none;
        }
        &__showName {
            color: #16181A;
        }
        &__list {
            // background: rgba(249, 249, 249, 0.482);
            li {
                // background-color: transparent;
                &:hover {
                    border-radius: 10px;
                    background: #EDF3F4 !important;
                }
            }
        }
    }
    .c-footer  {
        background: #fff;
        &__social {
            li {
                a {
                    svg {
                        path {
                            fill: #3785FA;
                        }
                    }
                    &.is-cryptorank {
                        svg {
                            width: 100%;
                            rect {
                                fill: #0587FF;
                                transition: .3s ease-in-out;
                            }
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
        &__price {
            span,
            a {
                color: #F67519;
            }
        }
        &__row {
            span {
                color: #8B8C92;
            }
        }
    }
    .c-darkMore {
        label {
            input {
                &:checked {
                    + .c-darkMore__toggle {
                        .c-darkMore__dark {
                            svg {
                                path {
                                    stroke: #FFEF8F;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__toggle {
            background: linear-gradient(180deg, #FE9A00 0%, #FFE679 100%);
            &:before {
                background: #fff;
            }
        }
        &__dark {
            svg {
                path {
                    stroke: #FFEF8F;
                }
            }
        }
    }
    .c-tabs {
        &__nav {
            li {
                a {
                    color: #8B8C92;
                }
                // &.is-active {
                //     a {
                //         color: #16181A;
                //     }
                // }
                // &:after {
                //     background: #16181A;
                // }
            }
        }
        &__ask {
            p {
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                color: #8B8C92;
            }
        }
    }
    .c-list {
        &__item {
            background: #fff;
        }
        // &__head {
        //     color: #16181A;
        // }
        &__row {
            // color: #8F8E93;
            span {
                // &:last-child {
                //     color: #181824;
                // }
                &.down {
                    color: #F73D1E;
                }
                &.up {
                    color: #00BE08;
                }
            }
        }
        &__line {
            background: #eee;
        }
        &__footer {
            p {
                color: #8F8E93;
                span {
                    &:last-child {
                        color: #F67519;
                    }
                }
            }
        }
        &.is-completed {
            .c-list__title {
                color: #313132;
            }
        }
        &.is-joinedClaim {
            .c-list__item {
                background: #FFFFFF;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            }
            .c-list__logo {
                background: #F6F8FA;
            }            
        }
        &.is-claimable {
            .c-list__item {
                background: #FFFFFF;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            }
            .c-list__logo {
                background: #F6F8FA;
            }
            .c-list__title {
                h3 {
                    color: #313132;
                }
            }
            .c-list__content {
                &.claimable_refunded {
                    &::before {
                        background: #F5F5F5;
                    }
                }
            }
        }
    }
    .c-statusRow {
        h3 {
            color: #8B8C92;
        }
        &__ask {
            p {
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                color: #8B8C92;
            }
        }
    }
    .c-sort {
        &__by {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            li {
                background: #FFFFFF;
                border-radius: 2px;
                &:hover {
                    background: #FFD60A;
                    color: #313132;
                }
            }
        }
    }
    // .pani-list {
    //     .ant-pagination-prev,
    //     .ant-pagination-next {
    //         .ant-pagination-item-link {
    //             background: #FFD60A;
    //             color: #000;
    //         }
    //     }
    //     .ant-pagination-disabled {
    //         opacity: .3;
    //     }
    //     .ant-pagination-item {
    //         a {
    //             color: #000;
    //         }
    //         &.ant-pagination-item-active {
    //             background: rgba(255,214,10,0.2) !important;
    //             border: 1px solid rgba(255,214,10,1);
    //             border-radius: 3px;
    //             a {
    //                 color: #8B8C92;
    //             }
    //         } 
    //     }
        
    // }
    // .c-infoIdo {
    //     background: #fff;
    //     // &__head {
    //     //     color: #16181A;
    //     // }
    //     &__text {
    //         color: #8B8C92;
    //     }
    //     &__line {
    //         background: #eee;
    //     }
    //     &__row {
    //         color: #8F8E93;
    //         span {
    //             &:last-child {
    //                 color: #181824;
    //             }
    //         }
    //     }
    //     &__contract {
    //         background: #F6F8FA;
    //         input {
    //             background: #F6F8FA;
    //             color: #181824;
    //         }
    //         button {
    //             background: #FFD60A;
    //             img {
    //                 filter: brightness(0);
    //             }
    //         }
    //     }
    // }
    .p-home {
        min-height: 100vh;
        &__banner {
            background: linear-gradient(180deg, rgba(244, 249, 253, 0.1) 0%, rgba(245, 245, 247, 0.1) 100%);
            backdrop-filter: blur(77.8321px);
            border: 5px solid #FFE6B1;
            transition: 0.3s ease-in-out;
        }
        &__head {
            h2 {
                color: #16181A;
                transition: 0.3s ease-in-out;
            }
            p {
                color: #16181A;
                transition: 0.3s ease-in-out;
            }
        }
        &__project {
            border: 5px solid #FFE6B1;
            transition: 0.3s ease-in-out;
            h3 {
                color: #16181A;
                transition: 0.3s ease-in-out;
            }
        }
        &__ttl {
            color: #16181A;
            transition: 0.3s ease-in-out;
        }
        &__qa {
            .c-qa {
                &__title {
                    color: #16181A;
                    transition: 0.3s ease-in-out;
                }
                &__content {
                    color: #16181A;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
    // .c-footerHome {
    //     background: #D9D9D9;
    //     transition: 0.3s ease-in-out;
    //     &_sologun{
    //         color:#8B8C92;
    //         font-weight: 400;
    //         font-size: 16px;
    //         letter-spacing: 0.025em;
    //     }
    //     &__row {
    //         ul {
    //             li {
    //                 a {
    //                     color: #313132;
    //                     font-weight: 400;
    //                     transition: 0.3s ease-in-out;
    //                 }
    //             }
    //         }
    //         p {
    //             color: #313132;
    //             font-weight: 500;
    //         }
    //     }
    //     &__copy {
    //         background: #2A2D3A;
    //         color: #fff; 
    //     }
    // }
    .p-launchpadDetail {
        &__right {
            // background: #fff;
            .c-phase {
                .ant-tabs-nav {
                    background-color: #F5F5F5;
                }
                .ant-tabs-tab-btn {
                    color: #8F8E93;
                }
                > .ant-tabs-nav {
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            > .ant-tabs-tab:before {
                                display: none;
                            }
                        }
                    }
                }
            }
            .c-tabs {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        color: #8B8C92;
                        // &.ant-tabs-tab-active {
                        //     .ant-tabs-tab-btn {
                        //         color: #313132;
                        //     }                            
                        // }
                        // &:after {
                        //     background: #313132;
                        // }
                    }
                }
            }
            .c-campaignRound {
               
                // &__content {
                //     border-top: 1px solid #EEEEEE;
                // }
                
                
                &__info {
                    // background: #fff;
                    p {
                        // color: #181824;
                        span {
                            // color: #8F8E93;
                        }
                    }
                }
                &__table {
                    table {
                        background: #fff;
                        th {
                            color: #8B8C92;
                            background: #EDF3F4;
                        }
                        td {
                            border-bottom: 1px solid #EEEEEE;
                            color: #181824;
                        }
                    }
                }
            }
        }
    }
    .c-progress {
        
        // &__txt {
        //     color: #8B8C92;
        //     span {
        //         &:first-child {
        //             color: #181824;
        //         }
        //     }            
        // }
        &__bar {
            background: #E9ECEF;
            span {
                // background: linear-gradient(135deg, #FBCF33 0%, #F53939 100%);
            }
        }
    }
    .c-listCate {
        &__item {
            background: #F6F8FA;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        }
        &__title {
            color: #313132;
        }
        &__text {
            color: #8F8E93;
            p {
                margin: 0;
                span {
                    color: #8F8E93 !important;
                }
            }
        }
        &__price {
            p {
                color: #313132;
            }
        }
    }
    .c-listRecommen {
        &__item {
            background: #F6F8FA;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            border: 1px solid #F6F8FA;
            &:hover {
                border: 1px solid #FFD60A;
            }
        }
        &__title {
            color: #313132;
        }
        &__text {
            color: #8F8E93;
            p {
                margin: 0;
                span {
                    color: #8F8E93 !important;
                }
            }
        }
        &__price {
            p {
                color: #313132;
            }
        }
    }
    .p-gameHub {
        &__cate {
            ul {
                li {
                    a {
                        color: #8B8C92;
                        &.is-active {
                            background: #2A2D3A;
                            color: #FDFCFF;
                        }
                    }
                }
            }
        }
        &__title {
            color: #313132;
        }
        &__btn {
            button {
                background: #C4C4C4;
                color: #313132;
            }
        }
        &__pagination {
            .ant-pagination {
                .ant-pagination-prev,
                .ant-pagination-next {                
                    .ant-pagination-item-link {
                        color: #71717A;
                        background: #C4C4C4;
                        border-radius: 2px;
                        border: 1px solid #C4C4C4;
                    }
                    &.ant-pagination-disabled {
                        .ant-pagination-item-link {
                            opacity: .3;
                        }
                    }
                    &:hover {
                        .ant-pagination-item-link {
                            color: #1A1C1E;
                            border: 1px solid #FFD60A;
                            background: #FFD60A;
                        }
                    }
                }
                .ant-pagination-item  {
                    background: #C4C4C4;
                    opacity: 0.3;
                    border-radius: 2px;
                    border: 1px solid #C4C4C4;
                    a {
                        color: #74757C;
                    }
                    &:hover,
                    &.ant-pagination-item-active {
                        opacity: 1;
                        background: #FFD60A;
                        border: 1px solid #FFD60A;
                        a {
                            color: #1A1C1E;
                        }
                    }
                }
            }
        }
    }

    .p-gameHubDetail {
        .btn-back {
            img {
                filter: brightness(0);
            }
            .text-back {
                color: #313132;
            }
        }
        &__header {
            .title {
                .text-banner {
                    color: #313132;
                }
            }
            .btn-play {
                background: #FFD60A;
                border-radius: 10px;
                .text-play {
                    color: #313132;
                }
            }
        }
        &__right {
            background: #F6F8FA;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            .main-text {
                color: #9F9F9F;
            }
            .price-img {
                .price {
                    color: #313132;
                }
            }
            .tab-info {
                .tab-info-item {
                    .left-text {
                        color: #8B8C92;
                    }
                    .right-text {
                        color: #313132;
                    }
                }
            }
            .aboutGame {
                background: #FFFFFF;
                border: 0.75px solid #fff;
                .header {
                    color: #F67519;
                }
                .description {
                    color: #8B8C92;
                    span {
                        color: #8B8C92 !important;
                    }
                }
            }
        }
    }

    .p-airdropDetail {
        h2 {
            color: #313132;
        }
        ul {
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 2px;
            li {
                color: #313132;
            }
        }
    }
}