.none-data {
	width: 100%;
	text-align: center;
	margin-top: 45px;
	position: relative;
}

.none-data svg {
	color: #1fcdaa30;
	font-size: 50px;
}

.none-data .text {
	color: #1eccaa45;
	line-height: 0;
	margin-top: 5px;
}

.logo-main img {
	width: 50px;
	animation-name: spin;
	animation-duration: 16s, 16s, 16s;
	animation-iteration-count: infinite, infinite, infinite;
	-webkit-animation-timing-function: ease-in-out, linear, linear;
	transform: rotateX(6deg) rotateY(6deg) rotateZ(6deg);
}

.nodata-title {
	font-family: SVN_GilroyBold;
	font-style: normal;
	font-weight: 700;
	font-size: 25px;
	line-height: 56px;
	background: linear-gradient(to right, #000072 0%, #264afd 100%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	max-width: 700px;
	width: 100%;
}