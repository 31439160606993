.page-stake-token{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .p-stake-title {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 45px;
        letter-spacing: 0.5px;
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #062b31 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include sp {
            font-size: 35px;
        }
    }
    .projects-top {
        margin-top: 15px !important;
        padding: 15px;
        border-radius: 10px;
        padding-bottom: 7px !important;
        border-bottom: 1px solid #cadadd;
        // background: #fff;
    }
    .projects-btn-status button {
        width: auto;
        height: 35px;
        background: transparent;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 15px;
        padding: 0 15px;
        color: #3F666B;
        font-family: AeonikRegular !important;
        font-size: 16px !important;
        border: none;
        border-bottom: 3px solid transparent;
    }
    .projects-btn-status button.active,
    .projects-btn-status button:hover {
        background: transparent;
        border-radius: 0;
        color: #0ead98;
        border: none;
        border-bottom: 3px solid #0ead98;
        cursor: pointer;
    }
    .project-filter,
    .project-filter form {
        width: 100%;
        text-align: right;
    }
    .staking-item {
        border-radius: 16px;
        background: #eaeff0;
        overflow: hidden;
        box-shadow: 0 3px 20px 0 #18545c17;
        width: 100%;
        min-height: 300px;
        margin-top: 20px;
    }

    .staking-item-header {
        width: 100%;
        height: 130px;
        /* background: linear-gradient(90deg, rgb(0, 34, 86) 0%, rgb(5, 70, 184) 100%); */
        padding: 15px;
        // background-image: url(../../../../public/images/feedback-bg.png) !important;
        // background-size: 100% 100% !important;
        background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);

    }

    .staking-item-content {
        background: rgb(255, 255, 255);
        border-radius: 16px;
        padding: 15px;
        position: relative;
        margin-top: -16px;
        overflow: hidden;
        min-height: 160px;
    }
    .staking-period{
        color: #fff;
        font-family: AeonikMedium;
        font-size: 15px;
        position: absolute;
        right: 0px;
        top: -15px;
        background: #239196;
        padding: 10px;
        line-height: normal;
        border-bottom-left-radius: 20px;

        @include sp{
            right: -10px !important; 
        }
    }
    .staking-title {
        color: #fff;
        font-family: AeonikMedium;
        font-size: 20px;
    }

    .icon-title {
        margin-left: 5px;
    }

    .staking-description {
        font-family: AeonikRegular;
        font-size: 15px !important;
        color: #fff;
        line-height: 1.5;
        margin-top: 5px;
    }

    .staking-status {
        align-items: center;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 0px;
        width: fit-content;
        background: rgb(228, 239, 255);
        border-radius: 10px;
        margin-top: 10px;
        text-align: center;
        display: inline-grid;
        font-family: AeonikRegular;
        font-size: 14px !important;
        color: #3F666B;
    }

    .stake-logo {
        width: 100%;
        text-align: right;
    }

    .stake-logo img {
        width: 100%;
        max-width: 85px;
        opacity: 0.7;
    }

    .apr-content {
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        height: 48px;
        padding: 0px 16px;
        border-radius: 8px;
    }

    .apr-content-caculator {
        text-transform: uppercase;
        font-family: AeonikRegular;
        color: #3F666B;
        height: 48px;
        padding-top: 10px;
    }

    .apr-content-caculator span {
        margin-right: 5px;
        margin-left: 5px;
    }

    .apr-content-caculator span svg {
        font-size: 20px;
        margin-top: 2px;
    }

    .apr-content-percent {
        line-height: 48px;
        font-family: AeonikRegular;
        color: #3F666B;
        font-size: 16px;
    }

    .apr-button {
        margin-top: 15px;
        width: 100%;
        text-align: center;
    }

    .apr-description {
        width: 100%;
        margin-top: 15px;
    }

    .apr-description p:nth-child(1) {
        margin-bottom: 2px;
    }

    .staking-item-detail {
        background: #eaeff0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-family: AeonikRegular;
        color: #3F666B;
        font-size: 14px;
    }

    .btn-detail {
        border: none;
        background: transparent;
        font-family: AeonikRegular;
        color: #3F666B;

        span{
            font-family: AeonikRegular;
            color: #3F666B !important;
            font-size: 16px;
        }
    }

    .btn-detail:focus,
    .btn-detail:hover {
        border: none;
        background: transparent;
        color: rgb(53, 40, 243);
        cursor: pointer;
    }

    .staking-detail-content {
        width: 100%;
        margin-top: 15px;
        padding: 15px;
    }

    .total-staking {
        text-align: left;
        font-family: AeonikRegular;
        color: #3F666B !important;
        font-size: 16px;
    }

    .total-staking .total {
        font-weight: 700;
        font-family: AeonikMedium;
        color: #3F666B !important;
    }

    .total-staking small {
        color: #3F666B !important;
    }

    .btn-max {
        height: 40px;
        min-width: 60px !important;
        font-weight: 600;
        border: none;
        margin-left: 10px;
    }

    .btn-group {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .btn-group button:nth-child(1) {
        margin-right: 10px;
    }

    .stake-periods {
        background: linear-gradient(269.11deg, rgba(99, 148, 209, 0.32) -1.22%, rgba(21, 84, 161, 0.32) 101.48%);
        box-shadow: 0px 0px 1px rgb(255 255 255 / 59%), inset 0px 0px 22px rgb(149 170 209 / 21%);
        border-radius: 12px;
        padding: 3px 0px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        max-width: 105px;
        width: 100%;
        display: inline-block;

    }

    .table-roi {
        width: 100%;
    }

    .table-roi tr {
        line-height: 33px;
        background: #f5f5f5;
    }

    .tr-header {
        font-size: 15px;
        font-weight: 600;
        background: #ededed !important;
    }
}
.ant-tooltip-inner{
    color: #3F666B !important;
    font-family: AeonikRegular !important;
}
