.main-stake-pool-v2 {
    padding-bottom: 40px;
    padding-top: 130px;
    background-position: right !important;
    background-size: auto !important;
}

.item-info {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    justify-content: space-between;
}

.item-1 {
    width: 33%;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    background: linear-gradient(136deg, #1CE39A 1.63%, #09A9CB 100%);
    position: relative;
}
.item-1 img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
}
.item-info.row .item-1:nth-child(1) {
    width: 49.5%;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    background: linear-gradient(136deg, #1CE39A 1.63%, #09A9CB 100%);
}
.item-info.row .item-1:nth-child(3) , .item-info .item-1:nth-child(4) {
    border-radius: 20px;
    background: #50686C;
    width: 24.5%;
    padding: 20px 20px 20px 20px;
}

.item-info.res .item-1:nth-child(3) {
    width: 49.5%;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    background: linear-gradient(136deg, #1CE39A 1.63%, #09A9CB 100%);
}
.item-info.res .item-1:nth-child(1) , .item-info .item-1:nth-child(2) {
    border-radius: 20px;
    background: #50686C;
    width: 24.5%;
    padding: 20px 20px 20px 20px;
}

.big-title {
    background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 62px;
    line-height: normal;
    margin-bottom: 50px;
}

.title-item {
    color: #fff;
    text-align: left;
    font-family: AeonikRegular;
    font-size: 18px;
    line-height: normal;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.mg-auto{
    margin: auto;
}
.count-down-unstake {
    width: fit-content;
    margin-bottom: 15px;
    background: linear-gradient(136deg, #1CE39A 1.63%, #09A9CB 100%);
    padding: 15px 20px;
    border-radius: 8px;
    color: #fff;
    display: flex;
}
.count-down-unstake .title {
    color: #fff;
    text-align: left;
    font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-bottom: 10px; */
    display: block;
    margin-right: 10px;
}
.count-down-unstake .time-down {
    color: #fff;
    text-align: left;
    font-family: AeonikRegular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
}
.num-item {
    color: #FFF;
    font-family: Aeonik;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.8px;
}

.tabs-stake-pool.v2 {
    padding-top: 35px;
    padding-bottom: 60px;
}

.tabs-stake-pool.v2 .ant-tabs-tab-btn {
    color: #3F666B;
    font-family:Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}
.tabs-stake-pool.v2 .ant-tabs-ink-bar {
    background: #0EAD98;
}

.tabs-stake-pool.v2 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0EAD98;

font-family: Aeonik;
font-size: 18px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 0.36px;
}

.wrap-all {
    border-radius: 20px;
    border: 0.7px solid #DEEBED;
    width: 100%;
    background: #FFF;
    padding-bottom: 10px;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    min-height: 300px;
}

.stake {
    display: flex;
    padding: 0;
}

.action-pool {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 20%;
}

.your-stake {
    border-radius: 6px;
    border: 0.9px solid #E9EDF3;
    background: linear-gradient(90deg, #FFF9D9 -12.7%, #FFD9BD 112.66%);
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.your-stake.acc {
    border-radius: 6px;
    border: 0.9px solid #E9EDF3;
    background: linear-gradient(90deg, #FFF9D9 -12.7%, #FFD9BD 112.66%);
    height: 50%;
}

.your-stake .title {
    color: #707A8A;
    text-align: center;
    font-family: Aeonik;;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.your-stake .num {
    color: #1E2329;
    text-align: center;
    font-family: Aeonik;;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btn-action {
    width: auto;
    height: 40px;
}

.btn-action button {
    width: 140px;
    height: 100%;
}

.btn-stake-reward {
    border-radius: 1000px;
    background: #0A2C31;
    color: #fff;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border: 0px solid transparent;
    margin-right: 10px;
}

.btn-withdraw-reward {
    border-radius: 1000px;
    background: #0A2C31;
    color: #fff;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border: 0px solid transparent;
}
.btn-withdraw-reward.dis {
    border-radius: 1000px;
    background: #ECEFF4;
    color: #9AB3B6;
    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border: 0px solid transparent;
}
.btn-stake-reward.dis {
    border-radius: 1000px;
    background: #ECEFF4;
    color: #9AB3B6;
    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border: 0px solid transparent;
}
.action-button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.btn-stake-reward:hover {
    cursor: pointer;
}

.btn-withdraw-reward:hover {
    cursor: pointer;
}

.btn-stake-reward.dis:hover {
    cursor: not-allowed;
}

.btn-withdraw-reward.dis:hover {
    cursor: not-allowed;
}

.stake-your-token {
    color: #0EAD98;
    text-align: center;
    font-family: Aeonik;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.56px;
    padding-top: 40px;
}

img.img-step-stake {
    width: 30px;
    height: 30px;
    display: block;
}

.content-stake .ant-steps-icon {
    line-height: 0px !important;
}

.content-stake {
    padding: 15px 30px 30px 30px;
}
.ant-steps-item-title {

}
.content-stake .ant-steps-item-title:after {
    background-color: #AAB6CD !important;
}

/* .content-stake .ant-steps-item-active .ant-steps-item-title:after {
    background-color: #ff9100ed !important;
} */
.content-stake .ant-steps-item-title {
    font-size: 16px;
}

.content-stake .ant-steps-item-active .ant-steps-item-title {
    color: #0A2C31;

font-family: Aeonik;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.32px;
}
.ant-steps-item-icon, .ant-steps-item-content {
    display: block;
    align-items: center;
}
.content-stake .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(63, 102, 107, 0.70);

    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    line-height: 32px !important;
}

.text-ac {
    color: #0A2C31;

    line-height: 32px !important;
    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;

}

.content-step1 .title {
    color: #1E2329;
    text-align: center;
    font-family: Aeonik;;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 25px;
}

.content-step1 .des {
    padding-top: 5px;
    color: #707A8A;
    text-align: center;
    font-family: Aeonik;;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.item-step1 {
    display: flex;
    gap: 10px;
    padding-top: 15px;
}

.card-item {
    border-radius: 14px;
background: #EDF3F4;
    padding: 10px 5px 10px 5px;
    width: 25%;
    height: 200px;
    position: relative;

}
.mar-center {
    margin: 0 10px;
}
.title-card {
    color: #0A2C31;

    font-family: Aeonik;
    font-size: 16px;
    text-align: left;
    font-family: Aeonik;;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 30px;
}

.check-card {
    padding-bottom: 10px;
    position: absolute;
    right: 15px;
    top: 15px;
}

.check-card img {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;
}

.check-card.acive img:nth-child(2) {
    display: none;
}

.check-card.acive img:nth-child(1) {
    display: block;
}

.check-card img:nth-child(1) {
    display: none;
}

.content-card {
    color: #3F666B;

    font-family: AeonikRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.32px;
    padding: 10px 10px 10px 20px;
}
.content-card span.balance{
    color: #3F666B;
    font-family: AeonikMedium;
    font-size: 16px;
}

.check-rule .text:hover {
    cursor: pointer;
}

.check-real {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.check-real:hover {
    cursor: pointer;
}

/* .check-real span {
    padding-top: 5px;
} */

.steps-action {
    display: flex;
    width: 100%;
    padding-top: 20px;
    justify-content: center;
}

.btn-control:hover {
    cursor: pointer;
}

.btn-control {
    width: 120px;
    padding: 10px 15px;
    border: none;
    border-radius: 99px;
    background: #1e2329;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: Aeonik;;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-control.dis {
    width: 120px;
    padding: 10px 15px;
    border: none;
    border-radius: 99px;
    background: #E4E4EB;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    color: #B6B8C3;
    text-align: center;
    font-family: Aeonik;;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-control.dis:hover {
    cursor: not-allowed;
}

.check-real span {
    color: #707A8A;
    font-family: AeonikRegular;
    font-size: 15px;
}

.terms {
    color: #1E2329 !important;
}

.terms:hover {
    text-decoration: underline;
}

.content-step2 .title {
    color: #0EAD98;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 20px;
    padding-top: 25px;
}

.content-step2 .des {
    padding-top: 5px;
    color: #707A8A;
    text-align: center;
    font-family: 'Aeonik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.content-input {
    width: 50%;
    border-radius: 14px;
    background: #EDF3F4;
    margin: 0 auto;
    border-radius: 6px;
    padding: 20px 30px 20px 30px;
    margin-top: 25px;
}

.amount-stake-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.amount-stake-title .title-left {
    color: #1E2329;
    font-family: 'Aeonik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
}

.amount-stake-title .title-right {
    color: #707A8A;
    font-family: AeonikRegular;
    line-height: normal;
    letter-spacing: 0.13px;
}

.amount-stake-title .title-right span {
    color: #3F666B;
    font-family: AeonikMedium;
    font-size: 16px;
    letter-spacing: 0.16px;
}

.input-amount {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
}

.input-amount .amount-stake {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 10px;
    /* background: transparent;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10) inset; */
    padding-left: 15px;
    color: #000 !important;
    font-size: 16px;
    /* background: #EDF3F4; */
    font-family: AeonikRegular;
    padding-left: 10px !important;
}

.posi {
    gap: 3px;
    display: flex;
    position: absolute;
    /* left: 80%; */
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.btn-max-stake {
    width: 80px;
    height: 36px;
    border: none;
    border-radius: 6px;
    background: #0EAD98;
    border-radius: 99px;
    color: #fff;
    font-weight: 600;
}

.palce-token {
    color: #616772;
    text-align: right;
    font-family: 'Aeonik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
}

.des-amount {
    color: #707A8A;
    font-family: 'Aeonik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 157%;
    padding-top: 10px;
}

.staking-rule {
    text-align: start;
}

.des-amount li {
    text-align: start;
    padding-bottom: 3px;
}

.check-rule {
    text-align: start;
    display: flex;
    gap: 10px;
    align-items: center;
}

.un-fee {
    color: #1E2329;
}

.btn-max-stake:hover {
    cursor: pointer;
}

.content-step3 {
    padding-top: 25px;
}

.content-step3 .title {
    color: #0EAD98;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 20px;
    padding-bottom: 10px;
}

.content-step3 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Aeonik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}
.autho-content .ant-progress-success-bg, .ant-progress-bg {
    border-radius: 1000px;
background: linear-gradient(270deg, #19CA89 0%, #CDDFE1 102.21%);
}
.autho-content {
    margin: 0 auto;
    border-radius: 14px;
    background: #EDF3F4;
    border-radius: 6px;
    width: 70%;
    padding: 30px 20px 30px 20px;
    text-align: center;
}

.content-step4 .title {
    padding-top: 45px;
    color: #0EAD98;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 25px;
    margin-bottom: 10px;
}

.autho-title {
    color: #707A8A;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 18px;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.autho-please {
    color: #707A8A;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    padding-bottom: 10px;
}

.autho-link {
    color: #229ED9;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.autho-progress {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 5px;
}

.autho-link:hover {
    text-decoration: underline;
}

.autho-progress .ant-progress-text {
    display: none;
}

.content-step4 .des {
    color: #707A8A;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.confirm-content {
    padding: 10px 30px;
    background: #fff;
    width: 60%;
    margin: 0 auto;
    border-radius: 6px;
    
}

.cf-content1 {
    color: #707A8A;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
}

.content-step5 .des {
    color: #707A8A;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content-step5 .title {
    padding-top: 25px;
    font-size: 25px;
    color: #0EAD98;
    text-align: center;
    font-family: AeonikMedium;
}

.success-content {
    background: #fff;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 6px;
    text-align: center;
}

.img-success img {
    width: 75px;
    height: 75px;
}

.mid-content1 {
    color: #707A8A;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    line-height: 151.5%;
}

.mid-content2 {
    color: #707A8A;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.mid-content {
    color: #0EAD98;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 20px;
    letter-spacing: 0.2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mid-content3:hover {
    text-decoration: underline;
}

.spin-stake .ant-spin {
    position: relative;

}

.spin-stake .ant-spin::before {
    content: "";
    border: 4px solid #E4E4EB;
    width: 100%;
    height: 100%;
    border-radius: 99px;
    position: absolute;
}

.modal-confirm .ant-modal-header .ant-modal-title {
    color: #1E2329;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal-text1 {
    color: #707A8A;

    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 20px;
}

.spin-stake {
    text-align: center;
}

.modal-confirm .ant-modal-content {
    border-radius: 6px;
}

.modal-confirm .ant-modal-header {
    border-radius: 6px;
}

.modal-confirm .ant-modal-close-x {
    display: none;
}

.modal-confirm .ant-modal-body {
    padding: 10px 0 30px 0;
}

.img-warning {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}

.unstake-warning {
    padding: 60px 0 60px 0;
    border-radius: 14px;
    background: #EDF3F4;
    width: 70%;
    margin: 60px auto 60px auto;
    
}

.content-warning1 {
    padding-top: 20px;
    color: #1E2329;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
}

.content-warning2 {
    padding-top: 10px;
    text-align: center;
    color: #1E2329;
    font-family: AeonikRegular;
    font-size: 16px;
}

.content-input.unstake {
    width: 50%;
    padding-bottom: 50px;
    margin-top: 15px;
}

.posi-unstake {
    gap: 3px;
    display: flex;
    position: absolute;
    /* left: 80%; */
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.title.title-unstake {
    padding-top: 20px;
}


@media (min-width: 1500px) {
    .container-stake {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 0 10px;
    }
}

.ant-input-affix-wrapper input:focus {
    outline: none;
    border: 1px solid rgba(250, 199, 104, 0) !important;
}


.time-down {
    color: #1E2329;
    text-align: center;
    font-family: "Aeonik";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 991px) {
    .big-title {
        font-size: 32px;
    }

    .item-1 {
        width: 100%;
    }

    .item-info.row .item-1:nth-child(1) {
        width: 100%;
        margin-bottom: 10px;
    }
    .item-info.row .item-1:nth-child(2), .item-info .item-1:nth-child(3){
        width: 49% !important;
    }
    .item-info.res .item-1:nth-child(1), .item-info .item-1:nth-child(2) {
        width: 49% !important;
    }
    .item-info.res .item-1:nth-child(3) {
        margin-top: 10px;
        width: 100% !important;
    }

    .item-info {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .container-stake {
        padding: 0 10px;
    }

    .stake {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .wrap-all {
        width: 100%;
        height: 100%;
    }

    .action-pool {
        width: 100%;
        height: 100%;
    }

    .content-stake {
        padding: 15px;
    }

    .item-step1 {
        flex-wrap: wrap;
    }

    .card-item {
        height: 100%;
        width: 100%;
    }

    .action-pool {
        gap: 0;
    }

    .your-stake {
        height: 100%;
        padding: 10px;
        margin-bottom: 15px;
    }

    .your-stake .title {
        margin-bottom: 5px;
    }

    .btn-withdraw-reward {
        font-size: 13px !important;
    }

    .btn-action {
        height: 40px;
        width: 48%;
    }

    .btn-stake-reward {
        font-size: 13px !important;
    }

    .steps-action {
        width: 100%;
    }

    .content-input {
        width: 100%;
        padding: 15px;
    }

    .amount-stake-title {
        display: block;
        justify-content: space-between;
        padding-bottom: 10px;
        text-align: justify;
    }

    .autho-content {
        width: 100%;
        padding: 15px;
    }

    .autho-progress {
        width: 100%;
    }

    .autho-link {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .confirm-content {
        width: 100%;
        padding: 15px;
    }

    .success-content {
        width: 100%;
        padding: 15px;
    }

    .mid-content3 {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .action-button {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        position: relative;
        top: unset;
        right: unset;
        transform: unset;
        margin-top: 15px;
    }

    .unstake-warning {
        width: 100%;
        margin: 10px 0;
        padding: 15px;
    }

    .title.title-unstake {
        padding-top: 0;
    }

    .content-input.unstake {
        width: 100%;
    }

    .tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
        width: 100%;
    }

    .tabs-stake-pool .ant-tabs-tab {
        width: calc(4 / 12 * 100%);
    }

    .main-stake-pool-v2 {
        padding-bottom: 0;
    }
}
.img-success {
    text-align: center;
}