.center-loading {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	position: fixed;
	left: 0;
	top: 0px;
	background: rgb(0 0 0 / 74%);
	width: 100%;
	z-index: 9999;
}


.wait-des {
	color: #c0c0c0;
	font-size: 25px;
	font-weight: bold;
	text-transform: capitalize;
	letter-spacing: 1px;
	line-height: normal;
	animation: textLoading 3s ease-in-out infinite;
	text-shadow: 0 0 5px #fff;
	margin-top: 220px;
}

.spinner-box {
	width: 300px;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	margin-top: -10%;
}

.leo {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}


.blue-orbit {
	width: 165px;
	height: 165px;
	border: 2px solid #91daffa5;
	animation: spin3DNow 3s linear .2s infinite;
}

.green-orbit {
	width: 120px;
	height: 120px;
	border: 2px solid #91ffbfa5;
	animation: spin3DNow 2s linear 0s infinite;
}

.red-orbit {
	width: 90px;
	height: 90px;
	border: 2px solid #ffca91a5;
	animation: spin3DNow 1s linear 0s infinite;
}

.white-orbit {
	width: 60px;
	height: 60px;
	border: 2px solid #ffffff;
	animation: spin3DNow 10s linear 0s infinite;
}

.w1 {
	transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
	transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
	transform: rotate3D(.5, 1, 2, 90deg);
}

@keyframes spin3DNow {
	from {
		transform: rotate3d(0.5, 0.5, 0.5, 360deg);
	}

	to {
		transform: rotate3d(0, 0, 0, 0deg)
	}
}

/* @keyframes spin3D {
	from {
		transform: rotate3d(.5, .5, .5, 360deg);
	}

	to {
		transform: rotate3d(0deg);
	}
} */

@keyframes textLoading {
	70% {
		color: #fff;
	}
}