.project-menu {
    width: 100%;
}
.project-menu .project-menu-list {
    background: transparent !important;
}
.project-menu .project-menu-list .ant-menu-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.project-menu .project-menu-list {
    border-bottom: 1px solid #b4b7ce;
}
.project-menu .project-menu-list .ant-menu-item .ant-menu-title-content a {
    position: relative;
    display: block;
    margin: 0;
    padding: 0px 20px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid transparent !important;
    line-height: 38px;
    color: #9295ab;
    transition: background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.project-menu .project-menu-list .ant-menu-item-selected {
    border-bottom: 2px solid #ee7620;
}

.project-menu .project-menu-list .ant-menu-item:hover .ant-menu-title-content a {
    color: #ee7620 !important;
}
.project-menu .project-menu-list .ant-menu-item-selected .ant-menu-title-content a {
    color: #ee7620 !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    /* border-bottom: 3px solid #5dd5dd !important; */
    border-bottom: none !important;
}
@media screen and (max-width: 768px) {
}
