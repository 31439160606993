.header-desktop .ant-menu{

    .ant-menu-overflow-item{
        padding: 0 20px !important;

        .ant-menu-title-content {
            a {
                color: #3F666B !important;
                font-family: AeonikRegular !important;
                font-size: 16px !important;
            }
        }
    }
}
.header-profile-wallet{
    min-width: 250px;
    min-height: 200px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    padding: 10px;

    .wallet-info{
        background: #fff;
        border: 0.5px solid #aab6cd;
        border-radius: 6px;
        box-shadow: inset 0 0 4px rgba(99, 99, 99, .25);
        padding: 10px;

    }
}
ul.ant-menu-sub{
    padding: 10px 5px !important;
    border-radius: 10px !important;
    width: 350px;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.12);

    .ant-menu-item{
        height: auto;
        padding-bottom: 5px;
        padding-left: 5px !important;
        padding-right: 5px !important;
        

        .ant-menu-title-content{
            height: auto;
            padding-bottom: 5px;
            &:hover {
                    color: rgb(46, 194, 194);
                }
            
            a{
                line-height: normal !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                &:hover {
                    color: rgb(46, 194, 194);
                }
                
            }
            a:hover .item-sub-menu{
                border-radius: 12px !important;
                background: #EDF3F4 !important;
            }
        }
        .ant-menu-title-content:hover{
            color: rgb(46, 194, 194);
        }

        .item-sub-menu {
            display: flex;
            margin-top: 0px;
            padding: 10px;
            border-bottom: 1px solid #f0f0f0;

            .posi-coming-soon {
                color: #f7502f;
                font-size: 13px;
                margin-top: 0px;
                position: absolute;
                font-family: AeonikRegular;
                right: 15px;
                text-shadow: 0 0 10px rgb(178, 77, 77);
            }

            .menu-img {
                border-radius: 10px;
                background: transparent;
                text-align: center;
                align-items: center;
                padding-top: 0px !important;
                height: 45px;
                width: 45px;
                margin-right: 7px;

                img {
                    width: 100%;
                }
            }

            .menu-content {
                padding-top: 0px;

                .menu-title {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    color: #1eb4a0;
                    font-family: AeonikMedium;
                    font-size: 16px;
                    line-height: normal;
                }

                .menu-desc {
                    margin-top: 5px !important;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    color: #0A2C31;
                    font-family: Aeonik;
                    font-size: 14px;
                    line-height: normal;
                }

                .menu-grp-link {
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;


                    a {
                        color: #2fb0ec;
                        font-family: AeonikRegular;
                        position: relative;
                        z-index: 9999;
                        display: block;
                        text-decoration: underline;

                        &:hover {
                            color: #3F666B;
                        }
                    }
                }
            }
        }
        .item-sub-menu:hover {
            border-radius: 12px !important;
            background: #EDF3F4 !important;
        }
    }
    
}

.c-header {
    background-color: #fafafa7d;
    padding: 17px 25px;
    display: block;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    
    .container-header{
        max-width: 1500px;
        margin: auto !important;
    }

    .header-desktop{
        display: block;
        background: transparent;
        border-bottom: none !important;
        // margin: 0 7%;

        .ant-menu-title-content{
            color: #3F666B !important;
            font-family: AeonikRegular !important;
            font-size: 16px !important;
            &:hover{
                color: rgb(46, 194, 194);
            }

            a{
                color: #3F666B;
                font-family: AeonikRegular !important;
                font-size: 16px !important;
                &:hover {
                    color: rgb(46, 194, 194);
                }
            }
        }
    }
    .menu-item-right{
        float: right;
    }
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after{
        border-bottom: none !important;
    }
    .ant-menu-horizontal>.ant-menu-item::after{
        display: none !important;
    }
    .header-mobile {
        display: none;
    }
    .hide-menu {
        display: none;
    }

    .show-mobile {
        display: block;
    }
    // &__btn {
    //     height: 56px;
    //     appearance: none;
    //     background-color: #FFD60A;
    //     border-radius: 10px;
    //     border: 0;
    //     padding: 17px 20px;
    //     margin: 0 25px 0 0;
    //     font-size: 16px;
    //     line-height: 1;
    //     color: #1A1C1E;
    //     text-align: center;
    //     cursor: pointer;
    //     transition: 0.3s ease-in-out;
    //     &:hover {
    //         background-color: #C8A703;
    //     }
    // }
    // &__logo {
    //     width: 50%;
    //     background-image: url("../images/logo-trang.svg");
    //     background-repeat: no-repeat;
    //     margin-top: 5px;
    //     height: 30px;
    //     img{
    //         width: 100%;
    //     }
    // }
    // &__toggle {
    //     display: block;
    //     width: 28px;
    //     height: 22px;
    //     padding: 2px;
    //     cursor: pointer;
    //     position: relative;
    //     overflow: hidden;
    //     img {
    //         width: 100%;
    //         display: block;
    //     }
    //     &:before,
    //     &:after {
    //         content: '';
    //         width: 24px;
    //         height: 2px;
    //         background-color: #C4C4C4;
    //         position: absolute;
    //         top: calc(50% - 1px);
    //         left: 2px;
    //         transition: .3s ease-in-out;
    //     }
    //     &:before {
    //         transform: translateY(-9px);
    //     }
    //     &:after {
    //         transform: translateY(9px);
    //     }
    //     span {
    //         display: block;
    //         width: 24px;
    //         height: 2px;
    //         background-color: #C4C4C4;
    //         position: absolute;
    //         top: calc(50% - 1px);
    //         left: 2px;
    //         transition: .3s ease-in-out;
    //     }
    //     &.is-open {
    //         span {
    //             left: 100%;
    //         }
    //         &:before {
    //             transform: rotate(45deg);
    //         }
    //         &:after {
    //             transform: rotate(-45deg);
    //         }
    //     }
    // }
}

@media screen and (max-width: 768px) {
    

    .mobile-icon-menu {
        color: #fff;
    }

    .header-desktop {
        display: none !important;
    }

    .btnConnect {
        margin-right: 0px !important;
    }

    .btn-menu-mobile {
        background: transparent !important;
        box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.59), inset 0px 0px 22px rgba(149, 170, 209, 0.21);
        border-radius: 50%;
        width: 40px !important;
        height: 40px !important;
        text-align: center;
        border: 0px solid transparent;
        font-size: 18px !important;
        padding-left: 10px;
        border-color: transparent !important;
        color: #292D32 !important;
    }

    .btn-menu-mobile-close {
        background: transparent !important;
            box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.59), inset 0px 0px 22px rgba(149, 170, 209, 0.21);
        border-radius: 50%;
        width: 40px !important;
        height: 40px !important;
        text-align: center;
        border: 0px solid transparent;
        font-size: 18px !important;
        padding-left: 10px;
        float: right;
        color: #292D32 !important;
        border-color: transparent !important;
    }

    .btn-menu-mobile-close svg {
        font-size: 24px !important;
        font-weight: bold;
        margin-top: 6px;
        color: #000 !important;
    }

    /* .item-menu-clo span {
		height: 40px;
	} */

    /* .btn-menu-mobile-close span {
		background: linear-gradient(269.11deg, rgba(99, 148, 209, 0.32) -1.22%, rgba(21, 84, 161, 0.32) 101.48%);
		padding: 20px;
		border-radius: 50%;
	} */

    .header-mobile {
        display: inline-flex !important;
        padding: 15px;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        position: relative;
        z-index: 1001;
        border-bottom: 1px solid #edecec;
    }

    .header-mobile .header-left {
        // width: 30%;
        // float: left;
        // text-align: left;
    }

    .header-mobile .header-center {
        // width: 40%;
        // float: left;
        // text-align: center;
    }

    .header-mobile .header-right {
        // width: 30%;
        // float: left;
        // text-align: right;
    }

    .logo-mobile {
        max-width: 45px;
        margin-top: -5px;
        animation-name: spin;
        animation-duration: 12s, 12s, 12s;
        animation-iteration-count: infinite, infinite, infinite;
        -webkit-animation-timing-function: ease-in-out, linear, linear;
        transform: rotateX(6deg) rotateY(6deg) rotateZ(6deg);
    }

    .menu-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1005;

        .ant-menu-item{
            padding-left: 16px !important;
            border-bottom: 1px solid #CADADD;
            .ant-menu-title-content a{
                color: #3F666B !important;
                font-family: AeonikRegular !important;
            }
            
        }
    }

    .menu-mobile .menu-mobile-ul {
        padding-top: 15px;
    }

    .header-mobile .header-right {
        position: relative;
        z-index: 999;
    }

    .menu-mobile .ant-menu-root {
        height: 100vh;
        background: #FFF !important;
        margin-top: 0px;
    }

    .menu-mobile .ant-menu-dark.ant-menu-inline .ant-menu-item {
        position: relative;
        z-index: 1000;
        padding-left: 15px !important;
    }

    .menu-mobile .ant-menu-dark .ant-menu-item>span>a {
        padding-left: 0;
    }

    .menu-mobile-ul .item-menu-clo {
        background: transparent;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: transparent !important;
        color: #0079ff;
    }

    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
    .ant-menu-dark .ant-menu-item-selected .anticon {
        color: #0079ff;
    }

    .header-mobile {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
    }

    .menu-mobile .ant-menu-submenu .ant-menu-submenu-title{
        border-bottom: 1px solid #CADADD;
        padding-right: 15px !important;
    }

    .menu-mobile .ant-menu-submenu .ant-menu-title-content {
        font-size: 15px;
        color: #ffffffa6 !important;
        margin-left: 5px;
        padding-left: 0;
    }

    .menu-mobile .ant-menu-submenu-title {
        padding-left: 15px !important;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-item .ant-menu-title-content a {
        color: #3F666B !important;
        font-family: AeonikRegular !important;
        font-size: 16px !important;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-item-selected .ant-menu-title-content a {
        color: #0079ff;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-submenu .ant-menu-submenu-title span {
        color: #3F666B !important;
        font-family: AeonikRegular !important;
        font-size: 16px !important;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-submenu ul.ant-menu-sub {
        background: transparent;
        padding-left: 25px;
        width: 100%;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-submenu ul.ant-menu-sub li {

        .posi-coming-soon{
            position: absolute;
            right: 5px;
            color: crimson;
            text-shadow: 0 0 10px #b24d4d;
        }
    }

    .menu-mobile .menu-mobile-ul .ant-menu-submenu-arrow {
        color: #000 !important;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-submenu-arrow::before {
        background: #000 !important;
    }

    .menu-mobile .menu-mobile-ul .ant-menu-submenu-arrow::after {
        background: #000 !important;
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}