.border-left{
    border-left: 1px solid #C2D6D9 !important;
}

.profile-page {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }
    .profile-content{
        margin-top: 50px;

        .profile-avt{
            width: 100%;

            img{
                width: 100%;
                border-radius: 10px;
            }
        }
        .custom-upload-no-bg{
            border: none !important;
            background: transparent;
            width: fit-content;

            .ant-upload-btn{
                padding-top: 5px !important;
                padding-bottom: 0px !important;
                cursor: pointer;
                text-decoration: underline;
                color: #007bff;
                font-family: AeonikRegular;
            }
        }
    }
    
}
