.projects-top {
    margin-top: 15px !important;
    padding: 15px 15px;
    border-radius: 10px;
    width: 100%;
    background: #fff;
}

.filter-block {
    width: 100%;
}

.filter-block .ant-tabs-nav {
    margin-top: 10px !important;
    margin-bottom: 20px;
    padding: 9px 15px;
    border-radius: 10px;
    background: #fff;
}

.filter-block .ant-tabs-tab {
    padding-left: 15px;
    padding-right: 15px;
}

.filter-block .ant-tabs-tab-active {

    border-bottom: 2px solid #ee7620 !important;
}

.filter-block .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ee7620 !important;
    font-size: 14px;
}

.filter-block .ant-tabs-tab+.ant-tabs-tab {
    margin: 0px !important;
}

.w-full {
    width: 300px !important;
}

.w-full-100 {
    width: 100%;
}



.select-chain-full {
    min-width: 250px !important;
}

.projects-grid {
    width: 100%;
    padding: 15px 0;
}

.project-filter {
    width: 100%;
    text-align: right;
    float: right;
}

.project-filter form {
    width: 100%;
    text-align: right;
}

.project-filter form .ant-form-item {
    display: inline-flex;
    margin-left: 10px;
    margin-bottom: 0px !important;
}

.project-item {
    width: 100%;
    background-color: #ffffff !important;
    border-radius: 12px;
    min-height: 440px;
    padding: 7px;
    margin-bottom: 15px;
    border: 0.7px solid #DEEBED;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

    /* background: linear-gradient(to right, #ee7620, #ee7620), linear-gradient(to right, #ee7620, #ee7620),
        linear-gradient(to bottom, #ee7620, #ee7620), linear-gradient(to bottom, #ee7620, #ee7620);
    background-position: 0% 0%, 100% 100%, 0% 0%, 100% 100%;
    background-size: 0% 2px, 0% 2px, 2px 0%, 2px 0%;
    background-repeat: no-repeat;
    transition: background-size 0.5s; */
}

.project-item:hover {
    border: 1px solid #75cffc;
    box-shadow: 0px 0px 2px #8ae7d6, inset 0px 4px 24px #e0efff;
    border-radius: 12px;
}

.project-image {
    width: 100%;
    margin-bottom: 10px;
    max-height: 205px;
    overflow: hidden;
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.project-image img {
    width: 100%;
    border-radius: 10px;
    max-height: 205px;
}

.project-item-content {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 15px;
}

.project-name {
    width: 100%;
    margin-top: 20px;
    margin-top: 15px;
}

.project-name a {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;  
}

.project-name a:hover {
    color: #ee7620 !important;
}

.project-status-social {
    width: 100%;
    margin-top: 15px;
    text-transform: capitalize;
}

.project-status-social span.social {
    float: right;
}

.project-status-social span.social .icon {
    margin-left: 15px;
    color: #9295ab !important;
    font-size: 25px;
}
.text-uppercase{
    text-transform: uppercase;
}
.project-status-social span.social .icon:hover {
    color: #3045e9 !important;
}

.project-total-raise {
    width: 100%;
    margin-top: 15px;
}

.project-total-raise span:nth-child(1) {
    line-height: 20px;
    color: #9295ab;
}

.project-total-raise span:nth-child(2) {
    line-height: 20px;
    color: #121528;
    float: right;
}

.project-item-content hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #eaebf2 !important;
}

.project-registration {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #9295ab;
}

.project-registration span:nth-child(1) {
    font-weight: 500 !important;
}

.text-hidden {
    visibility: hidden;
}

.project-progress-percent .ant-progress-text {
    position: absolute;
    top: -15px;
}

.project-progress-percent .ant-progress-outer {
    padding-right: 0px !important;
}

.project-progress-percent .ant-progress-bg {
    height: 12px !important;
    background: linear-gradient(270deg, #ee7620 0%, #ffd540 101.35%) !important;
    background-color: transparent !important;
}

.project-progress-percent .ant-progress-inner {
    background-color: #e6eef7 !important;
}

.project-total-sell {
    width: 100%;
}

.project-total-sell span {
    color: #9295ab;
    font-size: 11px;
}

.project-total-sell span:nth-child(2) {
    float: right;
}

.project-normal {
    width: 100%;
    margin-top: 10px;
}

.project-normal span:nth-child(1) {
    color: #9295ab;
}

.project-normal span:nth-child(2) {
    margin-left: 5px;
}

.pagiation-custom {
    text-align: center;
    padding: 20px 0;
}

.pagiation-custom li {
    border-radius: 50% !important;
    border: 1px solid #b4b7ce;
    background-color: transparent !important;
}

.pagiation-custom li a {
    color: #9295ab;
}

.pagiation-custom li.ant-pagination-item-active {
    border-color: #1890ff !important;
}

.pagiation-custom li .ant-pagination-item-link {
    border-radius: 50% !important;
    background: transparent !important;
    line-height: 28px;
}

.pagiation-custom li button .anticon {}


.select-chain .ant-select-selector {
    width: 137px !important;
    height: 40px !important;
    background: #ffffff;
    border: 1px solid #b4b7ce !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    text-align: left;
}

.select-chain .ant-select-selector .ant-select-selection-item {
    line-height: 40px;
    color: #9295ab;
}

.input-search {
    width: 150px !important;
    height: 40px !important;
    background: #ffffff;
    border: 1px solid #b4b7ce !important;
    box-sizing: border-box;
    border-radius: 8px !important;
}

.btn-search {
    border: 1px solid #b4b7ce !important;
    height: 40px;
    border-radius: 8px !important;
}

.btn-search .anticon-search {
    color: #fff;
}

.btn-search:hover {
    background: #ee7620;
    border: 1px solid transparent !important;
}

.btn-search:hover .anticon-search {
    color: #fff;
}

.project-chain {
    position: absolute;
    right: 30px;
    display: flex;
    margin-top: -45px;
    align-items: center;
}

.project-token-main {
    height: 25px;
    background: #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 19px;
    font-family: AeonikRegular !important;
    color: #0A2C31 !important;
    padding: 3px 8px;
    margin-top: 4px;
    margin-right: 5px;
    width: fit-content;
    font-weight: 700;
}

.project-token-chain {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    padding: 3px;
}

.project-token-chain img {
    width: 100%;
}

.loading-data {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.filter-block .filter-block-form {}

@media screen and (max-width: 768px) {
    .w-50-mobile {
        width: 50% !important;
    }

    .filter-block .ant-tabs-nav {
        display: block;
    }

    .select-chain .ant-select-selector {
        min-width: auto !important;
    }

    .filter-block .ant-tabs-extra-content {
        margin-top: 15px;
    }

    .project-image img {
        max-height: 100% !important;
    }

    .project-image {
        max-height: 100% !important;
    }

    .project-block {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
    }

    .projects-btn-status {
        margin-bottom: 15px;
    }

    .projects-btn-status button {
        margin-right: 10px !important;
        margin-top: 10px;
    }

    .project-filter,
    .project-filter form {
        text-align: left !important;
    }
}