.c-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: opacity .3s forwards ease-in-out; 
    &__box {
        border-radius: 20px;
        border: 0.7px solid #DEEBED;
        background: #FFF;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
        min-width: 470px;
        padding: 20px;
        @include sp {
            width: 330px;
        }
    }
    &__head {
        // border-bottom: 1px solid #32353E;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 0.025em;
        // text-transform: uppercase;
        color: #0A2C31;
        font-family: AeonikMedium;
        text-align: center;
        // padding: 31px 50px;
        position: relative; 
    }
    &__close {
        appearance: none;
        width: 28px;
        height: 28px;
        padding: 2px;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        position: absolute;
        right: 0px;
        top: calc(50% - 14px);
        img {
            width: 100%;
            display: block;
        }
    }
    &__body {
        // padding: 35px 25px 25px;

        .connect-description{
            color: #0C434B !important;
            text-align: center;
            font-family: Aeonik !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 15px;
            margin-bottom: 20px !important;
        }
        @include sp {
            padding: 0px;
        }
        &.is-staking {
            padding: 25px 45px;
            @include sp {
                padding: 25px 15px 20px;
            }
        }
    }
}

.c-connectWallet {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -5px 0;
    @include sp {
        display: block;
        margin: 0;
    }
    li {
        width: calc(50% - 10px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        font-size: 16px;
        line-height: 22px;
        color: #0A2C31;
        border-radius: 10px;
        background: rgb(242, 246, 252);
        padding: 9px 7px;
        cursor: pointer;
        transition: .3s ease-in-out;
        margin: 10px 5px 0;
        @include sp {
            width: 100%;
            margin: 0 0 10px;
        }
        &:last-child {
            @include sp {
                margin-bottom: 0;
            }
        }
        span {
            width: auto;
            margin-right: 10px;
            display: block;
            img {
                width: 100%;
            }

            &:nth-child(1){
                width: 48px;
            }
            &:nth-child(2) {
                width: 100%;
                max-width: 175px;
                text-align: left;
            }
            &:last-child {
                width: auto;
                margin-right: 0px;
            }
        }
        &:hover {
            background-color: rgb(221, 234, 252);
            // background: rgb(242, 246, 252);
        }
        svg{
            float: right;
        }
    }
}
.row-bottom {
    padding-bottom: 15px;
    border-bottom: 1px solid #C2D6D9;
}
.c-infoWallet {
    margin-top: 20px;
    &__logo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;
        .bg-logo{
            width: 55px;
            height: 55px; 
            margin-right: 10px;
        } 
        @include sp {
            margin-bottom: 20px;
        }
        img {
            display: block;
            width: 100%; 
            padding: 8px;
            background-color: #fff;
            border-radius: 99px;
            height: 100%;
            @include sp {
                width: 50px;
                margin-right: 10px;
            }
        }
        p {
            font-size: 16px;
            line-height: 1;
            color: #74757C;
            margin: 0;
            @include sp {
                font-size: 14px;
            }
            span {
                display: block;
                margin-top: 5px;
                font-weight: bold;
                font-size: 30px;
                line-height: 1;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                color: #FAC800;
                @include sp {
                    font-size: 22px;
                }
            }
        }
    }
    
    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
        span {
            display: block;
            // width: 150px;
            font-size: 16px;
            line-height: 1;
            color: #74757C;
            @include sp {
                width: 120px;
                font-size: 14px;
            }
        }
        p {
            // width: calc(100% - 150px);
            font-size: 18px;
            line-height: 1;
            color: #FDF5D3;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
             img{
                margin-left: 5px;
             }
            @include sp {
                width: calc(100% - 120px);
                font-size: 16px;
            }
            input {
                appearance: none;
                background-color: transparent;
                border: 0;
                padding: 0;
                opacity: 0;
                position: absolute;
                z-index: 0;
                &:focus-visible {
                    outline: none;
                    border: 0;
                }
            }
            button {
                appearance: none;
                background-color: transparent;
                width: 25px;
                height: 26px;
                padding: 1px;
                cursor: pointer;
                border: 0;
                margin: 0 0 0 15px;
                position: relative;
                z-index: 1;
                @include sp {
                    margin-left: 5px;
                }
                img {
                    width: 100%;
                    display: block;
                    transition: .3s ease-in-out;
                }
                &:hover {
                    img {
                        opacity: .8;
                    }
                }
            }
        }
    }
    
    &__rank {
        display: flex;
        background-color: #191B21;
        padding: 20px 10px;
        border-radius: 5px;
        margin: 20px 0 25px;
        @include sp {
            margin: 15px 0 20px;
            flex-wrap: wrap;
            padding: 15px 0 0;
        }
        li {
            width: 20%;
            position: relative;
            @include sp {
                width: calc(100%/3);
                margin-bottom: 15px;
            }
            &:before,
            &:after {
                content: '';
                width: 50%;
                height: 5px;
                background-color: #343437;
                position: absolute;
                top: 18px;
                z-index: 1;
                @include sp {
                    height: 3px;
                    top: 14px;
                }
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
            &:first-child {
                &:before {
                    display: none;
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            &.is-active {
                img {
                    filter: brightness(1);
                }
            }
            img {
                display: block;
                width: 40px;
                height: 40px;
                margin: 0 auto;
                position: relative;
                z-index: 2;
                filter: brightness(.5);
                @include sp {
                    width: 30px;
                    height: 30px;
                }
            }
            p {
                margin: 5px 0 0;
                font-size: 16px;
                line-height: 1;
                color: #FDF5D3;
                text-align: center;
                @include sp {
                    font-size: 14px;
                }
                span {
                    display: block;
                    color: #3F666B;
                    font-family: AeonikRegular;
                    font-size: 16px;
                    margin-top: 15px;
                    margin-top: 7px;
                    @include sp {
                        font-size: 14px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    &__txt {
        font-size: 16px;
        line-height: 1;
        color: #C4C4C4;
        text-align: center;
        margin: 0;
        span {
            display: block;
            margin-top: 10px;
        }
        a {
            font-size: 18px;
            line-height: 1;
            color: #FFD60A;
            text-decoration: underline;
            text-underline-offset: 2px;
            &:hover {
                color: #C8A703;
                text-decoration: underline;
            }
        }
    }
    &__btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        @include sp {
            margin: 30px auto 0;
            width: 190px;
        }
        a {
            margin-right: 30px;
            @include sp {
                margin: 0 0 15px;
            }
        }
        .c-btn {
            @include sp {
                width: 100%;
            }
        }
    }
}

.c-staking {
    p {
        font-size: 16px;
        line-height: 1;
        color: #C4C4C4;
        margin: 0;
        text-align: left;        
        &.is-amount {
            text-align: right;
            @include sp {
                font-size: 14px;
            }
        }
    }
    &__stake {
        background: #32353E;
        border-radius: 5px;
        position: relative;
        margin: 15px 0 10px;
        input {
            appearance: none;
            width: 100%;
            background: #32353E;
            border-radius: 5px;
            font-size: 18px;
            line-height: 18px;
            color: #FDFCFF;
            padding: 18.5px 150px 18px 20px;
            border: 1px solid transparent;
            &:focus-visible {
                outline: none;
                border: 1px solid #FFD60A;
                box-shadow: 4px 4px 4px rgba(255, 214, 10, 0.2);
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        input[type=number] {
            -moz-appearance: textfield;
        }
        span {
            display: block;
            font-size: 18px;
            line-height: 1;
            color: #74757C;
            margin: 0;
            padding: 0;
            position: absolute;
            top: calc(50% - 9px);
            right: 110px;
            @include sp {
                font-size: 16px;
                top: calc(50% - 8px);
            }
        }
        .c-btn {
            width: 85px;
            padding: 13px 25px;
            position: absolute;
            top: calc(50% - 21px);
            right: 10px;
            border-radius: 5px;
        }
    }
    &__btn {
        text-align: center;
        margin-top: 30px;
        .c-btn {
            + .c-btn {
                margin-left: 25px;
            }
        }
    }
    &__fee {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
        p {
            font-size: 16px;
            line-height: 1;
            color: #74757C;
            margin: 0;
        }
    }
    &__ans {
        width: 24px;
        height: 24px;
        position: relative;
        cursor: pointer;
        margin-left: 5px;
        svg {
            width: 100%;
            path {
                transition: .3s ease-in-out;
                &:first-child {
                    stroke: #71717A;
                }
                &:last-child {
                    fill: #71717A;
                }
            }
            &:hover {
                path {
                    &:first-child {
                        stroke: #FFD60A;
                    }
                    &:last-child {
                        fill: #FFD60A;
                    }
                }
                ~ p {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        p {
            width: 333px;
            padding: 5px 10px;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            color: #C4C4C4;
            background: #22252F;
            border: 1px solid #71717A;
            border-radius: 5px;
            position: absolute;
            top: 34px;
            right: 0;
            transition: .3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            @include sp {
                width: 265px;
            }
        }
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }