.c-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        margin-top: 10px;
        position: relative;         
        a {
            height: 52px;
            display: block;
            font-size: 16px;
            line-height: 26px;
            color: #C4C4C4;
            padding: 13px 10px;                
            border-radius: 10px;
            transition: 0.3s ease-in-out;
            position: relative;
            svg {
                width: 26px;
                path {
                    stroke: #71717A;
                    transition: 0.3s ease-in-out;
                }
            }
            span {
                display: block;
                opacity: 1;
                transform: scale(1);
                transform-origin: left;
                transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
                position: absolute;
                left: 51px;
                top: calc(50% - 13px);
            }
            &:hover {
                background-color: #32353E;
            }
        }

        &.is-submenu {
            >a {
                &:after {
                    content: '';
                    width: 16px;
                    height: 8px;
                    background: url(../images/arrow01.png) no-repeat center;
                    background-size: cover;
                    position: absolute;
                    right: 14px;
                    top: 24px;
                    transition: 0.3s ease-in-out;
                }
                &.is-open {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        
        &.is-active {
            &:after {
                content: '';
                width: 5px;
                height: 26px;
                background-color: #FFD60A;
                position: absolute;
                left: -20px;
                top: calc(50% - 13px);
                opacity: 0;
                transition: 0.3s ease-in-out;
            }
            >a {
                background-color: #FFD60A;
                color: #1A1C1E;
                svg {
                    path {
                        stroke: #1A1C1E;
                    }
                }
            }                
        }
    }
    &__sub {
        list-style-type: none;
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        z-index: 1004;
        &.is-open {
            max-height: 208px;
        }
        li {
            padding-left: 41px;
            margin: 0;
            &.is-active {
                a {
                    background-color: transparent;
                    color: #FFD60A;
                }
            }
            a {
                height: 52px;
                display: block;
                font-size: 16px;
                line-height: 26px;
                color: #C4C4C4;
                padding: 13px 10px;
                text-decoration: none;
            }
        }
    }
}

.c-iconNew {
    width: 34px;
    position: absolute;
    top: -8px;
    right: -40px;
    img {
        width: 100%;
        display: block;
    }
}