.p-ino {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .p-ino-title {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 45px;
        letter-spacing: 0.5px;
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #062b31 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include sp {
            font-size: 35px;
        }
    }
}