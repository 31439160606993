.mt-15{
    margin-top: 15px;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.d-flex{
    display: flex;
}
.flex-end{
    justify-content: end;
}
.private-create-page {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .private-create-title {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 35px;
        letter-spacing: 0.5px;
        background: var(--d, linear-gradient(92deg, #3dbcab 9.22%, #0C434B 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .create-form{
        padding-bottom: 50px;

        .create-step{
            padding: 15px;

            .create-step-cus{
                .ant-steps-item{

                    .ant-steps-item-container {
                        .ant-steps-item-icon {
                            background: #edf3f4;
                            border: none !important;
                            margin-bottom: 5px;
                        }
                
                        .ant-steps-item-content {
                            .ant-steps-item-title {
                                font-size: 17px;
                                line-height: 24px;
                                color: #3f666b;
                                font-family: AeonikMedium;
                            }
                
                            .ant-steps-item-description {
                                width: 100%;
                                max-width: 100%;
                                font-size: 16px;
                                line-height: 24px;
                                color: #3f666b;
                                font-family: Aeonik;
                                margin-bottom: 10px;
                                letter-spacing: .32px;
                            }
                        }
                    }
                }
                .ant-steps-item-active {

                    .ant-steps-item-container {
                        .ant-steps-item-icon {
                            background: linear-gradient(275deg, #19B4CD 4.29%, #5EE7D5 97.42%);
                            border: none !important;
                        }
                    }
                }
            }
            .private-create-block{
                padding: 15px;
                max-width: 100%;
                min-height: 260px;
                background: #fff;
                box-shadow: 0 3px 20px 0 rgba(24, 84, 92, .09);
                border-radius: 12px;
                margin: 30px 0;

                .title-steps{
                    margin-bottom: 15px;
                    font-size: 18px;
                    line-height: 24px;
                    color: #3f666b;
                    font-family: AeonikMedium;
                }
            }
        }
    }
}
.filter-block-form{
    margin-bottom: 10px;
}
.private-page{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .private-title {
        margin-top: 20px;
        margin-bottom: 40px;
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
        text-align: center;
        font-family: AeonikBold;
        font-size: 60px;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0.5px;
        @include sp {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 30px;
        }
    }
    .private-content {
        padding-top: 10px;
        padding-bottom: 10px;

        .private-item {
            border-radius: 20px;
            border: 0.7px solid #DEEBED;
            background: #FFF;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
            padding: 15px;
            margin-bottom: 15px;

            .private-image {
                max-height: 170px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    min-height: 170px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            .private-item-content {
                margin-top: 15px;
                // padding: 15px;
                // padding-top: 5px !important;

                .private-name {
                    text-align: left;
                    margin-bottom: 10px;
                    a{
                        color: #0C434B;
                        font-family: AeonikMedium;
                        font-size: 20px;
                        &:hover{
                            color: #1f9aad;
                        }
                    }
                }
                .private-progress-percent{
                    margin-top: 10px;
                    .ant-progress-text {
                        position: absolute;
                        top: -15px;
                    }
                    .ant-progress-outer {
                        padding-right: 0px !important;
                    }
                    .ant-progress-bg {
                        height: 12px !important;
                        background: linear-gradient(270deg, #19CA89 0%, #b1dadf 102.21%);
                        background-color: transparent !important;
                    }
                    .ant-progress-inner {
                        background-color: #e6eef7 !important;
                    }
                }

                .private-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }

                .private-row span:nth-child(1) {
                    color: #3F666B;
                    font-family: Aeonik;
                    font-size: 15px;
                }

                .private-row span:nth-child(2) {
                    font-size: 16px;
                    color: #0A2C31;
                    font-family: AeonikRegular;

                    .ant-tag {
                        margin-right: 0px !important;
                        color: #fff !important;
                        border-radius: 5px !important;
                        font-family: AeonikRegular !important;
                        font-size: 14px !important;
                        line-height: 21px !important;
                    }
                }
                .private-social{
                    margin-top: 0px;
                    margin-bottom: 10px;
                    
                    ul{
                        display: flex;
                        li{
                            margin-right: 5px;
                            img{
                                width: 20px;
                            }
                        }
                    }
                }

                .private-btn {
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;

                    button {
                        width: fit-content !important;
                    }
                }
            }
        }

        .airdrop-item:hover {
            .airdrop-image {

                img {
                    transform: scale(1.1);
                    transition: 0.5s ease-in-out;
                }
            }
        }
    }
}
.private-detail-page{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    min-height: 800px;
    padding-bottom: 50px;

    .private-detail-title {
        color: #0C434B !important;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 25px;
        margin-bottom: 5px;
        margin-top: 0px;
    }

    .private-detail-social {
        display: flex;
        margin-bottom: 10px;

        span {
            font-size: 16px;
            margin-right: 5px;

            image {
                width: 25px;
            }

            ;
        }
    }

    .card-private {
        border-radius: 10px;
        border: 0.7px solid #DEEBED;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

        .ant-card-head-title {
            color: #0C434B !important;
            text-align: left;
            font-family: AeonikMedium;
            font-size: 20px;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }

        .ant-card-extra {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .airdrop-line {
            padding: 10px 0;
            border-bottom: 1px solid rgb(232, 232, 232);
            
            span{
                color: #0C434B !important;
                text-align: left;
                font-size: 15px;
                font-family: Aeonik;
            }

        }

        .airdrop-line:nth-last-child(1) {
            border-bottom: none !important;
        }

        .airdrop-btn-grp {
            margin-bottom: 10px;

            .airdrop-btn {
                margin-bottom: 10px;
                margin-right: 10px;
                width: 100%;
                height: auto;
                border: 1px solid transparent;
                background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
                color: #fff;
                border-radius: 15px;
                font-size: 16px;
                line-height: normal;
                font-family: AeonikMedium;
                text-align: center;
                padding: 10px 20px;
                cursor: pointer;

                &:disabled {
                    opacity: 0.5;
                    background: #0EAD98;
                    color: #fff;
                    text-align: center;
                    font-family: AeonikMedium;
                    font-size: 16px;
                    cursor: not-allowed;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    .detail-time{
        display: flex;
        justify-content: space-between;
        .time-coundown{
            .process-tc{
                display: flex;

                .timer-count{
                    margin-right: 7px;
                    color: #0C434B !important;
                    text-align: left;
                    font-size: 15px;
                    font-family: AeonikRegular;
                }
            }
            
        }
    }
}