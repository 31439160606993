.text-info{
    font-size: 16px !important;
    color: #2289f7 !important;
    font-family: AeonikRegular !important;
}
.text-capita{
    text-transform: capitalize;
}
.text-upper{
    text-transform: uppercase;
}
.airdrop-page{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 70px;
    min-height: 800px;
    @include sp {
        padding-top: 100px;
    }

    .airdrop-title{
        margin-top: 20px;
        margin-bottom: 40px;
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
        text-align: center;
        font-family: AeonikBold;
        font-size: 60px;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0.5px;
        @include sp {
            font-size: 35px;
            margin-bottom: 10px;
        }
    }
    .airdrop-content{
        padding-top: 10px;
        padding-bottom: 10px;

        .airdrop-item {
            border-radius: 20px;
            border: 0.7px solid #DEEBED;
            background: #FFF;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
            padding: 15px;
            margin-bottom: 15px;

            .airdrop-image{
                max-height: 205px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    min-height: 170px;
                    border-radius: 10px;
                    max-height: 205px;
                }
            }
            .airdrop-content{
                margin-top: 15px;
                // padding: 15px;
                // padding-top: 5px !important;

                .airdrop-name{
                    color: #0C434B !important;
                    text-align: left;
                    font-family: AeonikMedium;
                    font-size: 20px;
                    margin-bottom: 10px;
                }
                .airdrop-row{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
                .airdrop-row span:nth-child(1){
                    color: #3F666B;
                    font-family: Aeonik;
                    font-size: 15px;
                }
                .airdrop-row span:nth-child(2) {
                    font-size: 16px;
                    color: #0A2C31;
                    font-family: AeonikRegular;

                    .ant-tag{
                        margin-right: 0px !important;
                        color: #fff !important;
                        border-radius: 5px !important;
                        font-family: AeonikRegular !important;
                        font-size: 14px !important;
                        line-height: 21px !important;
                    }
                }
                .airdrop-btn{
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;

                    button{
                        width: fit-content !important;
                    }
                }
            }
        }
        .airdrop-item:hover{
            .airdrop-image {
            
                img {
                   transform: scale(1.1);
                   transition: 0.5s ease-in-out;
                }
            }
        }
    }
}

.airdrop-create-page {
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 70px;
    min-height: 800px;

    @include sp {
        padding-top: 100px;
    }

    .airdrop-create-title {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 30px;

        letter-spacing: 0.5px;
    }

    
}
.airdrop-detail-page{
    background: url(../images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    min-height: 800px;

    .airdrop-detail-top{
        border-radius: 20px;
        border: 0.7px solid #DEEBED;
        background: #FFF;
        padding: 15px;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    }
    .airdrop-detail-title{
        color: #0C434B !important;
        text-align: left;
        font-family: AeonikMedium;
        font-size: 25px;
        margin-bottom: 5px;
        margin-top: 0px;
    }
    .airdrop-detail-social{
        display: flex;
        margin-bottom: 10px;
         
        span{
            font-size: 16px;
            margin-right: 5px;
            image{
                width: 25px;
            };
        }
    }
    .airdrop-detail-description{
        font-size: 15px;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .airdrop-detail-banner{
        width: 100%;
        border: 1px solid rgb(236, 236, 236);
        max-height: 225px;
        height: 100%;
        overflow: hidden;
        padding: 7px;
        img{
            border-radius: 7px;
            width: auto;
            max-width: 100%;
        }
    }
    .airdrop-detail-block{
        margin-top: 15px;
        margin-bottom: 50px;

        .card-airdrop{
            border-radius: 10px;
            border: 0.7px solid #DEEBED;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

            .ant-card-head-title {
                color: #0C434B !important;
                text-align: left;
                font-family: AeonikMedium;
                font-size: 20px;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
            }
            .ant-card-extra{
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .airdrop-line{
                padding: 10px 0;
                border-bottom: 1px solid rgb(232, 232, 232);

                
            }
            .airdrop-line:nth-last-child(1) {
                border-bottom: none !important;
            }

            .airdrop-btn-grp{
                margin-bottom: 10px;

                .airdrop-btn{
                    margin-bottom: 10px;
                    margin-right: 10px;
                    width: 100%;
                    height: auto;
                    border: 1px solid transparent;
                    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
                    color: #fff;
                    border-radius: 15px;
                    font-size: 16px;
                    line-height: normal;
                    font-family: AeonikMedium;
                    text-align: center;
                    padding: 10px 20px;
                    cursor: pointer;
                    &:disabled {
                        opacity: 0.5;
                        background: #0EAD98;
                        color: #fff;
                        text-align: center;
                        font-family: AeonikMedium;
                        font-size: 16px;
                        cursor: not-allowed;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
        
    }
}