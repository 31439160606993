.project-item-close {
    height: auto;
    min-height: auto !important;
}

.project-block-create {
    background: url(../../../common/asset/images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 800px;
}

.create-form {
    width: 100%;
    min-height: 700px;
    margin: auto;
    margin-bottom: 100px;
    position: relative;
}

.create-title {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    font-family: AeonikMedium;
    font-size: 35px;
    letter-spacing: 0.5px;
    background: var(--d, linear-gradient(92deg, #3dbcab 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.create-step {
    width: 100%;
}

.create-step .steps-content {
    min-height: 200px;
    /* width: 100%; */
    float: left;
}

.create-step .steps-action {
    margin-top: 24px;
    width: 100%;
    text-align: right;
}

.width-auto {
    width: auto !important;
    max-width: 515px;
}

.btn-create-token {
    width: 120px;
    height: 40px;
    border: 1px solid #b4b7ce;
    border-radius: 8px;
    background: #fff;
    float: right;
    font-size: 14px;
    color: #121528;
}

.btn-create-token:hover {
    background: linear-gradient(90deg, #0079ff 2%, #3255d1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    border: 1px solid #3255d1;
    cursor: pointer;
}

.btn-create-token:disabled {
    background: #b4b7ce !important;
}

.btn-create-token:disabled:hover {
    cursor: no-drop;
    color: #fff !important;
}

.create-step-cus .ant-steps-item-description {
    max-width: 250px !important;
    width: auto !important;
    color: #595b6b !important;
    font-size: 15px;
}

.create-step-cus .ant-steps-item-title {
    color: #7d8097;
    font-family: AeonikMedium;
}

.create-step-cus .ant-steps-item-active .ant-steps-item-title {
    color: #1f9aad !important;
    font-family: AeonikMedium;
}

.create-step-cus .ant-steps-item-active .ant-steps-item-icon {
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
    border: transparent !important;
}

.create-step-cus {
    background: linear-gradient(269.81deg, #48b6ff -0.56%, #7ebfec 12.94%, #b4d8f1 37.71%, #dbeaf7 99.44%);
    box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.59), inset 0px 0px 22px rgba(149, 170, 209, 0.21);
    padding: 15px;
    height: auto;
    margin: auto;
}

.l-30 {
    margin-left: 30px;
}

.create-step-block {
    padding: 30px;
    max-width: 100%;
    min-height: 260px;
    background: #ffffff;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    border: 1px solid rgb(232, 232, 232);
    border-radius: 15px;
    margin: 30px 0;
    display: grid;
}

.title-steps {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #121528;
}

.title-steps .link-normal {
    float: right;
    border: 1px solid #b4b7ce;
    box-sizing: border-box;
    border-radius: 8px;
    color: #121528;
    padding: 3px 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    background: #fff;
}

.title-steps .link-normal:hover {
    border: 1px solid #ee7620;
    color: #ee7620 !important;
    cursor: pointer;
}

.sub-title-steps .link-normal {
    border: 1px solid #b4b7ce;
    box-sizing: border-box;
    border-radius: 8px;
    color: #121528;
    padding: 3px 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.sub-title-steps .link-normal:hover {
    border: 1px solid #ee7620;
    color: #ee7620 !important;
    cursor: pointer;
}

.sub-title-steps .link-normal:disabled {
    opacity: 0.5;
}

.inputs-steps {
    width: 100%;
    margin-top: 20px;
}

.create-token-modal {
    max-width: 600px;
    width: 100% !important;
}

.modal-btn-group button:nth-child(1) {
    margin-right: 15px;
}

.click-approve {
    width: 100%;
    margin-top: 10px;
}

.click-approve .btn-approve {
    width: 145px;
    height: 40px;
    background: linear-gradient(90deg, #075bb0 2%, #26458e 100%);
    border-radius: 8px;
    color: #fff;
}

.launchpad-info-form {
    margin-top: 15px;
    margin-bottom: 10px;
    display: grid;
}

.launchpad-info-form .ant-form-item-with-help {
    margin-bottom: 15px;
    transition: none;
}

.select-create-ido .ant-select-selector {
    background: #ffffff !important;
    border: 1px solid #eaebf2 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    line-height: 40px !important;
    height: 40px !important;
}

.select-create-ido .ant-select-selection-item {
    line-height: 40px !important;
}

.radio-gr-create {
    margin-top: 8px;
    margin-bottom: 10px;
}

.txt-need-amount-create {
    margin-bottom: 15px;
    letter-spacing: 1;
}

.sub-title-steps {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #121528;
    margin-bottom: 15px;
}

.finish-content {
    margin-bottom: 15px;
}

.finish-content p:nth-child(1) {
    font-family: Aeonik !important;
    font-size: 14px;
    line-height: 16px;
    color: #0A2C31 !important;
    margin-bottom: 5px;
}
.finish-content p:nth-child(2) {
    font-family: AeonikRegular !important;
    font-size: 18px;
    line-height: normal;
    color: #3F666B !important;
    margin-bottom: 5px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.hr-cus-cre {
    border: 1px solid #eaebf2;
    margin-bottom: 15px;
}

.choose-network {
    width: 100%;
    margin-bottom: 15px;
}

.choose-network button {
    margin-right: 10px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    background: #eaebf2;
    border-radius: 8px;
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #121528;
    margin-bottom: 10px;
}

.choose-network button:hover {
    cursor: pointer;
    background: transparent;
    border: 1px solid #3255d1;
    color: #121528;
    background: #eaebf2;
}
.choose-network button:disabled {
    cursor: no-drop;
    opacity: 0.5;
}

.choose-network button img {
    max-height: 32px;
    margin-right: 5px;
    border-radius: 50%;
}

.choose-network button.active {
    margin-right: 10px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
    color: #121528;
    border-radius: 8px;
    border: 1px solid #3255d1;
}

.loading-info {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.blue-text {
    color: #3298dc !important;
}

.bg-token-info {
    width: 100%;
    max-width: 364px;
    min-height: 108px;
    background: url(../../../../public/images/bg-token-info.png) no-repeat !important;
    background-size: 100% 100% !important;
    margin-top: 15px;
    float: left;
    padding-left: 15px;
    margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
    .create-step-block{
        padding: 15px;
    }
    .project-block-create {
        background: #e6eef7;
        margin-top: 100px;
        min-height: 500px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .create-title {
        padding-left: 0px;
        padding-right: 0px;
    }
    .l-30{
        margin-left: 0px;
    }
}