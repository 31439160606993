.main-nft-section {
    background: url(../common/asset/images/bg-ido.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 60px 0;
    margin-top: 80px;
}
.nft-banner{
    background: url(../common/asset/images/nft-banner.png);
    background-position: top;
    background-size: 100%;  
    background-repeat: no-repeat;
}
.box-banner .title {
    background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: AeonikBold;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.box-banner .desc {
    color: #0C434B;
    font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
    width: 70%;
}

.btn-mint {
    border-radius: 1000px;
    border: 1.5px solid #3BD5C1;
    color: #3BD5C1;

    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #FFF;
    padding: 13px 30px;
    cursor: pointer;
}

.gr-button .btn-mint {
    margin-right: 10px;
}

.box-banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.box-banner .left {
    width: 65%;
    padding-right: 50px;
}

.box-banner .right {
    width: 35%;
}

.group-number-info .content-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 70px 0;
    background: #fff;
}

.group-number-info .content-info .item {
    width: 25%;
}

.group-number-info .content-info .item .number {
    background: var(--asaasd, linear-gradient(135deg, #19CA89 0%, #63E9DA 50%, #09A9CB 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: AeonikBold;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
}

.group-number-info .content-info .item .text {
    color: #3F666B;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.5%;
    /* 24.03px */
}

.images-info .item {
    padding: 20px;
}

.images-info {
    background: #e4f4ff;
}

.main-explore-nft {
    margin-top: 70px;
}

.main-explore-nft .group-row .title {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.main-explore-nft .group-row .title span {
    color: #3BD5C1;

    font-family: AeonikBold;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.main-tabs-nft {
    margin-top: 30px;
}

.main-tabs-nft .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0EAD98;

    font-family: AeonikBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-shadow: 0 0 0.25px currentcolor;
}

.main-tabs-nft .ant-tabs-tab .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.60);
    font-family: AeonikRegular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-shadow: 0 0 0.25px currentcolor;
}

.main-tabs-nft .ant-tabs-ink-bar {
    position: absolute;
    background: #0EAD98;
    pointer-events: none;
}

.main-tabs-nft .ant-tabs-tab {
    padding: 12px 20px;
}

.main-tabs-nft .colum.w-3 {
    width: 25%;
    padding: 20px;
}

.main-tabs-nft .columns {
    margin: 0 -20px;
}

.guide-item .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.guide-item .title-row .left {
    color: #3F666B;

    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.guide-item .title-row .right {
    color: #3F666B;

    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.guide-item .name-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.guide-item .name-row .left {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.guide-item .name-row .right {
    color: #1E2329;

    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.guide-item {
    padding: 10px 10px 20px 10px;
    border-radius: 24px;
    border: 2px solid #DEEBED;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.12);
    transition: 0.3s ease;
    cursor: pointer;
}

.guide-item:hover {
    border: 2px solid #0EAD98;
}

.mint-after-box .box-left {
    width: 50%;
}
.mint-after-box .box-left img {
    border-radius: 24px;
    width: 100%;
}
.mint-after-box .box-right {
    width: 50%;
    padding-left: 30px;
}

.mint-after-box {
    display: flex;
    flex-wrap: wrap;
}

.mint-after-box .box-right .row-info-after-mint .title {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    margin-bottom: 20px;
}

.mint-after-box .box-right .row-info-after-mint .desc {
    color: #707A8A;

    font-family: Aeonik;
    font-size: 15px;
    margin-bottom: 30px;
}

.process-mint {
    position: relative;
}

.process-mint .ant-progress-text {
    position: absolute;
    right: 0;
    top: -15px;
}

.process-mint .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
}

.process-mint .number-mint {
    text-align: right;
}

.mint-start {
    border-radius: 24px;
    border: 1px solid #E9EFF5;
    margin-top: 20px;
    background: #FFF;
    padding: 20px;
}

.mint-start .start-now {
    background: var(--color-brand, linear-gradient(275deg, #19B4CD 4.29%, #5EE7D5 97.42%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: AeonikBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 15px;
}

.mint-start .start-in {
    color: #707A8A;

    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    padding-bottom: 15px;
}

.mint-start .start-time {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.schedule-box {
    border-radius: 24px;
    border: 1px solid #E9EFF5;
    margin-top: 20px;
    background: #FFF;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.mint-schedule .mint-public {
    color: #1E2329;
    font-family: AeonikBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 15px;
}

.mint-schedule .time-public {
    color: #707A8A;

    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    padding-bottom: 15px;
}

.mint-schedule .balace-public {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.mint-schedule .title-schedule {
    color: #1E2329;
    margin-bottom: 15px;
    font-family: AeonikBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-top: 40px;
}

.schedule-box .eligible {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #707A8A;

    text-align: center;
    font-family: Aeonik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
    padding: 5px 10px;
    border-radius: 27px;
    background: rgba(112, 122, 138, 0.10);
}

.schedule-box .eligible.active {
    border-radius: 27px;
    background: rgba(32, 223, 168, 0.10);
    color: #20DFA8;
}

.padd-15 {
    padding-top: 15px;
}

.mint-release {
    border-radius: 24px;
    border: 1px solid #E9EFF5;
    margin-top: 20px;
    background: #FFF;
    padding: 20px;
}

.mint-release .mint-balance {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.mint-release .mint-balance img {
    margin-right: 5px;
}

.mint-release .mint-balance span {
    color: #707A8A;

    font-family: Aeonik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
}

.mint-button .left {
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 1000px;
    background: #EDF3F4;

    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10) inset;
    padding: 5px;
}

.mint-button {
    margin-top: 15px;
}

.mint-button .left .plus {
    text-align: center;
    color: #000;
    padding: 3px 15px;
    border-radius: 1000px;
    background: #fff;
    font-size: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-family: AeonikBold;
}

.mint-button .left .value {
    color: #1E2329;
    text-align: center;
    font-family: AeonikBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 20px;
}

.mint-button .right {
    width: 60%;
}

.mint-button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.btn-mint-row {
    margin-left: 10px;
    border-radius: 1000px;
    border: 1.5px solid #3BD5C1;
    color: #fff;
    width: 100%;
    text-align: center;
    font-family: AeonikBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 1000px;
    background: #3BD5C1;
    padding: 13px 30px;
    cursor: pointer;
}
.btn-mint-row:disabled{
    opacity: 0.5;
}
.btn-mint-row.dis {
    background: gray;
    border: 1.5px solid gray;
    cursor: no-drop;
}

.go-back {
    color: #1E2329;

    font-family: Aeonik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.9px;
    margin-bottom: 15px;
}

.go-back img {
    margin-right: 5px;
}

.go-back a {
    color: #1E2329;
}

.modal-content-mint .title {
    color: #1E2329;

    text-align: center;
    font-family: AeonikBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}

.modal-content-mint .desc {
    color: #616772;

    text-align: center;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
    margin-bottom: 15px;
}

.modal-content-mint .img-modal {
    text-align: center;
    margin-bottom: 15px;
}

.modal-mint .ant-modal-footer {
    display: none !important;
}
.modal-content-mint .modal-make-offer{
    margin-top: 15px;
    margin-bottom: 0px;
}
.modal-content-mint .modal-make-offer .make-offer-head{
    display: flex;
    justify-content: space-between;
}
.modal-content-mint .modal-make-offer .make-offer-input{
    border-radius: 5px;
    background: #eff3f4;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.auction-time{
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.auction-time .auction-time-label{
    color: #616772;
    font-family: Aeonik;
    margin-right: 10px;
}
.auction-time .auction-time-count span{
    color: #616772;
    font-size: 18px;
    font-family: AeonikRegular;
}
.list-modal {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.list-modal li {
    width: calc(4 / 12 * 100%);
    padding: 10px;
}

.list-modal li .content {
    border-radius: 14px;
    border: 1px solid #E9EFF5;
    width: 100%;
    background: #EDF3F4;
    padding: 10px;
}

.list-modal li .content .att-1 {
    display: block;
    color: #7A8195;

    text-align: center;
    font-family: AeonikBold;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    padding-bottom: 10px;
}

.list-modal li .content .att-2 {
    display: block;
    color: #2b9f8f !important;
    font-family: AeonikRegular;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    padding-bottom: 10px;
}

.list-modal li .content .att-3 {
    display: block;
    color: #7A8195;

    text-align: center;
    font-family: Aeonik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}

.modal-mint .name-modal {
    color: #1E2329;

    text-align: center;
    font-family: AeonikBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
}

.breadcrum ul {
    display: flex;
    align-items: center;
}

.breadcrum li {
    color: #1E2329;
    padding: 0 5px;
    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.breadcrum li.active {
    font-family: AeonikBold;
}

.bid-details .row-user {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.bid-details .row-user span {
    color: #3BD5C1;

    font-family: AeonikBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    padding: 0 5px;

}

.bid-details .bid-name {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.bid-details .bid-owner-address {
    color: #707A8A;
    font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
}

.bid-details .bid-owner-address span {
    border-radius: 1000px;
    background: #F3F4F6;
    padding: 7px 14px;
    margin-left: 5px;
    font-family: AeonikRegular;
    font-size: 16px !important;
}

.bid-details .bid-owner-address span img {
    margin-left: 5px;
}

.btn-mint-row.rs {
    margin-left: 0;
}

.group-detail-bid {
    margin-top: 20px;
    border-radius: 24px;
    border: 1px solid #E9EFF5;
    background: #FFF;
    padding: 13px 20px;
}

.group-detail-bid .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.group-detail-bid .title .left {
    color: #1E2329;

    font-family: AeonikBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.group-detail-bid .title .right {
    color: #1E2329;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}
.group-detail-bid .title .right img {
    margin-left: 5px;
}
.group-detail-bid .title .right.active {
    color: #0EAD98;
    font-family: AeonikBold;
}
.box-content {
    border-radius: 10px;
background: #EDF3F4;
padding: 15px;
margin-top: 15px;
}
.box-content ul li {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding-bottom: 15px;
}
.box-content ul li:last-child {
    /* padding-bottom: 0; */
}
.box-content ul li .txt-left {
    color: #1E2329;

    font-family: AeonikRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.box-content ul li .txt-right {
    color: #3F666B;

    text-align: right;
    font-family: AeonikMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.box-content.dis-none {
    display: none;
}
.box-content.res {
    background: transparent;
    padding: 0;
}
.table-mint tr td {
    padding: 10px;
    font-size: 16px;
    background: #fff;
    color: #3F666B;
    font-family: AeonikRegular;
}
.table-mint tr td span{
    color: #3F666B;
    font-family: AeonikMedium;
}
.table-mint tr th {
    padding: 10px;
    text-align: left;
    font-family: AeonikBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.table-mint {
    width: 100%;
}
.group-detail-bid .w-3 {
    width: 25%;
    padding: 10px;
}
.group-detail-bid .columns {
    margin: 0 -10px;
}
.guide-item .box-img img {
    border-radius: 24px;
    display: block;
}
.no-data {
    margin-top: 50px;
}
.d-desktop{
    display: block;
}
.d-mobile{
    display: none;
}
@media screen and (max-width: 768px) {
    .d-desktop{
        display: none;
    }
    .d-mobile{
        display: block;
    }
    .box-banner .left{
        width: 100%;
        padding-right: 0px;
        position: relative;
        z-index: 9;
    }
    .box-banner .title{
        font-size: 35px;
    }
    .group-number-info .content-info .item{
        width: 50%;
        margin-bottom: 15px;
        border-bottom: 1px solid rgb(246, 244, 244);
        padding-bottom: 10px;
    }
    .group-number-info .content-info .item .number{
        font-size: 30px;
    }
    .main-nft-section{
        padding-top: 20px !important;
    }
    .box-banner .right{
        width: 90%;
        opacity: 0.15;
        position: absolute;
        margin-top: 350px;
    }
    .box-banner .right .box-img img{
        width: 100%;
    }
    .mint-after-box .box-left{
        width: 100%;
    }
    .mint-after-box .box-right{
        width: 100%;
        padding-left: 0px;
        margin-top: 15px;
    }
    .bid-details .row-user{
        margin-bottom: 10px;
    }
    .list-modal li {
        width: calc(6 / 12 * 100%);
        padding: 10px;
    }
    .group-detail-bid {
        padding: 13px 15px;
    }
    .process-mint .ant-progress-text{
        right: 15px;
    }
    .mint-release{
        padding: 15px;
    }
    .btn-mint-row{
        padding: 10px 15px !important;
        width: 150px !important;
    }
    .mint-button .right{
        width: unset;
    }
}