.round-tabs-volum {
    margin-top: -75px;
}
.round-tabs-volum .ant-tabs-content {
    margin-top: 40px;
}
.round-tabs-volum.res .ant-tabs-nav-list {
    width: 400px;
}
.round-tabs-volum .ant-tabs-nav-list {
    width: 500px;
}
.main-create-bot {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    background: #13161b;
    padding: 35px;
    border-radius: 10px;
    color: #fff;
}
.main-create-bot .title {
    color: #fff;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-bottom: 25px;
}
.main-create-bot .item-form span {
    color: var(--body-text, #8e97a8);
    text-align: right;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.14px;
}
.main-create-bot .item-form .ant-input-number {
    width: 100%;
}
.main-create-bot .item-form .ant-input-number input {
    border-radius: 6px;
    border: 0.75px solid #333946;
    background: #222531;
    height: 40px;
    color: #8e97a8;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}
.main-create-bot .item-form .txt img {
    width: 15px;
    margin-right: auto;
    margin-left: 5px;
}
.main-create-bot .item-form .input-round .row-line {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.main-create-bot .item-form .input-round .row-line .text-line {
    width: 50%;
    border-radius: 10px 0px 0px 10px;
    background: #2b2e3e;
    padding: 16px 10px;
    color: var(--body-text, #8e97a8);
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}
.main-create-bot .item-form .input-round .row-line .res-form {
    width: 50%;
    height: 50px;
}
.main-create-bot .item-form .input-round .row-line .res-form input {
    height: 50px !important;
    border-radius: 0 6px 6px 0;
    border: 0px solid #333946 !important;
    margin-left: -1px;
    height: 50px;
}
.main-tool-bot .row-colum {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background: #13161b;
    padding: 25px;
    border-radius: 10px;
    color: #fff;
    height: 100%;
}
.main-tool-bot .row-colum .title {
    color: #fff;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-tool-bot .row-colum .title .right-button {
    display: flex;
    align-items: center;
}
.main-tool-bot .row-colum .title .right-button .btn-log {
    color: var(--color-main-2, #6188ff);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
   /* 114.286% */
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: rgba(97, 136, 255, 0.1);
    padding: 10px 15px;
    cursor: pointer;
}
.main-tool-bot .row-colum .title .right-button .btn-log img {
    width: 20px;
    margin-right: 5px;
}
.main-tool-bot .row-colum .title .right-button .btn-setting {
    border-radius: 6px;
    background: #282d35;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
}
.main-tool-bot .row-colum .title .right-button .btn-setting img {
    width: 20px;
    display: block;
}
.main-tool-bot .row-colum .desc {
    color: #bbc3d3;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 15px 0 10px 0;
}
.list-tool-bot {
    padding-left: 0;
}
.list-tool-bot li {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
}
.list-tool-bot li .txt-left {
    width: 40%;
    color: var(--body-text, #8e97a8);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;
}
.list-tool-bot li .txt-left img {
    width: 20px;
    display: block;
    margin-right: 5px;
}
.list-tool-bot li .txt-right {
    width: 60%;
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
}
.list-tool-bot li .txt-right img {
    width: 20px;
    display: block;
    margin-right: 5px;
}
.row-button-bot {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.row-button-bot .btn-add {
    width: 49%;
}
.modal-transaction {
    width: 1150px !important;
}
.modal-transaction .ant-modal-content {
    border-radius: 10px;
    border: 1px solid #333946;
    background: #13161b;
}
.modal-transaction .ant-modal-content .ant-modal-title {
    background: #13161b;
    color: #fff;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}
.modal-transaction svg {
    fill: #fff;
}
.modal-transaction .ant-modal-footer {
    display: none;
}
.export-transaction .group-upload {
    position: absolute;
    left: 24px;
    top: -5px;
}
.type-sale {
    color: var(--color-sell, #f56565) !important;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.2px;
}
.type-buy {
    color: var(--color-buy, #48bb78) !important;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.2px;
}
.main-report .row-top {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background: #13161b;
    padding: 25px;
    border-radius: 10px;
    color: #fff;
    height: 100%;
}
.main-report .row-top .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-report .row-top .title .left {
    display: flex;
    align-items: center;
}
.main-report .row-top .title .left .txt {
    color: #fff;
    text-align: center;
    font-family:AeonikRegular !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-right: 10px;
}
.main-report .row-top .title .left .ant-select-selection-item {
    color: #fff;
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
.main-report .row-top .title .right .group-upload .btn-up {
    width: 200px;
    justify-content: center;
}
.main-report .row-bottom {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background: #13161b;
    padding: 25px;
    border-radius: 10px;
    color: #fff;
    height: 100%;
    margin-top: 20px;
}
.main-report .row-bottom .header-bottom {
    justify-content: space-between;
    align-items: center;
}
.main-report .row-bottom .header-bottom .left .group-upload {
    margin-top: 0;
}
.main-report .row-bottom .header-bottom .left .group-upload .btn-up {
    width: 200px;
    justify-content: center;
}
.main-report .row-bottom .header-bottom .center {
    color: #fff;
    text-align: center;
    font-family:AeonikRegular !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}
.main-report .row-bottom .header-bottom .right .form-control input {
    border-radius: 6px;
    border: 0.75px solid #333946;
    background: #222531;
    height: 40px;
    color: #8e97a8;
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.main-report .row-bottom .header-bottom .right .form-control .ant-btn {
    display: none;
}
.list-report {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.list-report li {
    list-style-type: none;
}
.list-report li .top {
    color: var(--body-text, #8e97a8);
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.2px;
   /* 165.714% */
    margin-bottom: 5px;
}
.list-report li .bot {
    color: #fff;
    font-family:AeonikRegular !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.2px;
}
.list-report li .bot span {
    color: #fff;
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 23.2px;
}
.section-add-liquidity .group-add-liquidity {
    padding: 20px 0;
}
.section-add-liquidity .group-add-liquidity .group-row {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    background: #fff;
    padding: 25px;
    color: #000;
    margin-top: 35px;
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
    border: 1px solid #32c6d0;
    
}
.section-add-liquidity .group-add-liquidity .group-row .title {
    color: #32c6d0;
    text-align: center;
    font-family:AeonikRegular !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-bottom: 20px;
}
.section-add-liquidity .group-add-liquidity .group-row .sc-name {
    margin-left: auto;
    color: #32c6d0;
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.section-add-liquidity .group-add-liquidity .group-row .sc-name .name {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-smart-contract {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: #EDF3F4;
    padding: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-smart-contract .img img {
    width: 20px;
    display: block;
}
.section-add-liquidity .group-add-liquidity .group-row .row-smart-contract .icon {
    cursor: pointer;
    margin-left: 5px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-smart-contract .icon img {
    width: 20px;
    display: block;
}
.section-add-liquidity .group-add-liquidity .group-row .row-smart-contract .txt {
    color: var(--body-text, #8e97a8);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    margin-left: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-smart-contract .sc-contract {
    color: #3F666B;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.section-add-liquidity .group-add-liquidity .group-row .row-select-pair .title {
    color: #000;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    margin-bottom: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-select-pair .guide-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.section-add-liquidity .group-add-liquidity .group-row .row-select-pair .guide-wrap .left {
    width: 45%;
}
.section-add-liquidity .group-add-liquidity .group-row .row-select-pair .guide-wrap .center {
    width: 10%;
}
.section-add-liquidity .group-add-liquidity .group-row .row-select-pair .guide-wrap .center img {
    width: 40px;
    display: block;
    margin: 0 auto;
}
.section-add-liquidity .group-add-liquidity .group-row .row-select-pair .guide-wrap .right {
    width: 45%;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .title {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    margin-bottom: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit {
    border-radius: 6px;
    background: #edf3f4;
    padding: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .top .name-token {
    color: #0a2c31;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .top .name-token img {
    width: 20px;
    display: block;
    margin-right: 5px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .top .balance-token {
    color: #8e97a8;
    text-align: right;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .top .balance-token span {
    color: #0a2c31;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .bottom .balance-input {
    color: #0a2c31;
    font-family: AeonikRegular !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
   /* 66.667% */
    letter-spacing: 0.24px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .bottom .balance-input .ant-input-number-handler-wrap {
    display: none;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .bottom .balance-input .ant-input-number {
    width: 100%;
    padding-left: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .bottom .balance-input .ant-input-number input:focus {
    border: 0px solid rgba(97, 136, 255, 0.8) !important;
}
.section-add-liquidity .group-add-liquidity .group-row .row-deposit-amount .box-amount-deposit .bottom .balance-input .ant-input-number input {
    color: #0a2c31;
    padding-left: 0;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address {
    border-radius: 10px;
    border: 1px solid #282c34;
    background: #1c1f25;
    padding: 20px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .pair-name {
    color: var(--body-text, #8e97a8);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .pair-name span {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .pair-name span .name {
    margin-left: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .pair-name span img {
    width: 20px;
    margin-right: 2px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair {
    padding-left: 0;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li {
    list-style-type: none;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
    margin-bottom: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li:last-child {
    margin-bottom: 0;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li .to-amount {
    color: var(--color-main-2, #6188ff);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    padding: 0 5px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li .form-amount {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li img {
    width: 20px;
    margin-right: 10px;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li .text {
    color: var(--body-text, #8e97a8);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li .text .left {
    width: 30%;
}
.section-add-liquidity .group-add-liquidity .group-row .group-pair-address .list-pair li .text .right {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
   /* 171.429% */
    letter-spacing: 0.2px;
}
.input-balance {
    background: transparent;
    border: 0px solid transparent;
    outline: none;
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    padding-left: 10px;
}
.input-balance:focus {
    outline: none !important;
    border: 0px solid rgba(97, 136, 255, 0.8) !important;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0) !important;
}
.input-balance::placeholder {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
}
.tooltip-name .name {
    color: var(--background-1, #13161b);
    font-family: AeonikRegular !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
   /* 150% */
    margin-bottom: 5px;
}
.tooltip-name .desc {
    color: var(--background-1, #13161b);
    font-family: AeonikRegular !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
   /* 150% */
    margin-bottom: 10px;
}
.ant-tooltip {
    color: var(--background-1, #13161b);
    font-family: AeonikRegular !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
   /* 150% */
    margin-bottom: 10px;
    background: #fff;
    border-radius: 6px;
}
.ant-tooltip .ant-tooltip-inner {
    background: #fff;
    border-radius: 6px;
}
.round-radio {
    margin-top: 20px;
}
.round-radio .txt {
    color: #000;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10px;
}
.round-radio .columns {
    width: 80%;
}
.round-radio .guide-radio {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}
.round-radio .guide-radio .ant-radio-wrapper {
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--stoke-main, #333946);
    padding: 10px 10px 6px 10px;
    position: relative;
    margin-bottom: 15px;
}
.round-radio .guide-radio .ant-radio-wrapper:last-child {
    margin-bottom: 0;
}
.round-radio .guide-radio .ant-radio-wrapper.ant-radio-wrapper-checked {
    border: 1px solid #5182fe;
}
.round-radio .guide-radio .ant-radio-wrapper .item {
    display: flex;
    align-items: center;
}
.round-radio .guide-radio .ant-radio-wrapper .item .box-img img {
    width: 48px;
}
.round-radio .guide-radio .ant-radio-wrapper .item .text {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
   /* 137.5% */
    letter-spacing: 1px;
}
.round-radio .guide-radio .ant-radio-wrapper .item .text span {
    display: block;
    color: #bbc3d3;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}
.round-radio .guide-radio .ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.round-radio .guide-radio .ant-radio-wrapper .ant-radio .ant-radio-inner {
    background: #333946;
    border-color: #6c6e72;
}
.round-warning-add .txt {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10px;
}
.round-warning-add .box-warning {
    border-radius: 10px;
    background: rgba(240, 173, 2, 0.1);
    padding: 25px;
}
.round-warning-add .box-warning .desc {
    color: var(--pending, #ffb444);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
}
.round-warning-add .box-warning .icon {
    margin-bottom: 5px;
}
.round-warning-add .box-warning .icon img {
    width: 25px;
}
.round-current-price .txt {
    color: #000;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
   /* 118.75% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.round-current-price .txt .icon {
    margin-right: auto;
}
.round-current-price .txt .icon img {
    width: 20px;
    display: block;
    margin-left: 5px;
}
.round-range-price .txt {
    color: #000;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
   /* 118.75% */
    margin-bottom: 10px;
}
.round-range-price .box-range .content {
    border-radius: 10px;
    border: 1px solid var(--stoke-main, #333946);
    background: #13161b;
    text-align: center;
    padding: 20px;
    transition: 0.3s ease;
}
.round-range-price .box-range .content:hover {
    border: 1px solid #6188ff;
    background: #222531;
}
.round-range-price .box-range .content .text {
    color: var(--body-text, #8e97a8);
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    margin-bottom: 10px;
}
.round-range-price .box-range .content .price {
    color: #fff;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 10px;
}
.round-range-price .box-range .content .name {
    color: var(--body-text, #8e97a8);
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}
.round-range-price .group-button {
    text-align: center;
    margin-top: 15px;
}
.round-range-price .group-button .btn-percent {
    border-radius: 6px;
    background: #282c34;
    border: 0px solid transparent;
    width: 120px;
    padding: 10px 0;
    margin: 0 10px;
    color: #8e97a8;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    transition: 0.3s ease;
    cursor: pointer;
}
.round-range-price .group-button .btn-percent:hover {
    border-radius: 6px;
    background: #495162;
    color: #fff;
}
.row-bot:hover {
    cursor: pointer;
}
.main-table {
    overflow-y: auto;
    overflow-x: hidden;
}
.balance-of {
    margin-top: 10px;
    color: var(--body-text, #8e97a8);
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.balance-of span {
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.group-button {
    text-align: center;
    margin-top: 15px;
}
.group-button .btn-percent {
    border-radius: 6px;
    background: #282c34;
    border: 0px solid transparent;
    width: 120px;
    padding: 10px 0;
    margin: 0 10px;
    color: #8e97a8;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    transition: 0.3s ease;
    cursor: pointer;
}
.group-button .btn-percent:hover {
    border-radius: 6px;
    background: #495162;
    color: #fff;
}
.row-bot:hover {
    cursor: pointer;
}
.main-table {
    overflow-y: auto;
    overflow-x: hidden;
}
.inputfile {
    display: none;
}
.file-button {
    border-radius: 6px;
    background: #282c34;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 11px 15px;
    border: 1px solid transparent;
    width: 150px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.copy-img:hover {
    cursor: pointer;
}
.requied {
    color: red !important;
}
.btn-percent-row {
    border-radius: 6px;
    border: 1px solid var(--stoke-main, #333946);
    background: #13161b;
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    padding: 5px 15px;
    margin-right: 10px;
    cursor: pointer;
}
.btn-percent-row span {
    display: block;
    color: #fff;
    font-family: AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}
.btn-percent-row.click-now {
    border-radius: 6px;
    border: 1px solid #4a6efb;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
}
.btn-percent-row.active {
    color: #4a6efb;
}
.group-btn-back.res {
    bottom: 5%;
    left: 5%;
}
.round-disconect {
    color: #8e97a8;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    text-align: center;
    padding: 80px 0;
}
.group-dex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.group-dex .item-master .name {
    color: #000;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.group-dex .item-master .name img {
    width: 24px;
}
.group-dex .item-master:nth-child(1) {
    width: 59%;
}
.group-dex .item-master:nth-child(2) {
    width: 39%;
}
.group-dex .item-master .ant-select {
    width: 100%;
}


.group-title {
    /* padding: 0 0 45px 0; */
    position: relative;
    margin-top: 120px;
}
.group-title .img-title-left {
    position: absolute;
    left: 0;
    top: 0;
}
.group-title .img-title-right {
    position: absolute;
    right: 0;
    top: 0;
}
.group-title .main-title {
    color: #32c6d0;
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    padding-bottom: 40px;
}
.group-title .main-title .txt {
    color: #6188ff;
}
/* .group-title .main-title.res-title {
    margin-bottom: 45px;
} */

.round-tabs .ant-tabs-nav::before {
    border-bottom: 0px solid #f0f0f0;
}
.round-tabs .ant-tabs-nav-list {
    width: 320px;
    border-radius: 40px;
    border: 2px solid var(--stoke-main, #e1eff2);
    padding: 3px;
    background: #EDF3F4;
    margin: 0 auto;
}
.round-tabs .ant-tabs-nav-list .ant-tabs-tab {
    width: 50%;
    justify-content: center;
    color: var(--body-text, #8e97a8);
    text-align: center;
    font-family: AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.2px;
    padding: 8px 0;
}
.round-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 30px;
    background: #32c6d0;
}
.round-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
}
.round-tabs .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
}
.round-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
}
.breacrum {
    display: flex;
    align-items: center;
    justify-content: center;
}
.breacrum .arrow img {
    width: 50px;
}
.breacrum .rs-ar {
    margin-right: 20px;
}
.breacrum .item {
    color: #8e97a8;
    font-family: AeonikRegular !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.2px;
    display: flex;
    align-items: center;
}
.breacrum .item:nth-child(1) {
    margin-right: 20px;
}
.breacrum .item:nth-child(3) {
    margin-left: 20px;
}
.breacrum .item:nth-child(5) {
    margin-left: 20px;
}
.breacrum .item:nth-child(7) {
    margin-left: 20px;
}
.breacrum .item.active {
    color: #32c6d0;
}
.breacrum .item.active .number {
    background: #32c6d0;
}
.breacrum .item .number {
    width: 30px;
    height: 30px;
    border-radius: 99px;
    background: #8e97a8;
    color: #fff;
    display: block;
    text-align: center;
    margin-right: 10px;
    padding: 2.3px;
}
.breacrum .arrow:nth-child(6) {
    margin-left: 20px;
}
.breacrum .arrow:nth-child(4) {
    margin-left: 20px;
}

.select-fiter .ant-select {
    height: 40px;
    line-height: 40px;
    width: 100%;
}
.select-fiter .ant-select .ant-select-selector {
    border-radius: 6px;
    background: #EDF3F4;
    border: 0.75px solid #cacaca;
    height: 40px;
    width: 100%;
}
.select-fiter .ant-select .ant-select-arrow svg {
    fill: #fff;
}
.select-fiter .ant-select .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: #3F666B;
    font-family:AeonikRegular !important;
    font-size: 16px;
    line-height: 16px;
}
.select-fiter .ant-select .ant-select-selection-item .text-chain {
    color: #3F666B;
    font-family:AeonikRegular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.2px;
}
.select-fiter .ant-select .ant-select-selection-item img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 99px !important;
}

.ant-select-dropdown {
    border-radius: 6px;
    background: #ffffff;
}
.ant-select-dropdown .ant-select-item-option-content {
    display: flex;
    padding: 4px 0;
}
.ant-select-dropdown .ant-select-item-option-content img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 99px !important;
}
.ant-select-dropdown .ant-select-item-option-content .text-chain {
    color: #8e97a8;
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.2px;
}
.ant-select-dropdown .ant-select-item-option-active, .ant-select-dropdown .ant-select-item-option-selected {
    border-radius: 6px;
    background: #d7e4e6;
}
.ant-select-dropdown .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #d7e4e6 !important;
}
.ant-select-dropdown .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #d7e4e6 !important;
}

.btn-max {
    color: var(--color-main-2, #32c6d0);
    font-family:AeonikRegular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */ 
    padding: 5px 10px;
    border: 0px solid transparent;
    border-radius: 6px;
    background: rgba(97, 136, 255, 0.10);
    margin-left: 5px;
    cursor: pointer;
}
.mar-b-20 {
    margin-bottom: 20px;
}
.btn-back {
    height: auto;
    appearance: none;
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
    border-radius: 20px;
    border: 0;
    font-family: AeonikRegular !important;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}
.form-control input {
    border-radius: 6px;
    border: 0.75px solid #333946;
    background: #222531;
    height: 40px;
    color: #8e97a8;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
} 
.form-control  .ant-btn {
    display: none;
}
.ant-input-number-handler {
    display: none !important;
}
.ant-input-number-handler-wrap {
    display: none !important;
}
.ant-input-number {
    border: 0px solid transparent !important;
}
@media screen and (max-width: 768px) {
    .group-title{
        margin-top: 70px;
        padding-bottom: 10px !important;
    }
    .group-title .main-title.res-title{
        margin-bottom: 15px;
        padding-bottom: 0px;
    }
    .round-tabs-volum{
        margin-top: 15px !important;
    }
    .round-tabs-volum .ant-tabs-content{
        margin-top: 0px !important;
    }
    .group-dex{
        display: block;
    }
    .group-dex .item-master{
        width: 100% !important;
    }
    .group-dex .item-master:nth-child(1){
        margin-bottom: 15px;
    }
}
