input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.mt-mobile-0{
    @include sp {
        margin-top: 0px !important;
    }
}
.ant-modal{
    top: 40px !important;
}
.align-middle{
    display: grid !important;
    align-items: center;
}
.chain-disable{
    opacity: 0.5;
    cursor: no-drop;
}
.align-center{
    align-items: center !important;
}
.p-5{
    padding: 5px 10px !important;
}
.mr-10{
    margin-right: 10px !important;
}
.mr-0{
    margin-right: 0px !important;
}
.gap-10{
    gap: 10px;
}
.gap-5{
    gap: 5px;
}
.height-auto{
    height: auto !important;
}
.loading-full{
    width: 100%;
    padding: 20px;
}
.d-flex{
    display: flex;
}
.d-md{
    display: block;

    @include sp{
        display: none;
    }
}
.d-xs{
    display: none;

    @include sp{
        display: block;
    }
}
.p-5{
    padding: 5px 10px !important;
}
.height-auto{
    height: auto !important;
}
.d-flex-btw {
    justify-content: space-between;
}
.pt-3{
    padding-top: 3px !important;
}
.flex-btw{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.flex-center{
    display: flex !important;
    justify-content: center;
    gap: 10px;
    align-items: center;
}
.flex-left{
    display: flex !important;
    justify-content: left;
    align-items: center;
}
.text-danger{
    color: rgb(241, 67, 67) !important;
}
.text-gray{
    color: #787878 !important;
}
.text-black {
    color: #000 !important;
}
.table-desktop{
    display: block;

    @include sp{
        display: none !important;
    }
}
.table-mobile {
    display: none;

    @include sp {
        display: block !important;
    }
}
.mt-mobile-15 {
    @include sp {
        margin-top: 15px !important;
    }
}
.mb-0{
    margin-bottom: 0px !important;
}
.mt-0 {
    margin-top: 0px !important;
}
.mr-5{
    margin-right: 5px !important;
}
.ml-5{
    margin-left: 5px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.p-10{
    padding: 10px !important;
}
.text-black-green{
    color: #0A2C31 !important;
}
.mt-5{
    margin-top: 5px;
}
.mr-15{
    margin-right: 15px;
}
.text-white{
    color: #fff !important;
}
.line-24{
    line-height: 24px !important;
}
.text-weight-600{
    font-family: AeonikMedium !important;
}
.ul-cus{
    padding-left: 20px;
    margin-top: 10px;
    li{
        list-style-type:circle;
    }
}
.img-border{
    border: 1px solid #28e6d6;
    border-radius: 100%;

}
.text-weight-500 {
    font-family: AeonikRegular !important;
}
.w-25{
    width: 25px !important;
}
.text-16{
    font-size: 16px !important;
}
.text-18{
    font-size: 18px !important;
}
.text-25 {
    font-size: 25px !important;
}
.text-darkblue{
    color: #3F666B !important;
}
.text-darkgray{
    color: #696f78 !important;
}
.text-regular-darkgray{
    font-family: AeonikRegular;
    color: #707A8A !important;
}
.text-green{
    color: rgba(24, 226, 153, 1) !important;
}
.text-red{
    color: rgb(215, 16, 16) !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px;
}
.text-blue{
    color: rgb(41, 89, 249) !important;
}
.btn-copy{
    display: block;
    display: block !important;
    width: auto !important;
    margin-left: 7px;
    svg{
        color: #3F666B;
        font-weight: 700;
    }
}
.btn-black{
    border-radius: 10px;
    background: #0A2C31;
    color: #FFF;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 12px !important;
    cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
    &:disabled{
        opacity: 0.5;
    }
}
.btn-filter{
    padding: 5px 12px !important;
    border-radius: 10px;
    border: 1px solid #3BD5C1;
    font-family: AeonikRegular;
    font-size: 16px;
    color: #3BD5C1;
    background: transparent !important;
    margin-bottom: 5px;
    cursor: pointer;
}
.mb-5{
    margin-bottom: 5px;
}
.menu-filter{
    width: 330px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #DEEBED;
    background: #FFF;
    box-shadow: 0px 3px 24px 0px rgba(24, 84, 92, 0.25);

    @include sp{
        width: 100% !important;
    }

    .collap-filter{
        background-color: #fff;
        .ant-collapse-header{
            color: #1E2329;
            font-family: AeonikRegular;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
        }
        .ant-collapse-item{
            border-bottom: 1px solid #f0f0f0 !important;
        }

        .ant-checkbox-wrapper{
            color: #1E2329;
            font-family: Aeonik;
            font-size: 16px;
        }
        .ant-checkbox-checked .ant-checkbox-inner{
            background-color: #3BD5C1 !important;
            border-color: #3BD5C1 !important;
        }
    }
}
.c-backTop {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    img {
        width: 100%;
    }
}

.c-commingSoon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -35px -25px -25px -40px;
    @include min {
        margin: -35px -75px -75px -75px;
    }
    @include tablet {
        margin: -100px -25px -30px;
    }
    img {
        width: 100%;
    }
}

.head-breadcrumb-airdrop {
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px !important;

    @include sp {
        margin-bottom: 5px !important;
        margin-top: 0px !important;
    }

    ol{
        align-items: center;
    }

    li a {
        font-family: AeonikRegular;
        font-size: 16px;
        color: #3F666B;
    }

    li:last-child {
        color: #2b9f8f !important;
    }
}
.head-breadcrumb{
    position: relative;
    margin-top: 40px !important;
    margin-bottom: -40px !important;
    
    @include sp{
        margin-bottom: 5px !important;
        margin-top: 0px !important;
    }
    li a{
        font-family: AeonikRegular;
        font-size: 16px;
        color: #3F666B;
    }
    li:last-child{
        font-family: AeonikRegular;
        font-size: 16px;
        color: #2b9f8f !important;
    }
}
.head-breadcrumb-detail {
    position: relative;
    display: block;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    li a {
        font-family: AeonikRegular;
        font-size: 16px;
        color: #3F666B;
    }

    li:last-child {
        color: #3BD5C1 !important;
    }
}
.c-loadingVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #101011;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
      animation: spinner 1.5s infinite linear;
      box-shadow: 
      rgba(249,200,1,.75) 18px 0 0 0, 
      rgba(249,200,1,.75) 13px 13px 0 0, 
      rgba(249,200,1,.75) 0 18px 0 0, 
      rgba(249,200,1,.75) -13px 13px 0 0, 
      rgba(249,200,1,.75) -18px 0 0 0, 
      rgba(249,200,1,.75) -13px -13px 0 0, 
      rgba(249,200,1,.75) 0 -18px 0 0, 
      rgba(249,200,1,.75) 13px -13px 0 0;
    }
    span {
      font-size: 12px;
      line-height: 1;
      color: #F9C801;
      padding-top: 30px;
      text-transform: uppercase;
      font-weight: bold;
      animation: loading-text 1.8s linear 0s infinite normal;
      letter-spacing: .8px;
    }
}
.link-detail {
    border-radius: 20px;
    border: 1.5px solid #3BD5C1;
    text-align: center;
    display: block;
    color: #3BD5C1;
    text-align: center;
    font-family: AeonikRegular;
    font-size: 16px;
    padding: 7px 20px;
    margin-top: 25px;
}
.link-detail:hover{
    background: #3BD5C1;
    color: #fff;
}
.c-loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 400px;
    }
}

.c-loadingContent {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10%;
    img {
        width: 400px;
    }
}

.c-copied {
    border-radius: 3px;
    padding: 20px 51px 20px 25px;
    color: #5A7052;
    background: #DEF2D6;
    border: 1px solid #5A7052;
    width: 400px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
    &:after {
        content: '';
        width: 100%;
        height: 4px;
        background: #5A7052;
        position: absolute;
        bottom: 0;
        right: 0;
        animation: width 3.1s ease-in-out;
    }
}

.c-btn {
    appearance: none;
    border: 0;
    background: #0EAD98;
    border-radius: 10px;
    padding: 15px 32px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    font-family: AeonikRegular;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    &.claim{
        padding:12px 25px
    }
    &:hover {
        background: #fff;
        color: #0EAD98;
        border: 1px solid #0EAD98;
    }
    &:disabled {
        background: #ECEFF4 !important;
        color: #454545;
        cursor: not-allowed;
    }
    &--border {
        border: 1px solid #0EAD98;
        background: #32353E;
        color: #32353E;
        padding: 14px 31px;
        margin-left: 7px;
        &:hover {
            background: #0EAD98;
            color: #1A1C1E;
        }
        &:disabled {
            border: 1px solid #C4C4C4;
            background: #575759;
            color: #C4C4C4;
            cursor: not-allowed;
        }
    }
    &--cancel {
        border: 1px solid #575759;
        background: transparent;
        color: #C4C4C4;
        padding: 14px 31px;
        &:hover {
            background: transparent;
            color: #C4C4C4;
            opacity: .7;
        }
    }
    &.mobile {
        @include sp {
            width: 100%;
        }
        + .mobile {
            @include sp {
                margin-top: 15px;
            }
        }
    }
}

.c-btnWallet {
    height: auto;
    appearance: none;
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
    border-radius: 20px;
    border: 0;
    font-family: AeonikRegular;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    // transition: 0.3s ease-in-out;
    @include sp {
        width: fit-content;
        margin: 5px 0 0;
    }
    &:hover {
        opacity: 0.8;
    }
}

.c-crossChain {
    width: 165px;
    position: relative;
    @include tablet {
        width: 170px;
        margin: 25px 0 0;
    }
    @include sp {
        display: none;
    }
    &__showName {
        width: 100%;
        height: auto;
        // background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
        background: #fff;
        border-radius: 20px;
        padding: 10px 10px;
        font-size: 16px;
        line-height: 20px;
        color: #19B4CD !important;
        border: 1.5px solid #19B4CD;
        font-family: AeonikRegular;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        cursor: default;
        transition: 0.3s ease-in-out;

        &:after {
            content: '';
            width: 16px;
            height: 8px;
            background: url(../images/arrow01.png) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 10px;
            top: calc(50% - 4px);
            transition: 0.3s ease-in-out;
        }
        img {
            width: 20px;
            margin-right: 5px;
        }
    }
    &__list {
        list-style-type: none;
        padding: 15px !important;
        margin: 0;
        width: 120%;
        position: absolute;
        top: 50px;
        border-radius: 15px;
        left: 0;
        opacity: 0;
        z-index: 10;
        transform-origin: top;
        transition: 0.3s ease-in-out;
        overflow: hidden;
        max-height: 0;
        background: #fcfeff;
        border: 1px solid #EDF3F4;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

        li {
            font-size: 16px;
            line-height: 20px;
            color: #74757C;
            // background-color: transparent !important;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            + li {
                border-top: 1px solid #EDF3F4;
            }
            img {
                width: 20px;
                margin-right: 5px;
            }
            &:hover {
                background: #FDF5D3;
            }
        }
    }
    &:hover {
        .c-crossChain__showName {
            //@media screen and (min-width:821px) {
            background-color: #EDF3F4;
            color: #000;
            cursor: pointer;
            //}
            &:after {
                //@media screen and (min-width:821px) {
                transform: rotate(180deg);
                //}
            }
        }
        .c-crossChain__list {
            //@media screen and (min-width:821px) {
            opacity: 1;
            z-index: 1002;
            max-height: 300px;
            //}
        }
    }
}

.c-action {
    display: block;
    font-size: 16px;
    line-height: 1;
    color: #0EAD98;
    font-family: AeonikRegular;
    
}
.btn-action{
    display: flex;
    justify-content: center;
    .c-btn{
      padding: 12px 20px;
      font-size: 14px; 
    }
}

.invest-allo-title{
    color: #0C434B !important;
    text-align: left;
    font-family: AeonikMedium;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.tab-ido-general{
    background: transparent !important;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;

    .ant-tabs-nav{
        border-bottom: 1px solid #CADADD;
        margin-bottom: 10px !important;
    }
}
.c-tabs {
    margin-bottom: 10px !important;
    border-radius: 20px;
    border: 0.7px solid #DEEBED;
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

    &__nav {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        border-bottom: 1px solid #CADADD;

        @include sp {
            display: block;
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 1000;
            background-color: #22252F;
            max-height: 0;
            transition: .3s ease-in-out;
        }
        &.is-open {
            @include sp {
                max-height: 348px;
            }
        }
        li {
            padding-bottom: 10px;
            position: relative;
            @include sp{
                padding-bottom: 0;
            }
            + li {
                margin-left: 55px;
                @include max(1280px) {
                    margin-left: 38px;
                }
                @include tablet {
                    margin-left: 30px;
                }
                @include sp {
                    margin-left: 0;
                }
            }
            &:after {
                content: '';
                width: 0;
                height: 3px;
                background: #0EAD98 !important;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: all 0.2s ease-in-out;
                @include sp {
                    display: none;
                }
            }
            a {
                color: #3F666B;
                font-family: Aeonik !important;
                font-size: 16px !important;
                line-height: 1;
                text-transform: capitalize;
                text-decoration: none;  
                transition: all 0.2s ease-in-out;
                @include min {
                    font-size: 24px;
                }
                @include sp {
                    font-size: 18px;
                    display: block;
                    padding: 20px 25px;
                }
            }
            &:hover,
            &.is-active {
                &:after {
                    width: 100%;
                }
                a {
                    color: #0EAD98;
                }
            }
            &.is-close {
                background: #32353E;
                padding: 20px 60px 20px 25px;
                font-size: 18px;
                line-height: 1;
                color: #FDFCFF;
                span {
                    display: block;
                    width: 28px;
                    height: 28px;
                    padding: 2px;
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 14px);
                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    &__navSP {
        width: 100%;
        // background: #32353E;
        border-radius: 10px;
        font-size: 18px;
        line-height: 1;
        text-transform: capitalize;
        letter-spacing: 0.025em;
        color: #0C434B;
        font-family: AeonikMedium;
        padding: 15px 50px 15px 15px;
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
        cursor: pointer;
        position: relative;
        @include sp {
            margin-bottom: 20px;
        }
        &:after {
            content: '';
            width: 16px;
            height: 8px;
            background: url(../images/arrow01.png) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 20px;
            top: calc(50% - 4px);
            transition: 0.3s ease-in-out;
        }
    }
    &__content {
        padding-top: 40px;
        position: relative;
        @include min {
            padding-top: 0px;
        }
        @include tablet {
            padding-top: 0px;
        }
        &.is-changeAirdrop {
            position: unset;
        }
    }
    &__ask {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -12px;
        right: -29px;
        cursor: pointer;
        @include sp {
            display: none;
        }
        svg {
            width: 100%;
            path {
                transition: .3s ease-in-out;
                &:first-child {
                    stroke: #71717A;
                }
                &:last-child {
                    fill: #71717A;
                }
            }
            &:hover {
                path {
                    &:first-child {
                        stroke: #FFD60A;
                    }
                    &:last-child {
                        fill: #FFD60A;
                    }
                }
                ~ p {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        p {
            width: 333px;
            padding: 5px 10px;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            color: #C4C4C4;
            background: #22252F;
            border: 1px solid #71717A;
            border-radius: 5px;
            position: absolute;
            top: 34px;
            right: 0;
            transition: .3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            z-index: 2;
        }
    }

    .ant-tabs-nav {
        padding: 0;
        &:before {
            display: none;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            @include sp{
                flex-wrap: inherit !important;
            }
            .ant-tabs-tab {
                padding-left: 10px;
                padding-right: 10px;
                font-size: 18px;
                line-height: 1;
                color: #5f5f5f;
                font-family: AeonikRegular;
                padding-bottom: 15px;
                position: relative;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                @include sp {
                    font-size: 16px;
                }
                + .ant-tabs-tab {
                    margin-left: 15px;
                }
                &:after {
                    content: '';
                    width: 0;
                    height: 3px;
                    background: #FFD60A;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: all 0.2s ease-in-out;
                }
                &-active {                    
                    .ant-tabs-tab-btn {
                        color: #0EAD98;
                        text-align: center;
                        font-family: AeonikMedium !important;
                        font-size: 17px;
                    }
                    &:after {
                        width: 100%;
                        background: #0EAD98;
                    }
                }
            }
            .ant-tabs-ink-bar-animated {
                display: none;
            }
        }
        &-operations {
            display: none;
        }
    }
    .ant-tabs-content {
        padding-top: 0px;
    }
}

.ant-tabs {
    &.c-phase {
        margin-top: -25px;
        @include max(1024px) {
            margin-top: -10px;
        }
        &.is-phase2 {
            > .ant-tabs-nav {
                > .ant-tabs-nav-wrap {
                    > .ant-tabs-nav-list {
                        > .ant-tabs-tab {
                            &:nth-child(2) {
                                > .ant-tabs-tab-btn {
                                    background-image: linear-gradient(
                                        -225deg,
                                        #3B94C1 0%,
                                        #FFD60A 29%,
                                        #FF3907 67%,
                                        #FF4574 100%
                                    );
                                    background-size: auto auto;
                                    background-clip: border-box;
                                    background-size: 200% auto;
                                    color: #FFD60A;
                                    background-clip: text;
                                    text-fill-color: transparent;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    animation: textclip 2s linear infinite;
                                    text-shadow: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
        > .ant-tabs-nav {
            margin: 0 -25px;
            background-color: #191B21;
            @include max(1024px) {
                margin: 0 -10px;
            }
            > .ant-tabs-nav-wrap {
                > .ant-tabs-nav-list {
                    width: 100%;
                    > .ant-tabs-tab {
                        width: 50%;
                        padding: 0;
                        font-size: 20px;
                        transition: .3s ease-in-out;
                        &:before {
                            content: '';
                            width: 100%;
                            height: 100%;
                            border-top: 2px solid #22252F;
                            border-left: 2px solid #22252F;
                            border-right: 2px solid #22252F;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        &:after {
                            height: 1px;
                            background-color: #191B21;
                        }
                        + .ant-tabs-tab {
                            margin-left: 0;
                        }
                        > .ant-tabs-tab-btn {
                            width: 100%;
                            text-align: center;
                            font-size: 20px;
                            line-height: 23px;
                            letter-spacing: 0.025em;
                            color: #74757C;
                            padding: 25px;
                            transition: .3s ease-in-out;
                        }
                        &-active {
                            background: #22252F;
                            &:after {
                                height: 1px;
                                background-color: #191B21;
                            }
                            > .ant-tabs-tab-btn {
                                color: #FFD60A;
                            }
                        }
                    } 
                }
            }
            
        }
        .ant-tabs-content {
            @include sp {
                padding-top: 10px;
            }
        }
    }
}

.countdown{ 
    color: #FDF5D3;
    justify-content: center;
    padding:0 50px;
    width: 100%;
    &-detail{
        width: 100%;
        padding:0px;
        align-items: center;
        span{
            font-size: 18px;
            @include tablet{
                font-size: 12px;
            }
            @include sp{
                font-size: 12px;
            }
        }
       
    }
    span{ 
        font-size: 20px;
        font-weight:400px;
    } 
    .flex{
        display: flex;
        justify-content: space-between; 
        p{
            font-size: 14px; 
            color: #3F666B;
            font-weight:400px;
        }  
    }
}

.btn-ino{
    width: 100%; 
    position: absolute;
    .c-btnWallet{
        width: 100%;
        margin-right: 0px;
    }
}
.btn-info{
    border: 1px solid #3BD5C1;
    background: #FFF;
    font-family: AeonikMedium;
    padding-left: 10px;
    padding-right: 10px;

    color: #3BD5C1;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    height: 46px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    position: relative;
    margin-top: 0px;
    cursor: pointer;

    @include sp {
        padding: 10px;
        height: auto;
        font-size: 14px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.8;
    }
}
.btn-border-danger{
    border: 1px solid #ed3b35 !important;
    color: #ed3b35 !important;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.5;
    }
}
.max-Box{
    color: #74757C;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}


.ino_amount{
    margin-top: 30px;
    width: 100%;
    background:#22252F;
    font-size: 16px;
    color:#C4C4C4;
    border-radius: 5px;
    margin-bottom: 5px;

    .flex{
        display: flex;
    }
    &__inputBox{
        width: 80%;
        position: relative;
        display: flex;
    } 
    .input{ 
        width: 100%;
        padding:15px;
        background: transparent;
        border:0;
        border: 1px solid transparent;
        @include tablet{
            font-size: 12px; 
            padding:10px;
        }
        &:focus{
            outline: none;
            border: 1px solid #FFD60A;
            box-shadow: 4px 4px 4px rgba(255, 214, 10, 0.2);
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px; 
        }
    } 
    &__BoxMax{
        display: flex;
        position: absolute;
        right: 0%;
        width: 30%;
        justify-content: center;
        .box{   
            color: #74757C;
            font-size:16px;
            border-right: 1px solid #71717A;
            background: transparent; 
            width: 50%;
            text-align: center;
            font-weight: 400; 
            padding: 5px; 
            margin: 10px 0px;
            @include tablet{
                font-size: 12px; 
                padding: 5px; 
                margin: 5px 0;
            }
        }
        .max{ 
            color: #3F666B;
            font-family: AeonikRegular !important;
            font-size: 16px !important;
            background: transparent; 
            cursor: pointer; 
            width: 50%;
            text-align: center;
            font-weight: 400; 
            justify-content: center;
            padding: 5px; 
            margin: 10px 0;
            border: 0px;
            @include tablet{
                font-size: 12px; 
                margin: 5px 0;
            }
        }
    }  
    .buy-now{
        background: #67696b;
        width: 22%;
        padding: 15px;
        font-size: 16px;
        text-align: center;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid transparent;  
        @include tablet{
            font-size: 12px;
            padding: 10px;
        }
        @include sp{
            font-size: 10px;
            padding: 10px;
        } 
        &.active{
            background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
            border-left: none !important;
            color:#e1edfa; 
            font-family: AeonikRegular !important;
            height: 100%;

            &:hover{
                opacity: 0.8;
            }
        }
    }
    &__estimated{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        align-items: center;
        .text{
            color:#74757C;
            font-size: 16px;
            font-weight: 400;
            @include sp{
                font-size: 12px;
            }
        }
        .price{
            color:#FEFEFE;
            font-size: 16px;
            font-weight: 400;
            margin-left: 20px;
            @include sp{
                font-size: 14px;
            }
        }
    }
}
.c-list {
    display: block;
    // flex-wrap: wrap;
    // margin: 0 -15px;
    @include sp {
        margin: 0;
    }
   
    &__tag{
        display: flex;
        align-items: center;
        padding: 15px 20px;
        img{
            width: 25px;
            height: 25px;
        }
        span{
            font-size: 14px;
            font-weight: 600;
            margin-left: 5px; 
            color:#fff
        }  
    }
    &-ino{
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        &__head {
            color: #3F666B !important;
            font-family: AeonikMedium;
            font-size: 18px;
            text-transform:uppercase;

            a{
                color: #3F666B !important;
                font-family: AeonikMedium;
                font-size: 18px;
                text-transform: uppercase;
            }
        }
        img{
            width:79.25px;
            margin-right: 5px; 
            object-fit:contain ;
        }
        &__row{
            margin-bottom: 6px;
            // border-bottom: 1px solid #ccc;s
            display: flex;
            justify-content: space-between;

            span{
                color: #3F666B;
                font-family: AeonikRegular;
                font-size: 14px;

                &:last-child{
                    font-family: AeonikRegular;
                    color: #0A2C31;
                    font-size: 18px;
                    align-items: center;

                    img{
                        margin-right: 5px;
                    }
                }
            }
        }
        &__center{
            display: flex;
            justify-content: center; 
        }
        &__lighttext{
            color: #3F666B;
            font-family: AeonikRegular;
            font-size: 16px;
            margin-bottom: 0px;
            padding-top:18px;
            padding-bottom: 10px;
            @include sp {
                font-size: 14px;
            }
        }
    }
    &__item {
        box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
        border-radius: 20px;
        width: 100%;
        margin-top: 30px;
        // margin: 0 15px 30px;
        padding-bottom: 10px;
        position: relative; 
        &.bg{
            background: radial-gradient(circle, #485563 10%, #22252F 60%);
        }
        
        @include sp {
            margin-top: 20px;
        }
    }
    &__img {
        position: relative;
        img {
            display: block;
            width: 100%;
            border-radius: 20px 20px 0px 0px;
            transition: transform .3s;
            height: 100%;
            object-fit: contain;
        }
        :hover{
            transform: scale(1.02);
        }
    }
    &__status {
        font-size: 13px;
        line-height: 1;
        color: #1A1C1E;
        background: #FFD60A;
        border-radius: 2px;
        padding: 4px 5px 4px 12px;
        position: absolute;
        top: 10px;
        left: 10px;
        &.protected{ 
            background: #4770C4 !important;
            color: #FFFFFF!important;  
            // img{
            //     width: 10px !important;
            //     height: 10px; 
            //     object-fit: contain; 
            //     position: absolute;
            //     left: 5px;
            //     top: calc(50% - 5px);
            // }
            &:before{
                content: '';
                background: #fff;
            }
        }
        &:before {
            content: '';
            width: 4px;
            height: 4px;
            background: #1A1C1E;
            border-radius: 50%;
            position: absolute;
            left: 5px;
            top: calc(50% - 2px);
        }
        &.is-Opening,
        &.is-OPENING,
        &.is-joined {
            background: #F67519;
            color: #fff;
            &:before {
                background: #fff;
            }
        }
        &.is-Completed,
        &.is-claimable {
            background: #00BE08;
            color: #fff;
            &:before {
                background: #fff;
            }
        }
    }
    &__body {
        padding: 20px;  
        @include sp {
            padding: 15px;
        }
        
       &.bg {
            background-color: rgb(34 37 47 / 50%);
            margin-top: 20px;
        }
        .ino-list{
            align-items: center;
            display: flex;
            width: 100%; 
            border-bottom: 1px solid #32353E;  
            @include sp {
               flex-wrap: wrap;
               align-items:start;
                
             }
            .nav-list{  
                .header-item{
                color:#C4C4C4;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                padding:5px;
                
                @include sp {
                    font-size: 10px;
                }
                }
                .flex{
                    display: flex;
                    align-items: center;
                }
               
            } 
            .list-item{  
                width: 20%;
                text-align: center;
                align-items: center;
                display: flex; 
                justify-content: center;
                // &:last-child{
                //     .header-item{
                //         padding-bottom: 20px;
                //     } 
                // }
                @include sp {
                    width: 33%;
                    text-align: left;
                    justify-content:flex-start;
                    padding:10px 0px
                 } 
                .img-item{ 
                    width: 50px;
                    height: 50px;
                    padding:10px;
                    background: radial-gradient(53.49% 53.49% at 50% 46.51%, #485563 0%, #22252F 100%);
                    border-radius: 99px;
                }
                .name-item{
                    font-size: 18px;
                    font-weight: 400;
                    color:#FDF5D3;
                    margin-left: 5px;
                    display: block;
                    @include sp {
                        font-size: 12px;
                    }
                }
                .img-icon{
                    width: 25px;
                    height: 25px; 
                }
            }
        }
       
    }
    &__mainhead{
        padding:12px 12px 0 12px
    }
    &__head {
        
        line-height: 30px;
        color: #0A2C31;
        font-family: AeonikMedium;
        font-size: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 15px; 
       
        @include sp {
            font-size: 18px;
        }
        span {
            color: #707a8a;
            font-size: 14px;
            font-family: AeonikRegular;
            margin-left: 10px;

            // @include sp {
            //     width: 80px;
            //     height: auto;
            // }
            // img {
            //     // width: 100%;
            //     max-width: 45px;
            //     height: auto;
            //     max-height: 100%;
            // }
        }
    }
    &__row {
        font-size: 15px;
        line-height: 20px;
        color: #3F666B;
        margin: 0 0 17px;
        display: flex;
        flex-wrap: wrap;
        
        @include sp {
            margin: 15px 0 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            display: block;
            letter-spacing: 0.36px;
            &:first-child {
                width: 110px;
            }
            &:last-child {
                width: calc(100% - 110px);
                font-size: 17px;
                color: #0A2C31;
                text-transform: capitalize;
                font-family: AeonikRegular;
                text-align: right;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                
               
                @include sp {
                    font-size: 16px;
                }
                img {
                    display: block;
                    width: 20px;
                    margin-left: 5px;
                }
            }
        }
    }
    &__line {
        display: block;
        width: 100%;
        height: 1px;
        background: #32353E;
        margin-bottom: 15px;
    }
    &__footer {
        width: 100%;
        padding: 0 15px 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
      
        p {
            width: calc(100% - 110px);
            font-size: 16px;
            line-height: 20px;
            color: #74757C;
            margin: 0;
            @include min {
                font-size: 18px;
            }
            @include sp {
                font-size: 14px;
            }
            span {
                display: block;
                &:last-child {
                    font-size: 18px;
                    line-height: 1;
                    color: #FFD60A;
                    font-weight: 500;
                    margin-top: 5px;
                    @include min {
                        font-size: 20px;
                    }
                    @include sp {
                        font-size: 16px;
                    }
                }
            }
        }
        a {
            display: block;
            width: 110px;
            font-family: AeonikRegular;
            border-radius: 20px;
            border: 1.5px solid #3BD5C1;
            color: #3BD5C1;
            font-size: 16px;
            line-height: 1;
            text-decoration: none;
            text-align: center;
            padding: 12px;
            transition: 0.3s ease-in-out;
            @include min {
                font-size: 18px;
            }
            &:hover {
                background: #3BD5C1;
                color: #FDFCFF;
            }
        }
    }
    &__title {
        font-size: 18px;
        line-height: 1;
        color: #fff;
        font-weight: bold;
        span {
            display: block;
            width: 80px;
            img {
                width: 100%;
            }
        }
    }
    &__logo {
        width: 25%;
        display: flex;
        flex-wrap: wrap;
        @include tablet {
            width: 40%;
        }
        @include sp {
            width: 100%;
        }
    }
    &__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @include tablet {
            width: 60%;
        }
        @include sp {
            width: 100%;
        }
        .c-list__row {
            width: calc(100%/6);
            display: block;
            padding-left: 15px;
            @include tablet {
                width: calc(100%/3);
            }
            span {
                &:first-child,
                &:last-child {
                    width: 100%;
                    text-transform: unset;
                    justify-content: flex-start;
                }
                &:last-child {
                    margin-top: 10px;
                    text-align: left;
                    line-height: 20px;
                    @include sp {
                        margin-top: 5px;
                    }
                }
                &.up {
                    color: #00BE08;
                }
                &.down {
                    color: #F73D1E;
                }
            }
        }
    }
    &__done {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(4px);
        position: absolute;
        top: 0;
        left: -1px;
        z-index: 5;
        border-top: 1px solid #32353E;
        p {
            width: 445px;
            background: #F8F3D6;
            border: 1px solid #D79E3D;
            border-radius: 3px;
            padding: 10px 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 18px;
            color: #8A713C;
            @include sp {
                width: calc(100% - 40px);
            }
            span {
                display: flex;
                align-items: center;
                width: calc(100% - 110px);
                padding-right: 10px;
                img {
                    margin-right: 15px;
                }
            }            
            button {
                appearance: none;
                display: block;
                width: 110px;
                border: 0;
                background: #4770C4;
                border-radius: 10px;
                color: #FDFCFF;
                font-size: 16px;
                line-height: 1;
                text-decoration: none;
                text-align: center;
                padding: 12px;
                transition: 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    background: #4167B6;
                    color: #FDFCFF;
                }
            }
        }
    }
    &.is-completed {
        display: block;
        margin: 0;
        a {
            text-decoration: none;
            position: relative;
            &.noneClick {
                pointer-events: none;
            }
        }
        .c-list__item {
            width: 100%;
            box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);
            padding: 15px 0;
            background: transparent;
            margin: 0;
            display: flex;
            border-radius: 0;
            margin-top: 15px;
            @include sp {
                margin-top: 15px;
                display: block;
            }
        }
        .c-list__img {
            width: calc(50% - 5px);
            border-radius: 5px;
            @include tablet {
                width: calc(60% - 5px);
            }
            @include sp {
                width: 140px;
            }  
            img {
                width: 100%;
                display: block;
            }
           
        }
        .c-list__title {
            width: 100%;
            margin-left: 10px;
            align-items: center;
            display: flex;
            .pro-banner{
                max-height: 30;
                width: auto;
                max-width: 50px;
                margin-right: 10px;

                img{
                    width: 100%;
                }
            }
            .pro-title{
                color: #0A2C31;
                font-family: Aeonik;
                font-size: 16px;
            }
            
            @include sp {
                width: 100%;
            }
        }
        // .c-list__status {
        //     background: #00BE08;
        //     color: #fff;
        //     &:before {
        //         background: #fff;
        //     }
        // }
    }
    &.is-joinedClaim {
        display: block;
        margin: 0;
        @include sp {
            margin: 0 -25px;
        }
        .c-list__item {
            width: 100%;
            padding: 0;
            margin: 15px 0 0;
            background: #22252F;
            border-radius: 2px;
            display: flex;
            flex-wrap: wrap;
            @include sp {
                display: block;
                margin: 0 0 15px;
            }
        }
        .c-list__logo {
            padding: 15px 30px;
            background: #32353E;
            border-radius: 2px;
            width: 20%;
            flex-direction: column;
            justify-content: center; 
            @include max(1280px) {
                padding: 15px;
            }
            @include sp {
                width: 100%;
                flex-direction: row;
                align-items: flex-start;
                padding: 15px 25px;
            }
        }
        .c-list__img {
            @include sp {
                width: 140px;
            }
        }
        .c-list__title {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-weight: normal;
            width: 100%;
            margin-top: 5px;
            align-items: center;
            @include sp {
                width: calc(100% - 140px);
                margin: 0;
                padding-left: 5px;
            }
            h3 {
                color: #fff;
                font-weight: normal;
                width: calc(100% - 60px);
                text-align: left;
                margin: 0;
                @include tablet {
                    width: 100%;
                    order: 2;
                }
            }
            span {
                width: 50px;
                margin-left: 10px;
                @include tablet {
                    order: 1;
                    margin: 0 0 5px;
                }
            }
        }
        .c-list__body {
            padding: 0 0 0 25px;
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            @include sp {
                width: 100%;
                padding: 0 25px;
            }
        }
        .c-list__content {
            width: 100%;
            align-items: center;
            padding: 15px 0;
            @include sp {
                align-items: baseline;
                padding: 15px 0 0;
            }
            + .c-list__content {
                border-top: 1px solid #32353E;
            }
        }
        .c-list__row {
            width: calc(100%/6 - 9px);
            padding: 0;            
            margin: 0;
            @include sp {
                width: calc(100%/3);                
                padding-right: 5px;
                margin-bottom: 15px;
            }
            + .c-list__row {
                margin-left: 10px;
                @include sp {
                    margin-left: 0;
                }
            }
            &:nth-of-type(3) {
                span {
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        @include sp {
                            display: block;
                            width: 100%;
                            text-align: right;
                            justify-content: flex-end;
                        }
                    }
                }
            }
            span {
                position: relative;
                &:nth-of-type(1) {
                    display: none;
                    @include sp {
                        text-transform: uppercase;
                        display: initial;
                    }
                }
                &:nth-of-type(2) {
                    margin-top: 0;
                    text-align: center;
                    justify-content: center;
                    @include sp {
                        margin-top: 10px;
                        text-align: left;
                        justify-content: flex-start;
                    }
                }
                &.is-Joined {
                    color: #00BE08;
                }
                &.is-Claim {
                    color: #F67519;
                }
                &.is-Refunded,
                &.is-Finished {
                    color: #74757C;
                }
                &.is-Claimable {
                    color: #FFD60A;
                }
                &.is-missed-claim {
                    color: #F73D1E;
                }
                &.is-selectNetwork {
                    color: #3785FA;
                    font-size: 14px;
                    padding-right: 24px;
                    position: relative;
                    svg {
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        right: 0;
                        top: -5px;
                        cursor: pointer;
                        path {
                            stroke: #71717A;
                        }
                        &:hover {
                            path {
                                stroke: #FFD60A;
                            }
                            + p {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    p {
                        width: 295px;
                        background: #22252F;
                        border: 1px solid #71717A;
                        border-radius: 5px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #C4C4C4;
                        position: absolute;
                        top: calc(100% + 5px);
                        right: 2px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s ease-in-out;
                        z-index: 2;
                    }
                }
            }
        }
        
        .c-list__ask {
            width: 24px;
            height: 24px;
            position: absolute;
            top: calc(50% - 12px);
            right: -29px;
            cursor: pointer;
            display: none;
            @include sp {
                display: block;
            }
            svg {
                width: 100%;
                path {
                    transition: .3s ease-in-out;
                    &:first-child {
                        stroke: #71717A;
                    }
                    &:last-child {
                        fill: #71717A;
                    }
                }
                &:hover {
                    path {
                        &:first-child {
                            stroke: #FFD60A;
                        }
                        &:last-child {
                            fill: #FFD60A;
                        }
                    }
                    ~ p {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            p {
                width: 333px;
                padding: 5px 10px;
                margin: 0;
                font-size: 16px;
                line-height: 24px;
                color: #C4C4C4;
                background: #22252F;
                border: 1px solid #71717A;
                border-radius: 5px;
                position: absolute;
                top: 34px;
                right: 0;
                transition: .3s ease-in-out;
                opacity: 0;
                visibility: hidden;
                z-index: 2;
                text-transform: none;
                @include sp {
                    width: 300px;
                    right: calc(50% - 150px);
                }
                i {
                    text-decoration: underline;
                }
            }
        }
    }
    &.is-claimable {
        display: block;
        margin: 0;
        @include sp {
            margin: 0 -25px;
        }
        .c-list__item {
            width: 100%;
            padding: 0;
            margin: 15px 0 0;
            background: #22252F;
            border-radius: 2px;
            display: flex;
            flex-wrap: wrap;
            @include sp {
                display: block;
                margin: 0 0 15px;
            }
        }
        .c-list__logo {
            padding: 15px 30px;
            background: #32353E;
            border-radius: 2px;
            width: 20%;
            flex-direction: column;
            justify-content: center; 
            @include max(1280px) {
                padding: 15px;
            }
            @include sp {
                width: 100%;
                flex-direction: row;
                align-items: flex-start;
                padding: 15px 25px;
            }
        }
        .c-list__img {
            @include sp {
                width: 140px;
            }
        }
        .c-list__title {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-weight: normal;
            width: 100%;
            margin-top: 5px;
            align-items: center;
            @include sp {
                width: calc(100% - 140px);
                margin: 0;
                padding-left: 5px;
            }
            h3 {
                color: #fff;
                font-weight: normal;
                width: calc(100% - 60px);
                text-align: left;
                margin: 0;
                @include tablet {
                    width: 100%;
                    order: 2;
                }
            }
            span {
                width: 50px;
                margin-left: 10px;
                @include tablet {
                    order: 1;
                    margin: 0 0 5px;
                }
            }
        }
        .c-list__body {
            padding: 0 0 0 25px;
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            @include sp {
                width: 100%;
                padding: 0 25px;
            }
        }
        
        .c-list__content {
            width: 100%;
            align-items: center;
            padding: 15px 0;
            @include sp {
                align-items: baseline;
                padding: 15px 0 0;
            }
            + .c-list__content {
                border-top: 1px solid #32353E;
            }
            &.claimable_refunded {
                position: relative;
                overflow: hidden;
                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #17191F;
                    filter: blur(80px);
                    border-radius: 2px;
                    z-index: 9;
                }
            }
        }
        .c-list__row {
            width: calc(99%/6 - 8px);
            padding: 0;            
            margin: 0;
            @include sp {
                width: calc(100%/3);                
                padding-right: 5px;
                margin-bottom: 15px;
            }
            + .c-list__row {
                margin-left: 10px;
                @include sp {
                    margin-left: 0;
                }
            }
            &:nth-of-type(3), &:nth-of-type(6) {
                span {
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        @include sp {
                            display: block;
                            width: 100%;
                            text-align: right;
                            justify-content: flex-end;
                        }
                    }
                }
            }
            span {
                position: relative;
                &:nth-of-type(1) {
                    display: none;
                    @include sp {
                        text-transform: uppercase;
                        display: initial;
                    }
                }
                &:nth-of-type(2) {
                    margin-top: 0;
                    text-align: center;
                    justify-content: center;
                    @include sp {
                        margin-top: 10px;
                        text-align: left;
                        justify-content: flex-start;
                    }
                }
                &.is-Joined {
                    color: #00BE08;
                }
                &.is-Claim {
                    color: #F67519;
                }
                &.is-Refunded,
                &.is-Finished {
                    color: #74757C;
                }
                &.is-Claimable {
                    color: #FFD60A;
                }
                &.is-missed-claim {
                    color: #F73D1E;
                }
                &.is-selectNetwork {
                    color: #3785FA;
                    font-size: 14px;
                    padding-right: 24px;
                    position: relative;
                    svg {
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        right: 0;
                        top: -5px;
                        cursor: pointer;
                        path {
                            stroke: #71717A;
                        }
                        &:hover {
                            path {
                                stroke: #FFD60A;
                            }
                            + p {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    p {
                        width: 295px;
                        background: #22252F;
                        border: 1px solid #71717A;
                        border-radius: 5px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #C4C4C4;
                        position: absolute;
                        top: calc(100% + 5px);
                        right: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s ease-in-out;
                        z-index: 2;
                    }
                }
            }
        }
        .c-list__ask {
            width: 24px;
            height: 24px;
            position: absolute;
            top: calc(50% - 12px);
            right: -29px;
            cursor: pointer;
            display: none;
            @include sp {
                display: block;
            }
            svg {
                width: 100%;
                path {
                    transition: .3s ease-in-out;
                    &:first-child {
                        stroke: #71717A;
                    }
                    &:last-child {
                        fill: #71717A;
                    }
                }
                &:hover {
                    path {
                        &:first-child {
                            stroke: #FFD60A;
                        }
                        &:last-child {
                            fill: #FFD60A;
                        }
                    }
                    ~ p {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            p {
                width: 333px;
                padding: 5px 10px;
                margin: 0;
                font-size: 16px;
                line-height: 24px;
                color: #C4C4C4;
                background: #22252F;
                border: 1px solid #71717A;
                border-radius: 5px;
                position: absolute;
                top: 34px;
                right: 0;
                transition: .3s ease-in-out;
                opacity: 0;
                visibility: hidden;
                z-index: 2;
                text-transform: none;
                @include sp {
                    width: 300px;
                    right: calc(50% - 150px);
                }
                i {
                    text-decoration: underline;
                }
            }
        }
    }
    .loading {
        height: 60%;
        width: 70%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
    }
}
.mt-15{
    margin-top: 15px !important;
}
.mt-10 {
    margin-top: 10px;
}
.c-statusRow {
    padding: 20px 0 20px calc(20% + 25px);
    background: #22252F;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    @include sp {
        display: none;
    }
    h3 {
        text-align: center;
        font-size: 16px;
        line-height: 1;
        color: #C4C4C4;
        font-weight: normal;
        width: calc(20% - 8px);
        margin: 0;
        .sub_title{
            color:#74757C;
            text-align: center;
        }
        + h3 {
            margin-left: 10px;
        }
        &:last-child {
            @include max(1280px) {
                padding-right: 20px;
            }
        }
        span {
            position: relative;
        }
    }
    &__ask {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -12px;
        right: -29px;
        cursor: pointer;
        @include sp {
            display: none;
        }
        svg {
            width: 100%;
            path {
                transition: .3s ease-in-out;
                &:first-child {
                    stroke: #71717A;
                }
                &:last-child {
                    fill: #71717A;
                }
            }
            &:hover {
                path {
                    &:first-child {
                        stroke: #FFD60A;
                    }
                    &:last-child {
                        fill: #FFD60A;
                    }
                }
                ~ p {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        p {
            width: 333px;
            padding: 5px 10px;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            color: #C4C4C4;
            background: #22252F;
            border: 1px solid #71717A;
            border-radius: 5px;
            position: absolute;
            top: 34px;
            right: 0;
            transition: .3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            z-index: 2;
            text-align: left;
            i {
                text-decoration: underline;
            }
        }
    }
}

.c-listStake {
    background: #22252F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    &.is-grid {
        margin: 0 -15px;
        display: flex;
        flex-wrap: wrap;
        background: transparent;
        box-shadow: none;
        @include sp {
            margin: 0;
        }
        .c-listStake__item {
            width: calc(100%/3 - 30px);
            margin: 0 15px 30px;
            background: #22252F;
            border-radius: 5px;
            padding: 0;
            @include min {
                width: calc(100%/4 - 30px);
            }
            @include max(1280px) {
                width: calc(100%/2 - 30px);
            }
            @include sp {
                width: 100%;
                margin: 0 0 30px;
            }
            + .c-listStake__item {
                border-top: 0;
            }
        }
        .c-listStake__title {
            padding: 15px;
            background: #32353E;
            border-radius: 5px 5px 0px 0px;
        }
        .c-listStake__img {
            margin-right: 25px;
        }
        .c-listStake__body {
            padding: 20px 15px 15px;
            max-height: 100%;
        }
        .c-listStake__cont {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin-bottom: 15px;
            span {
                width: 100px;
            }
            p {
                width: calc(100% - 100px);
                padding: 0;
                margin: 0;
                text-align: right;
            }
        }
        .c-listStake__box {
            display: flex;
            justify-content: space-between;
            padding: 15px 0 0;
            background: transparent;
            border-top: 1px solid #32353E;
            + .c-listStake__box {
                border-top: 0;
            }
        }
        .c-listStake__info {
            background-color: transparent;
            padding: 0;
            width: calc(100% - 130px);
            margin: 0;
            order: 1;
        }
        .c-btn {
            order: 2;
        }
        .c-listStake__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 2;
            .c-btn {
                width: 46px;
                padding: 15px;
                + .c-btn {
                    margin-left: 20px;
                }
            }
        }
        .c-listStake__approve {
            width: 100%;
            padding: 15px 0 0;
            border-top: 1px solid #32353E;
            margin-top: 15px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .c-btn {
                width: 100%;
            }
            a {
                + a {
                    margin-top: 0;
                }
            }
        }
    }
    &__item {
        padding: 25px 25px 0;
        position: relative;
        transition: 0.3s ease-in-out;
        @include tablet {
            padding: 15px 15px 0;
        }
        @include sp {
            padding: 10px 10px 0;
        }
        + .c-listStake__item {
            border-top: 1px solid #32353E;
        }
        &.is-open {
            padding-bottom: 25px;
            @include tablet {
                padding-bottom: 20px;
            }
        }
    }
    &__arrow {
        appearance: none;
        display: block;
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 40px;
        right: 25px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        @include tablet {
            top: 25px;
            right: 15px;
        }
        @include sp {
            right: 10px;
            top: 20px;
        }
        img {
            width: 100%;
        }
        &.is-open {
            transform: rotate(180deg);
        }
    }
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 25px;
        padding-right: 45px;
        @include tablet {
            padding-bottom: 15px;
            padding-right: 40px;
        }
        @include sp {
            padding-bottom: 10px;
            padding-right: 35px;
        }
    }
    &__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        h3 {
            font-size: 20px;
            line-height: 1;
            color: #fff;
            letter-spacing: 0.025em;
            font-weight: normal;
            margin: 0;
            padding: 0;
            @include tablet {
                font-size: 18px;
            }
            span {
                display: flex;
                align-items: flex-end;
                font-size: 16px;
                color: #C4C4C4;
                padding-top: 4px;
                @include tablet {
                    font-size: 14px;
                }
                img {
                    width: 24px;
                    height: 24px;
                    display: block;
                    margin-left: 5px;
                    @include tablet {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    &__img {
        position: relative;
        width: 54px;
        margin-right: 10px;
        span {
            display: block;
            width: 48px;
            height: 48px;
            background: #fff;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            padding: 6px;
            @include tablet {
                width: 45px;
                height: 45px;
            }
            img {
                width: 100%;
                display: block;
            }
            &:nth-of-type(2) {
                width: 22px;
                height: 22px;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
                padding: 0;
            }
            &:nth-of-type(3) {
                width: 22px;
                height: 22px;
                position: absolute;
                bottom: 0;
                right: 11px;
                z-index: 3;
                padding: 0;
            }
        }
    }
    &__cont {
        span {
            display: block;
            color: #74757C;
            font-size: 16px;
            line-height: 1;
            text-align: left;
            @include tablet {
                font-size: 14px;
                text-align: right;
            }
        }
        p {
            font-size: 18px;
            line-height: 1;
            color: #FDF5D3;
            text-align: left;
            margin: 15px 0 0;
            font-weight: bold;
            @include tablet {
                font-size: 16px;
                margin: 10px 0 0;
                text-align: right;
            }
            &.is-earn {
                color: #FFD60A;
            }
        }
    }
    &__body {
        max-height: 0;
        overflow: hidden;
        transition: 0.5s ease-in-out;        
        &.is-open {
            max-height: 300px;
            @include tablet {
                max-height: 400px;
            }
            @include sp {
                max-height: 550px;
            }
        }
    }
    &__box {
        background-color: #32353E;
        padding: 20px 35px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        @include tablet {
            padding: 10px;
        }
    }
    &__approve {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        order: 1;
        width: calc(100%/3 - 20px);
        padding: 10px 20px 10px 0;
        @include tablet {
            width: 100%;
            order: 3;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 15px 0 10px;
        }
        a {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 1;
            color: #FDF5D3;
            transition: .3s ease-in-out;
            &:hover {
                color: #FDF5D3;
                opacity: .7;
            }
            + a {
                margin-top: 15px;
            }
            img {
                display: block;
                margin-left: 10px;
                width: 24px;
                @include tablet {
                    width: 20px;
                }
            }
        }
        .c-btn {
            margin-top: 20px;
            @include tablet {
                width: 100%;
                margin-top: 15px;
            }
        }
        .c-listStake__cont {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            + .c-listStake__cont {
                margin-top: 10px;
            }
        }
        .c-btnWallet {
            @include tablet {
                width: 100%;
            }
        }
    }
    &__info {
        background-color: #191B21;
        border-radius: 5px;
        padding: 20px;
        width: calc(100%/3 - 20px);
        order: 2;
        margin-left: 30px;
        position: relative;
        @include tablet {
            width: calc(50% - 10px);
            margin-left: 0;
            padding: 15px 20px;
        }
        @include sp {
            width: 100%;
            padding: 10px;
        }
        + .c-listStake__info {
            @include tablet {
                margin-left: 20px;
            }
            @include sp {
                margin-left: 0;
                margin-top: 10px;
            }
        }
        p {
            font-size: 16px;
            line-height: 1;
            margin: 0;
            color: #C4C4C4;
            text-align: left;
            @include tablet {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            span {
                color: #fff;
                &:first-child {
                    font-size: 18px;
                    color: #FFD60A;
                    margin-right: 15px;
                    font-weight: bold;
                }
            }
            + p {
                margin-top: 10px;
            }
        }
        .c-btn {
            margin-top: 20px;
            @include tablet {
                width: calc(50% - 5px);
            }
            + .c-btn {
                margin-left: 10px;
            }
            &.is-full {
                @include tablet {
                    width: 100%;
                }
            }
        }
    }
    &__ans {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        svg {
            width: 100%;
            path {
                transition: .3s ease-in-out;
                &:first-child {
                    stroke: #71717A;
                }
                &:last-child {
                    fill: #71717A;
                }
            }
            &:hover {
                path {
                    &:first-child {
                        stroke: #FFD60A;
                    }
                    &:last-child {
                        fill: #FFD60A;
                    }
                }
                ~ p {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        p {
            width: 333px;
            padding: 5px 10px;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            color: #C4C4C4;
            background: #22252F;
            border: 1px solid #71717A;
            border-radius: 5px;
            position: absolute;
            top: 34px;
            right: 0;
            transition: .3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            @include sp {
                width: 265px;
            }
        }
    }
}

.c-listHome {
    display: block;
    padding: 0;
    margin: 0;
    &__item {
        display: flex;
        align-items: center;
        background: linear-gradient(108.81deg, #ECD551 12.71%, #F8F078 87.29%);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 20px 30px;
        @include tablet() {
            padding: 15px 25px;
        }
        &:nth-child(2n) {
            background: #fff;
        }
        + .c-listHome__item {
            margin-top: 28px;
            @include tablet() {
                margin-top: 15px;
            }
        }
    }
    &__number {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 70px;
        span {
            display: block;
            width: 35px;
            height: 35px;
            background: #1A1C1E;
            border-radius: 50%;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 35px;
            text-align: center;
            color: #FFFFFF;
            @include tablet() {
                font-size: 14px;
            }
        }
    }
    &__name {
        width: calc((100% - 70px)/5);
        color: #16181A;
        font-size: 18px;
        line-height: 22px;
        padding-right: 5px;
        @include tablet() {
            width: calc((100% - 70px)/3);
            font-size: 16px;
            line-height: 20px;
        }
        @include sp {
            width: calc((100% - 70px)/2);
            text-align: center;
        }
        span {
            display: block;
            width: 80px;
            border-radius: 5px;
            margin-bottom: 8px;
            overflow: hidden;
            @include sp {
                margin: 5px auto 0;
            }
        }
        img {
            display: block;
            width: 100%;            
            transition: .3s ease-in-out;
        }
        &:hover {
            span {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    &__info {
        width: calc((100% - 70px)/6);
        text-align: center;
        padding: 0 5px;
        @include tablet() {
            width: calc((100% - 70px)/3);
        }
        @include sp {
            width: calc((100% - 70px)/2);
        }
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            color: #16181A;
            margin-bottom: 10px;
            @include tablet() {
                font-size: 14px;
                line-height: 1;
            }
        }
        p {
            color: #181824;
            font-size: 18px;
            line-height: 22px;
            margin: 0;
            font-weight: bold;
            @include tablet() {
                font-size: 16px;
                line-height: 1;
            }
        }
        &.is-roi {
            p {
                &.up {
                    color: #00BE08;
                }
                &.down {
                    color: #F73D1E;
                }
            }
        }
        &.is-date,
        &.is-token,
        &.is-rate {
            @include tablet() {
                display: none;
            }
        }
        &.is-price {
            @include sp {
                display: none;
            }
        }
    }
}

.c-infoIdo {
    
    border-radius: 20px;
    border: 0.7px solid #DEEBED;
    background: #FFF;
    padding: 20px;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

    .view-more {
        font-size: 16px;
        color: #3785FA;
        padding-bottom: 15px;
        display: inline-block;
    }
    &__img {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;

        img {
            display: block;
            width: 100%;
            border-radius: 5px 5px 0px 0px;
        }
    }
    &__status {
        font-size: 13px;
        line-height: 1;
        color: #1A1C1E;
        background: #FFD60A;
        border-radius: 2px;
        padding: 4px 5px 4px 12px;
        position: absolute;
        top: 4px;
        left: 4px;
        text-transform: capitalize;
        &.protected {
                background: #4770C4 !important;
                color: #FFFFFF !important;  
                // img {
                //     width: 10px !important;
                //     height: 10px;
                //     object-fit: contain;
                //     position: absolute;
                //     left: 5px;
                //     top: calc(50% - 5px);
                // }
        
                &:before {
                    content: '';
                    background: #fff;
                }
            }
        &:before {
            content: '';
            width: 4px;
            height: 4px;
            background: #1A1C1E;
            border-radius: 50%;
            position: absolute;
            left: 5px;
            top: calc(50% - 2px);
        }
    }
    &__body {
        padding: 0px;
    }
    &__head {
        
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @include sp {
            font-size: 24px;
            margin-bottom: 15px;
            display: grid;
        }
        
        .ido-name-social{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .ido-name{

                .ido-detail-title{
                    margin-bottom: 15px;
                    display: flex;
                    .ido-main-title{
                        font-size: 28px;
                        line-height: 30px;
                        font-family: AeonikMedium;
                        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        letter-spacing: 0.025em;
                        @include sp {
                            font-size: 20px;
                        }
                    }

                    .ido-symbol{
                        color: #707a8a;
                        margin-left: 10px;
                        font-family: AeonikRegular;
                        font-size: 16px;
                    }
                }
                
            }
            @include sp {
                margin-bottom: 15px;
                justify-content: flex-start;
            }
            
            span {
                width: 72px;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 15px;
        
                @include sp {
                    width: auto;
                    height: auto;
                }
        
                img {
                    width: auto;
                    max-width: 50px;
                    max-height: 100%;
                    height: auto;
                }
            }

            .ido-name{
                padding-top: 5px;
                margin-bottom: 15px;
            }
            .ido-social{
                margin-top: 12px;
            }
        }
    }
    &__text {
        font-size: 16px;
        line-height: 24px;
        color: #3F666B;
        font-family: Aeonik;
        margin-bottom: 10px;
        letter-spacing: 0.32px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include sp {
            font-size: 16px;
            line-height: 21px;
        }
        span {
            color: #C4C4C4;
        }
    }
    &__social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0px;
        li {
            width: 18px;
            + li {
                margin-left: 12px;
            }
            a {
                img {
                    display: block;
                    transition: 0.3s ease-in-out;
                }
                &:hover {
                    img {
                        opacity: .8;
                    }
                }
            }
        }
       
    }
    &__row {
        font-size: 16px;
        line-height: 20px;
        color: #3F666B;
        margin: 0 0 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include sp {
            font-size: 14px;
            margin: 0 0 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            display: block;
            &:first-child {
                //width: 100px;
            }
            &:last-child {
                
                font-size: 18px;
                color: #0A2C31;
                text-transform: capitalize;
                font-family: AeonikRegular;
                text-align: right;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                @include sp {
                    font-size: 16px;
                }
                img {
                    display: block;
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
    &__line {
        display: block;
        width: 100%;
        height: 1px;
        background: #ededed;
        margin-bottom: 15px;
    }
    &__contract {
        background: #EDF3F4;
        position: relative;
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
        min-width: 300px;

        input {
            width: 100%;
            height: 40px;
            appearance: none;
            background: #EDF3F4;
            border: 0;
            padding: 11px 60px 11px 10px;
            font-size: 16px;
            line-height: 18px;
            color: #0A2C31;
            font-family: AeonikRegular;
            border-radius: 5px;
            outline: none;
        }
        img {
            height: 40px;
            padding: 8px 10px;
            appearance: none;
            background: #3F666B;
            border-radius: 5px;
            outline: none;
            border: 0;
            position: absolute;
            top: 0px;
            right: 0;
            cursor: pointer;
        }
    }
}

.c-campaignRound {
    border-radius: 20px;
    
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(24, 84, 92, 0.09);

    + .c-campaignRound {
        margin-top: 20px;
        @include sp {
            margin-top: 15px;
        }
    }
    &.is-open {
        border: 1.5px solid var(--asaasd, #19CA89);
    }
    &__title {
       
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px !important;
        position: relative;
        transition: 0.3s ease-in-out;
        text-align: center;
        

        @include sp {
            padding: 25px 40px 25px 15px;
        }
        h3 {
            color: #0C434B !important;
            text-align: center;
            font-family: AeonikMedium;
            font-size: 28px;
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
            @include min {
                font-size: 22px;
            }
            @include sp {
                font-size: 18px;
            }
        }
        p {
            font-size: 16px;
            line-height: 20px;
            color: #ef4b52;
            text-align: center;
            margin: 0;
            @include min {
                font-size: 18px;
            }
            @include sp {
                font-size: 14px;
            }
        }
        span{
            width: 100%;
            text-align: center !important;
            font-family: AeonikRegular;
        }
    
    }
    &__body {
        overflow: hidden;
        transition: 0.5s ease-in-out;
        height: auto;
        // max-height: 0;
    }
    &__content {
        padding: 15px;
        
        @include sp {
            padding: 20px 15px;
        }
    }
    &__row {
        font-size: 16px;
        line-height: 20px;
        color: #C4C4C4;
        margin: 0 0 15px;
        display: flex;
        flex-wrap: wrap;
        @include min {
            font-size: 18px;
        }
        @include sp {
            font-size: 14px;
            margin: 0 0 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            display: block;
            &:first-child {
                width: 30%;
                color: #3F666B !important;
                font-family: Aeonik !important;
                font-size: 14px !important;

               
                @include sp {
                    width: 50%;
                }
            }
            &:last-child {
                width: 70%;
                font-size: 16px;
                color: #0A2C31;
                font-family: AeonikRegular;
                text-transform: uppercase;
                font-weight: 500;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                
                @include sp {
                    font-size: 16px;
                    width: 50%;
                }
                img {
                    display: block;
                    width: 20px;
                    margin-right: 5px;
                }
            }
            &.is-text {
                text-transform: none;
                text-align: right;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
    &__btn {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0px;
    }
    &__info {
        background: #EDF3F4;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        @include sp {
            padding: 10px;
        }
        p {
            width: calc(100%/3);
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #3F666B;
            padding: 0 0 15px;
            margin: 0;
            @include min {
                font-size: 20px;
            }
            @include sp {
                font-size: 16px;
                width: 50%;
            }
            span {
                display: block;
                font-size: 16px;
                line-height: 20px;
                color: #0A2C31;
                font-family: Aeonik;
                margin-bottom: 5px;
                @include min {
                    font-size: 18px;
                }
                @include sp {
                    font-size: 14px;
                }
            }
        }
    }
    &__table {
        overflow-y: auto;
        max-height: 318px;
        @include sp {
            max-height: 565px;
        }
        &::-webkit-scrollbar {
            width: 2px;
            background-color: #22252F;
        } 
        &::-webkit-scrollbar-thumb {
            background-color: #FFD60A;
            border-radius: 2px;
        }
        table {
            width: 100%;
            background: #191B21;
            border-radius: 5px;
            tr {
                &:last-child {
                    .c-campaignRound__tableSP {
                        border: 0;
                    }
                }
            }
            th,
            td {
                font-size: 16px;
                line-height: 1;
                padding: 15px 10px;
                @include min {
                    font-size: 18px;
                }
                @include sp {
                    font-size: 14px;
                    padding: 0 10px;
                }
            }
            th {
                background: #22252F;
                color: #C4C4C4;
                font-weight: normal;
            }
            td {
                text-align: center;
                color: #FDF5D3;
                border-bottom: 1px solid #22252F;
                @include sp {
                    text-align: left;
                    border-bottom: 0;
                }
                &.action {
                    color: #FFD60A;
                }
                &.nodata {
                    padding: 0;
                }
                span {
                    display: block;
                    + span {
                        margin-top: 5px;
                    }
                }            
            }
        }
    }
    &__tableSP {
        border-bottom: 1px solid #32353E;
        padding: 15px 0;
        p {
            display: flex;
            color: #FDF5D3;
            margin: 0 0 10px;
            span {
                color: #74757C;
                width: 140px;
            }
        }
    }
    &__time {
        padding: 0;
        margin: 15px 0 15px;
        font-size: 16px;
        line-height: 1;
        
        .coundown-text{
            color: #3F666B !important;
            font-family: Aeonik !important;
            font-size: 16px !important;
        }
        .coundown-time{
            width: fit-content;
            margin-top: 10px;
            display: flex;
            margin: auto;
            justify-content: space-around;

            span{
                border-radius: 10px;
                background: #EDF3F4;
                padding: 20px;
                color: #0A2C31;
                text-align: center;
                font-family: AeonikRegular !important;
                font-size: 18px;
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 10px;
            }
        }

        @include min {
            font-size: 18px;
        }
        @include sp {
            font-size: 14px;
        }
    }

    .not-whitelist{
        border-radius: 10px;
        background: #ECEFF4;
        color: #9AB3B6;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-family: Aeonik;
        font-size: 16px;
    }
}
.p-15{
    padding: 15px !important;
}
.bg-darkblue{
    border-radius: 10px;
    background: #EDF3F4;
}
.c-progress {
    margin-top: 25px;
    &__ttl {
        padding: 0;
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 1;
        color: #3F666B;
        font-family: Aeonik !important;
    }
    &__bar {
        width: 100%;
        height: 13px;
        background: #fff;
        border-radius: 20px;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
        span {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 13px;
            background: linear-gradient(270deg, #19CA89 0%, rgba(9, 169, 203, 0.00) 102.21%);
            transition: 0.3s ease-in-out;
            border-radius: 20px;
        }
    }
    &__txt {
        font-family: AeonikMedium;
        font-size: 16px;
        line-height: 1;
        color: #3F666B;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        margin-top: 10px;
        span {
            display: block;
            &:first-child {
                color: #3F666B;
            }
        }
    }
}
.w-100{
    width: 100%;
}
.text-right{
    text-align: right !important;
}
.text-underline{
    text-decoration: underline;
    &:hover{
        cursor: pointer;
    }
}
.pr-10{
    padding-right: 10px;
}
.pt-5{
    padding-top: 5px;
}
.hver{
    cursor: pointer;
}
.font-20{
    font-size: 20px !important;
}
.font-18{
    font-size: 18px !important;
}
.w-fitcontent{
    width: fit-content !important;
}
.w-200{
    min-width: 150px !important;
    max-width: 200px;
}
.mb-15{
    margin-bottom: 15px !important;
}
.p-lr-20{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.text-regular-blue{
    font-family: AeonikRegular;
    font-size: 16px;
    color: #3F666B;
}
.text-medium-blue{
    font-family: AeonikMedium !important;
    color: #3F666B;
}
.ptb-5{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.text-dot{
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.btn-basic{
    width: 100%;
    // height: 44px;
    border: 1px solid transparent;
    background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
    color: #fff;
    border-radius: 15px;
    font-size: 16px;
    line-height: normal;
    font-family: AeonikMedium;
    text-align: center;
    padding: 10px 20px;
    position: relative;
    margin-top: 0px;
    border-left: 0px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    @include sp {
        padding: 10px;
        height: auto;
        font-size: 14px;
    }

    &:disabled {
        opacity: 0.5;
        background: #0EAD98;
        color: #fff;
        text-align: center;
        font-family: AeonikMedium;
        font-size: 16px;
        cursor: not-allowed;
    }
    &:hover{
        opacity: 0.8;
    }
}
.mr-0{
    margin-right: 0px !important;
}
.ml-0{
    margin-left: 0px !important;
}
.node-tag{
    margin-right: 0px !important;
    line-height: normal !important;
    padding-bottom: 2px !important;
    border-radius: 5px !important;

    small{
        line-height: normal !important;
    }
}
.coming-time{
    text-align: center;
    margin: auto !important;

    .time-count{
        margin: auto;
    }
}
.modal-node{
    min-width: 600px;

    @include sp{
        min-width: unset !important;
    }
}
.btn-basic-default{
    margin-left: 5px;
    margin-right: 5px;
    border-left: 0px;
    width: 100%;
    height: 46px;
    border: 1px solid #bdbebe;
    background: #696c6c;
    color: #fff;
    border-radius: 15px;
    font-size: 16px;
    line-height: 1;
    font-family: AeonikMedium;
    text-align: center;
    padding: 10px 20px;
    position: relative;
    margin-top: 0px;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.8;
    }
}
.ant-modal-title{
    color: #0C434B !important;
    text-align: left;
    font-family: AeonikMedium;
    font-size: 22px !important;
}
.modal-btn-group{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    button{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.custom-slt{
    
    .ant-select-selector {
        border-radius: 10px;
        min-height: auto !important;
        height: auto !important;
        background: #EDF3F4 !important;
        border: none !important;
        border-radius: 7px !important;
        padding: 4px 10px !important;

        .ant-select-selection-search-input {
            height: auto !important;
            font-size: 16px;
            font-family: AeonikRegular !important;
        }

        .ant-select-selection-placeholder {
            line-height: 40px !important;
            color: #acafaf !important;
            font-family: AeonikRegular !important;
        }

        .ant-select-selection-item{
            font-size: 14px;
            color: #0C434B !important;
            font-family: AeonikRegular !important;
            text-align: center;
        }
    }
}
.basic-form-nopd{
    .ant-form-item-control-input {
        padding-left: 0px !important;
    }
}
.basic-form{
    .ant-form-item-label label{
        font-size: 15px !important;
        font-family: AeonikRegular;
        color: #787878
    }
    .ant-input-prefix{
        margin-right: 5px !important;
        margin-left: 8px !important;
    }
    .ant-input-affix-wrapper{
        border: 1px solid #dfdfdf;
        padding: 0px;
        padding-right: 7px;
        border-radius: 10px;

        .ant-input-suffix{
            color: #0C434B !important;
            font-family: AeonikMedium;
        }
    }
    .ant-form-item-control-input{
        padding-left: 15px;
    }
    .ant-input{
        border-radius: 10px;
        min-height: 45px;
        font-size: 16px;
        background: #ffffff;
        font-family: AeonikRegular;
        padding-left: 10px !important;
        box-shadow: none !important;
        border: 1px solid #dfdfdf;
        color: rgba(37, 48, 50, 0.885) !important;
    }
    .ant-input-number-input{
        border-radius: 10px;
            min-height: 45px;
            font-size: 16px;
            background: #EDF3F4;
            font-family: AeonikRegular;
            padding-left: 10px !important;
            box-shadow: none !important;
            border: none;
            color: rgba(63, 102, 107, 0.70) !important;
    }
    .ant-select-selector{
        border-radius: 10px;
        min-height: 45px;
        background: #EDF3F4 !important;
        border: none !important;
        border-radius: 10px !important;

        .ant-select-selection-search-input{
            height: 45px !important;
            font-size: 16px;
        }
        .ant-select-selection-placeholder{
            line-height: 40px !important;
            color: #acafaf !important;
            font-family: AeonikRegular !important;
        }
    }
    .ant-input-status-error{
        background: #fff;
        border: 1px solid #ff4d4f !important;
    }
    .ant-radio-wrapper{
        font-family: AeonikRegular;
        font-size: 16px;
    }
}
.ant-picker{
    width: 100%;
    border-radius: 8px !important;
    height: 45px;
}
.c-commit {
    margin: 0px 0 0px;
    
    &__form {
        position: relative;
        input {
            appearance: none;
            -moz-appearance: textfield;
            width: 100%;
            height: 46px;
            border-radius: 10px;
            border: 1px solid #CADADD;
            background: #EDF3F4;
            padding: 12.75px 142px 12.75px 15px;
            line-height: 1;
            color: rgba(63, 102, 107, 0.70);
            font-size: 18px;

            &::placeholder {
                color: rgba(63, 102, 107, 0.70);
            }
            &:focus-visible {
                outline: none;
                border: 1px solid #CADADD;
                box-shadow: 4px 4px 4px rgba(255, 214, 10, 0.2);
                ~ p {
                    opacity: 1;
                }
                // ~ button {
                //     &:disabled {
                //         border-top: 1px solid #FFD60A;
                //         border-right: 1px solid #FFD60A;
                //         border-bottom: 1px solid #FFD60A;
                //     }
                // }
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        button {
            appearance: none;
            width: 100%;
            height: 46px;
            border: 1px solid #0EAD98;
            background: #0EAD98;
            color: #fff;
            border-radius: 15px;
            font-size: 16px;
            line-height: 1;
            font-family: AeonikMedium;
            text-align: center;
            padding: 10px 20px;
            position: relative;
            margin-top: 15px !important;
            cursor: pointer;
            &:disabled {
                opacity: 0.5;
                background: #0EAD98;
                color: rgb(72, 72, 72);
                text-align: center;
                font-family: AeonikMedium;
                font-size: 16px;
                cursor: not-allowed;
            }
        }
        p {
            font-size: 14px;
            line-height: 20px;
            color: #FF0000;
            margin: 2px 0 0;
            transition: 0.2s ease-in-out;
            opacity: 0;
        }
    }
    &__mess {
        width: 100%;
        max-width: 600px;
        border-radius: 3px;
        padding: 20px 51px 20px 25px;
        // position: fixed;
        // top: 25px;
        // right: 25px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 22px;
        z-index: 9999;
        &.is-error {
            color: #B3302E;
            background: #ECC8C5;
            border: 1px solid #B3302E;
            span {
                &:before,
                &:after {
                    background: rgba(179, 48, 46, 0.5);
                }
            }
        }
        &.is-done {
            color: #5A7052;
            background: #DEF2D6;
            border: 1px solid #5A7052;
            span {
                &:before,
                &:after {
                    background: rgba(90, 112, 82, 0.5);
                }
            }
        }
        img {
            width: 32px;
            display: block;
            margin-right: 25px;
        }
        span {
            display: block;
            width: 26px;
            height: 26px;
            cursor: pointer;
            position: absolute;
            top: calc(50% - 13px);
            right: 25px;
            overflow: hidden;
            &:before,
            &:after {
                content: '';
                width: 26px;
                height: 2px;
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% - 13px);
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
.bg-danger {
    background: rgb(241, 67, 67) !important;
    color: #fff;
}
.rnc__base {
    right: 0;
    top: 0;
    .rnc__notification-container--top-right {
        width: 80%;
        max-width: 600px;
    }
    .rnc__notification {
        width: 100% !important;
        
        &-item {
            width: 100%;
            font-family: AeonikRegular;
            text-align: center;
            &--danger,
            &--warning {
                background: #f0c4c0;
                border: 1px solid transparent;
                border-radius: 10px;
                position: relative;
                &::before {
                    content: '';
                    width: 32px;
                    height: 32px;
                    background: url(../images/error_icon.svg) no-repeat center;
                    background-size: cover;
                    position: absolute;
                    left: 25px;
                    top: calc(50% - 16px);
                }
                .rnc__notification-message {
                    color: #B3302E;
                }
                .rnc__notification-timer {
                    background-color: #ECC8C5 !important;
                    &-filler {
                        background-color: #B3302E !important;
                    }
                }
            }
            &--success {
                background: #d6f2de;
                border: 1px solid transparent;
                border-radius: 10px;
                position: relative;
                &::before {
                    content: '';
                    width: 32px;
                    height: 32px;
                    background: url(../images/success_icon.svg) no-repeat center;
                    background-size: cover;
                    position: absolute;
                    left: 25px;
                    top: calc(50% - 16px);
                }
                .rnc__notification-message {
                    color: #2d8a58;
                }
                .rnc__notification-timer {
                    background-color: #DEF2D6 !important;
                    &-filler {
                        background-color: #5A7052 !important;
                    }
                }
            }
        }
        &-content {
            padding: 20px 25px 20px 70px;
        }
        &-message {
            font-size: 18px;
            line-height: 22px;
        }
        &-timer {
            width: calc(100% - 50px);
            position: absolute;
            bottom: 5px;
            left: 25px;            
        }
    }
}
.c-notifyComponent {
    width: 100%;
    max-width: 600px;
    border-radius: 3px;
    padding: 20px 51px 20px 25px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    z-index: 9999;
    &.is-error,
    &.danger {
        color: #B3302E;
        background: #ECC8C5;
        border: 1px solid #B3302E;
        span {
            &:before,
            &:after {
                background: rgba(179, 48, 46, 0.5);
            }
        }
    }
    &.warning {
        color: #B3302E;
        background: #ECC8C5;
        border: 1px solid #B3302E;
        span {
            &:before,
            &:after {
                background: rgba(179, 48, 46, 0.5);
            }
        }
    }
    &.is-done,
    &.success {
        color: #5A7052;
        background: #DEF2D6;
        border: 1px solid #5A7052;
        span {
            &:before,
            &:after {
                background: rgba(90, 112, 82, 0.5);
            }
        }
    }
    img {
        width: 32px;
        display: block;
        margin-right: 25px;
    }
    span {
        display: block;
        width: 26px;
        height: 26px;
        cursor: pointer;
        position: absolute;
        top: calc(50% - 13px);
        right: 25px;
        overflow: hidden;
        &:before,
        &:after {
            content: '';
            width: 26px;
            height: 2px;
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 13px);
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

 
.c-inodetail{
    display: flex;
    padding-top: 15px;
    @include sp{
        display: block;
    }
    &__main_title{
        font-size: 25px;
        letter-spacing: 2.5%; 
        color: #0C434B !important;
        font-family: AeonikMedium;
        @include sp{
            font-size: 23px;
        }
    }
    &__left{
        width: 40%; 
        @include sp{
            width: 100%;  
        }
        .slick-slider {
            margin-top: 20px;
            margin-left: 20px;  
            @include sp{  
                display: flex;
                flex-direction: row;
                margin-top: 0px;
                margin-left: 0px; 
                align-items: flex-end;
                width: 100%;
            }
            .up-arrow{
                margin-left: 52px;
                cursor: pointer; 
                @include tablet{ 
                    margin-top: 0px;
                    margin-left: 30px;  
                    img{ 
                        height: 25px;
                        width: 25px; 
                    }
                }
                @include sp{   
                    margin-left: 0px;  
                    margin-top: 0px; 
                    margin-bottom: 10px;
                    img{
                        transform: rotate(-90deg);
                        height: 25px;
                        width: 25px; 
                    }
                }
               
            } 
            .down-arrow{ 
                cursor: pointer;  
                margin-left: 52px;
                img{ 
                    transform: rotate(180deg);
                }
                @include tablet{ 
                    margin-top: 10px;
                    margin-left: 30px; 
                    img{ 
                        transform: rotate(180deg);
                        height: 25px;
                        width: 25px; 
                    } 
                }
                @include sp{  
                    margin-top: 0px;
                    margin-left: 0px; 
                    margin-bottom: 10px;  
                    img{ 
                        transform: rotate(90deg);
                        height: 25px;
                        width: 25px; 
                    } 
                }
            }   
        } 
       
    }
    &__right {
        width: 60%;
        padding: 20px;
        background: #ffffffd4;
        border-radius: 10px;
        @include sp{ 
            width: 100%;
        }
        .flex{
            display: flex; 
            align-items: center;
            &.jc-between{
                justify-content: space-between;
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid #9db0b6;
            }
        }   
        .sub_title{
            font-size: 16px;
            letter-spacing: 3%;
            text-transform: uppercase;
            color: #3F666B;
            font-family: Aeonik;
            margin-right: 20px;
            @include tablet{
                font-size: 12px;
            }
            @include sp{
                font-size: 10px;
                width: 50%;
            } 
        }
        .count{
            color: #3F666B;
            font-family: AeonikRegular;
            letter-spacing: 0.3em; 
            font-weight: 500;
            font-size: 18px;
            
            @include sp{
                width: 50%;
            }
        } 
    }
    &__info{
        display: flex;
        justify-content: space-between;
        padding: 25px 0px;
        align-items: center;
        border-bottom: 1px solid #32353E; 
        margin-bottom: 10px;
        @include sp{
            padding: 15px 0px 10px 0px;
        }
    }
    &__projectinfo{
        margin-top: 80px; 
        @include tablet { 
            margin-bottom: 80px;
        }
        @include sp {  
            margin-bottom: 80px;
        }
    }
    &__price{
        padding:10px 20px;
        background: #22252F;
        border-radius: 5px; 
        span{
            color: #3F666B;
            font-family: AeonikRegular;
            @include sp{
                font-size: 16px;
            }
        }  
    }
    &__network{
        display: flex;
        color:#74757C; 
        align-items: center;
        span{
            margin-right: 15px;
            font-weight: 400;
            font-size: 16px; 
            @include sp{
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
    &__unit{
        display: flex;
        align-items: center;
        img{
            display: block;
            width: 100%;
            object-fit: contain;
            margin-right: 5px;
        }
        span{ 
            color: #3F666B;
            font-family: AeonikRegular !important;
            font-size: 16px !important;
            margin-left: 5px;
            @include sp{
                font-size: 12px;
            }
        }
    }
    &__staking{
        margin-top: 15px; 
        span{
            font-size: 16px;
            font-weight: 400;
            color: #C4C4C4;  
        @include tablet{
            font-size: 12px;
        }
        }
       
    }
}
.c-darkMore {
    label {
        display: block;
        input {
            display: none;
            &:checked {
                + .c-darkMore__toggle {
                    &:before {
                        transform: translateX(-29.5px);
                    }
                    .c-darkMore__light {
                        svg {
                            path {
                                stroke: #1A1C1E;
                            }
                        }
                    }
                    .c-darkMore__dark {
                        svg {
                            path {
                                stroke: #C4C4C4;
                            }
                        }
                    }
                }
            }
        }
    }    
    &__toggle {
        display: block;
        background: #32353E;
        border-radius: 30px;
        width: 60px;
        height: 30px;
        position: relative;
        cursor: pointer;
        &::before {
            content: '';
            width: 25px;
            height: 25px;
            background: #FFD60A;
            border-radius: 50%;
            position: absolute;
            right: 2.5px;
            top: calc(50% - 12.5px);
            transition: .3s ease-in-out;
        }        
    }
    &__light,
    &__dark {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 12.5px);
        svg {
            path {
                transition: .3s ease-in-out;
            }
        }
    }
    &__light {
        left: 2.5px;
        svg {
            path {
                stroke: #C4C4C4;
            }
        }
    }
    &__dark {
        right: 2.5px;
        svg {
            path {
                stroke: #1A1C1E;
            }
        }
    }
}

.c-nodata {
    width: 100%;
    padding: 30px;
    text-align: center;
    p {
        font-size: 16px;
        line-height: 1;
        color: #74757C;
        text-align: center;
        margin: 10px 0 0;
        letter-spacing: 0.025em;
    }
    &--airdrop {
        p {
            font-size: 20px;
            line-height: 24px;
        }
    }
    &--upcoming {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            width: 100%;
            max-width: 292px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.025em;
            color: #2bb3a0;
            border-radius: 5px;
            font-family: AeonikRegular;
            margin-top: 16px;
            border: 3px solid #3BD5C1;
            padding: 16px;
            span {
                display: block;
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                margin-top: 4px;
            }
        }
    }

}

.c-bgSP {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    opacity: 0;
    animation: opacity .3s forwards ease-in-out;
}

.c-sort {
    width: 155px;
    margin-left: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    &__sort {
        padding: 0 26px 0 0;
        font-size: 16px;
        line-height: 1;
        color: #74757C;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            width: 16px;
            height: 8px;
            background: url(../images/arrow01.png) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 0;
            top: calc(50% - 4px);
            transition: 0.3s ease-in-out;
        }
        &.is-sort {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    &__by {
        width: 100%;
        position: absolute;
        left: 0;
        top: calc(100% + 10px);
        z-index: 1000;
        border-radius: 2px;
        margin: 0;
        max-height: 0;
        transition: .3s ease-in-out;
        overflow: hidden;
        @include sp {
            position: fixed;
            bottom: 0;
            top: auto;
        }
        &.is-sort {
            max-height: 92px;
            @include sp {
                max-height: 170px;
            }
        }
        li {
            font-size: 16px;
            line-height: 1;
            padding: 15px 10px;
            color: #74757C;
            background: #22252F;            
            transition: .3s ease-in-out;
            cursor: pointer;
            @include sp {
                padding: 20px 25px;
            }
            &:hover {
                background: #FDF5D3;
                color: #1A1C1E;
            }
            &.is-close {
                background: #32353E;
                padding: 20px 60px 20px 25px;
                font-size: 18px;
                line-height: 1;
                color: #FDFCFF;
                position: relative;
                span {
                    display: block;
                    width: 28px;
                    height: 28px;
                    padding: 2px;
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 14px);
                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.pani-list {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: end;
    .ant-pagination {
         
        .ant-pagination-item{ 
            background: transparent;
            border:none;
            a{
                color:white
            }  
        }
        .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
            background: #32353E;
            border: 0px;
            color:#FFFFFF;
        }
        .ant-pagination-item-active {
            // background: rgba(50,53,62,0.2) !important;
            border: 1px solid #32353E;
            border-radius: 3px;
        } 
    } 
}



.c-progress-ino{
    position: relative; 
    width: 100%;
    margin-bottom: 30px;
    height: 42px;
    margin-top: 20px;
    &__icon{  
        background: #32353E;
        border-radius: 5px;
        width: 42px;
        height: 42px; 
        position: absolute;
        transform: rotate(45deg); 
    } 
    p{
        color: #3F666B;
        font-family: AeonikRegular !important;
        font-size: 16px !important;
        width: fit-content;
        margin-left: auto;
        @include sp{
           font-size: 14px;
        }
    }
    img{
        position: absolute;
        z-index: 1; 
        top:8px;
        left: 8px; 
    }
    &__barIno{
        width: calc(100% - 30px);
        height: 18px;
        position:absolute;
        // z-index: -1;
        background: #cfdddf;
        transform: skewX(-50deg);
        top: 30px;
        left: 30px;
        @include sp{
            height: 10px;
        }
        &.running{
            background: #FFD60A; 
        }
    }
}
.customWallet{ 
    width: 294px; 
    height: 44px;
    margin-top: 30px;
    .c-btnWallet{
        width: 100%;
        height: 100%;
        padding:10px 20px
    }
}

 
.ant-select-item-option-content { 
    color:#8c9193;
    font-weight: 500 !important;
    font-family: AeonikMedium !important;
}
 
.ant-select-item-option-active:not(.ant-select-item-option-disabled)
{
    background-color : #EDF3F4 !important; 
    .ant-select-item-option-content{
        color: #3F666B;
        font-family: AeonikMedium !important;
    }
   
}
 
.c-project{
     margin-top: 20px;
     background: #22252F;
    
     &-header{
        display: flex; 
        align-items: center;
     }  
     .ant-select.c-selectChangeTab{ 
        width: 100%;
        margin-bottom: 10px;
        border-bottom:  1px solid #32353E;
        padding:10px 0px;
        
        .ant-select-selector{ 
            border:0px;
            background-color: transparent;
            .ant-select-selection-item{
                color:#FFD60A;
                text-align: center;
                font-size: 18px;
            }
            
        }
        .ant-select-arrow{
            color:#71717A;
            margin-right: 30px;
        }
     }
     .itemheader {  
        border: 1px solid #cae0e4;
        width: 25%;
        align-self: center; 
        display: flex;
        justify-content: center; 
        padding:10px;
        cursor: pointer;
        span{
            color:#74757C
        }
        &.active{
            background:#cfdddf;
            span {  
                color: #0A2C31;
                text-align: right;
                font-family: AeonikRegular;
                font-size: 16px;
            }
        }  
     }
     .detail{
        padding:25px;
        background:#22252F ;
        @include sp {
            padding:15px
        }
        .flex{
            display: flex;
            align-items: center;
            &.jc-between{
                justify-content: space-between;
                margin-bottom: 20px;
            }
            span{
                font-weight: 400;
                color:#FFFFFF
            }
        } 
        span{ 
            color:#C4C4C4
        }
        .c-footer{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center; 
            margin: 0;
            margin-top: 22px;
            svg{
                margin-right:20px;
                width: 20px;
                height: 20px;
                fill: #4770C4;
            }
        }
     }
}
 
.tab-rule{
    display: flex;
    padding: 20px 15px;
    line-height: 24px;
    @include sp {
        flex-direction: column;
    }
    ul{
        margin-bottom: 0;
    }
    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
            width: 50%;
            color: #0A2C31;
            font-family: AeonikRegular;
            padding: 0 5px;
            @include sp{
                width: 100%;
            }
        }
    }
    &__left{
        width: 50%;
        @include sp{
            width: 100%;
        }
        li{
            color:#C4C4C4
        }
    }
    &__right{
        width: 50%;
        @include sp{
            width: 100%;
        }
        li{
            color:#C4C4C4
        }
    }
}
.tab-whatinbox{
    display: flex;
    padding:20px;
    @include sp{
        flex-direction: column;
    }
    ul{
        margin-bottom: 0;
    }
    &__left{
        width: 50%;
        line-height: 24px;
        @include sp{
            width: 100%;
        }
        li{
            width: 60%;
            display:flex;
            justify-content:space-between;
            @include sp{
                width: 100%;
                margin-bottom: 5px;
            }
            span{
                color:#C4C4C4;
                &.percent{
                    color:#FDF5D3
                } 
            } 
        }
    }
    &__right{
        width: 50%;
        line-height: 24px;
        @include sp{
            width: 100%;
        }
        li{
            width: 60%;
            display:flex;
            justify-content:space-between;
            @include sp{
                width: 100%;
                margin-bottom: 5px;
            }
            span{
                color:#C4C4C4;
                &.percent{
                    color:#FDF5D3
                } 
            } 
        }
    }
    .c-whatBox {
        display: block;
        width: 100%;
        margin: 0;
        &__head {
            display: flex;
            background: #cfdddf;
            padding: 15px 5px;
            p {
                width: 20%;
                font-size: 16px;
                line-height: 1;
                color: #3F666B;
                font-family: AeonikRegular;
                text-align: center;
                margin: 0;
                &.is-des {
                    width: 40%;
                }
            }
        }
        &__item {
            display: flex;
            align-items: center;
            padding: 10px 0;
            background: #ffffff;
            @include sp {
                display: block;
                padding: 8px 15px;
            }
        
            p {
                width: 20%;
                color: #3F666B;
                font-family: AeonikRegular;
                font-size: 16px;
                line-height: 20px;
                margin: 0;
                padding: 0 5px;
                @include sp {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 0;
                }
                + p {
                    @include sp {
                        margin-top: 10px;
                    }
                }
                span {
                    &:first-child {
                        @include sp {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #74757C;
                        }
                    }
                }
                &.is-des {
                    width: 40%;
                    display: flex;
                    flex-wrap: wrap;
                    @include tablet() {
                        display: block;
                        text-align: center;
                    }
                    @include sp {
                        display: flex;
                        width: 100%;
                    }
                    &.is-read {
                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                    span {
                        &.is-readMore {
                            color: #FFD60A;
                            font-size: 16px;
                            line-height: 20px;
                            text-decoration: underline;
                            cursor: pointer;
                            @include sp{
                                font-size: 14px;
                            }
                        }
                    }
                }
                &.is-color {
                    color: #FDF5D3;
                    text-align: center; 
                }
                &.is-name {
                    display: flex;
                    align-items: center;
                    @include tablet() {
                        display: block;
                        text-align: center;
                    }
                    @include sp {
                        display: flex;
                        justify-content: flex-start;
                        text-align: left;
                    }
                    img {
                        display: block;
                        width: 64px;
                        margin-right: 8px;
                        @include tablet() {
                            margin: 6px auto 0;
                        }
                        @include sp {
                            margin: 0 8px 0 0;
                            width: 40px;
                        }
                    }
                }
            }
        }
    }
}

.c-timeLine {
    list-style-type: none;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #22252F;
    border-radius: 2px;
    margin: 0;
    padding: 20px;
    @include tablet() {
        padding: 15px 25px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    &__item {
        width: 25%;
        background: #191B21;
        padding: 25px 10px;
        @include tablet() {
            width: calc(100%/3);
            padding: 20px 10px;
        }
        @include sp {
            width: 100%;
        }
        &:nth-child(2n) {
            background: #32353E;
        }
        span {
            display: block;
            text-align: center;
            padding-bottom: 42px;
            @include tablet() {
                padding-bottom: 20px;
            }
            img {
                display: block;
                width: 48px;
                margin: 0 auto;
            }
        }
        h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #C4C4C4;
            margin-bottom: 10px;
            text-transform: uppercase;
            @include tablet() {
                font-size: 16px;
                line-height: 20px;
            }
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #C4C4C4;
            margin: 0;
            @include tablet() {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.btn-buynowMobile{ 
   width:calc(100% + 50px) ;
   padding: 20px 50px; 
   background: #22252F;  
    z-index: 1000;
   @include tablet{
    margin-left:-25px;
    margin-top:10px; 
    position: fixed;
    left:0;
    top:90%;
   }
   @include sp{
    padding:10px 20px;
    margin-top: 10px; 
    margin-left:0px;
    width :100%
   }
   button {
    background:#FFD60A;
    padding: 13px;
    width: 60%;
    border-radius: 10px; 
    margin: auto;
    margin-left: 50%;
    transform: translate(-50%, 0);
    border:0px;
    @include tablet{
        padding:15px;
    }
    @include sp { 
        padding:10px;
    }
   }
}
.tab-myorder_border{ 
    padding: 20px;
    @include sp{
        padding:10px
    }
    .tab-myorder{
        margin-bottom: 10px;  
        padding:30px 20px; 
        width: 30%; 
        background: #cfdddf;
        border-radius: 5px;
        @include sp{
            width: 100%;
            padding:15px; 
        }
        .title{
            color: #3F666B;
            font-family: AeonikRegular;
           font-size: 18px;
           font-weight: 400;
           margin-bottom: 10px;
            @include tablet{
            font-size: 16px;
            }
        }
        .total-box{
            display: flex; 
            justify-content: space-between;
            align-items: center; 
            .text-total{
                color: #3F666B;
                font-family: AeonikMedium;
                font-size: 24px;
                font-weight: 600; 
                @include tablet{
                    font-size: 14px;
                }
            } 
            
        }
    } 
}
 
 
.form-transfer{ 
    .c-popup__head{
        @include sp {
            font-size: 14px;
            padding:12px 20px
        }
    }
    .c-popup__body{
        @include sp {  
            padding:12px
        }
    }
    @include sp{
        .ant-col-offset-8{
            margin-left: 0%; 
        }
    } 
    .header-title{ 
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #C4C4C4;
        margin-top: 20px;
        @include sp {
            font-size: 14px;
            margin-top: 10px;
        }
    }
    
    .ant-form-item{
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .ant-form-horizontal .ant-form-item-control{
        max-width: 100%;
        
        .input{
            width:100%;
            padding:18px;
            background: #32353E; 
            border-radius: 5px;
            color:#fff;
            font-size:18px; 
            border:1px solid transparent;
            &:focus{
                border: 1px solid #FFD60A;
                outline: none;
            } 
            @include sp {
                padding:10px;
                font-size:12px; 
            }
        } 
            .c-selectChange{ 
                width: 100%;  
                height: 64px;
                @include sp {
                    height:42px
                }
                .ant-select-arrow{
                    color:#fff
                }
                .ant-select-selector{
                    background: #32353E;
                    border:0px;
                    border-radius: 5px;  
                    height: 100%;
                    align-items: center;
                    &:focus{
                        border: 1px solid #FFD60A;
                        outline: none;
                    } 
                    
                    .ant-select-selection-item{
                        img{
                            margin-right: 10px; 
                        }
                        
                        color:#C4C4C4; 
                        font-size: 18px;
                        @include sp{
                            font-size: 12px;
                        }
                    } 
                } 
            } 
    }
    .available{
        margin-top: 5px;
        color:#C4C4C4;
        font-weight: 400;
        text-align: end;
        display: block;
        @include sp{
            font-size: 12px;
        }
    }
    &__input{
        position: relative;
        .BoxMax{
            display: flex;
            position: absolute;
            align-items: center; 
            top:0%;
            right: 0%;
           
            .box{
                right:0;
                color: #74757C;
                font-size: 18px;
                font-weight: 400;
                margin-right: 20px;
                @include sp {
                    font-size: 12px;
                    margin-right: 10px;
                }
            }
            .max{
               padding:12px 25px; 
               border-radius: 5px;
               background: #FFD60A; 
               margin: 10px; 
               border: 0px;
               @include sp {
                font-size: 12px;
                padding:10px 20px;
                margin:0
                }
            }
        }
    }
    &__btn{
        @include sp {
            display: flex;
            justify-content: center;
        }
        .ant-btn{
            &.cancel{
                color:#C4C4C4;
                background: transparent;
                border:1px solid #C4C4C4; 
                margin-left:25px;
                border-radius: 8px;
                padding:10px 25px ;
                height: 46px; 
                @include sp { 
                    height: auto;
                    font-size: 12px;
                    padding:10px 20px ;
                    margin-left:20px;
                }
            }
            &.submit{
                color:#1A1C1E;
                border:1px solid transparent; 
                background:#FFD60A;
                border-radius: 8px;
                height: 46px; 
                padding:10px 22px ;
                @include sp { 
                    height: auto;
                    font-size: 12px;
                    padding:10px 18px ;
                }
            }
        } 
    }
}


.btn-transfer{
    background:#FFD60A;
    border-radius: 10px;
    padding:12px 30px;
    border:0px;
    @include tablet{
        padding:12px 20px;  
    }
    span{
        color:#1A1C1E;
        font-size: 16px; 
        @include tablet{
            font-size: 14px;  
        }
    }

}

.list-myorder{ 
    display: flex;
    background-color: #32353E;
    align-items: center; 
    border-radius: 5px; 
    margin-top: 10px;
    @include sp{
        flex-wrap: wrap;
    }
    .myorder__item{
        display: flex;
        flex-direction: column;
        width: calc(100%/3);
        padding:10px; 
        border-right:1px solid #71717A;
        align-items: center;
        margin:10px;
        @include sp{
            font-size: 10px;
            width: 50%; 
            border:0px;
            margin: 0px;
            padding:5px;
        }
        &:last-child{
            border-right:0px
        }
        &.is-box {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            text-align: center;
            color: #FDF5D3;
            @include sp {
                width: 100%;
            }
            img {
                display: block;
                width: 60px;
                margin-right: 7px;
            }
        }
        .title{
            color:#74757C;
            font-size: 16px;
            font-weight: 400;
            @include sp{
                font-size: 12px;
             }
        }
        span{
           color:#FDF5D3; 
        }
    }
}


@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    50% { transform: translate(5px, 5px) rotate(5deg) } 
    100% { transform: translate(1px, 1px) rotate(0deg) } 
  }

  @keyframes shakesmall {
    0% { transform: translate(3px, 1px) rotate(0deg); }
    50% { transform: translate(3px, 1px) rotate(8deg); } 
    100% { transform: translate(3px, 1px) rotate(0deg); } 
  }


.slick_custom
{
    display: flex;  
    justify-content: center; 
    @include sp{
        flex-direction: column-reverse;
    }
    .dots-slick{ 
    .slick-current{ 
            .item-dots{
                .bg-img-dots{
                    background:  linear-gradient(90deg, #FCEABB 0%, #F8B500 100%);
                    transform: rotate(70deg);
                }
            }  
        } 
    }
    .dots-slick{ 
        width: 30%; 
        align-items: center;  
            @include sp{
                width: 100%;
            } 
            .slick-list{  
                margin: 10px;
                .slick-track{
                    height: 100% !important;
                }
                @include sp{
                    width: 100%; 
                    height: 100%; 
                    margin-left: 20px;
                }  
                img{ 
                    animation: shakesmall 3s infinite;
                    @include tablet{
                        width: 230px; 
                        height: 230px; 
                    }
                    @include sp{
                        width: 230px; 
                        height: 230px;
                        margin-bottom: 95px;
                    }
                    
                }  
        } 
        .slick-active{
            .bg-img-dots{
            background: linear-gradient(90deg, #FCEABB 0%, #F8B500 100%);
            }
        }
        .item-dots{
            position: relative; 
            margin:10px 20px; 
            height: 85px;
            &:hover{
                .bg-img-dots{
                    background: linear-gradient(90deg, #FCEABB 0%, #F8B500 100%);
                    @include sp{
                        background: #32353E;
                    }
                } 
            }
            @include tablet{
                margin-top: 5px;
                margin-left: 5px;
                margin-bottom: 15px;
                height: 50px;
            } 
            img{
                width: 65px;
                height: 65px; 
                margin-left: 5px;
                @include tablet{
                    width: 35px;
                    height: 35px; 
                    margin-left: 5px;
                    margin-top: 5px;
                }
            }
           
            .bg-img-dots{ 
                background: #32353E;
                border-radius: 20px;
                width: 80px;
                height: 80px;  
                transform: rotate(45deg);
                top:0; 
                z-index: -1;
                position: absolute;
                @include tablet{
                    width: 50px;
                    height: 50px;
                }
            }
           
        }
        
        
    }
    .img-slick{
        width: 100%;
        margin: auto;
        .slick-list{ 
            @include sp{
                height: 100%;
                width: 100%;
            }
            @include tablet{
                height: 100%;
                width: 100%;
            }   
        }
        .slick-active{
            .item-img{ 
                margin-top: 20px;
                img{
                    animation: shake 3s infinite;
                    width: 80%;
                    height: 80%; 
                    margin: auto;
                    @include tablet{
                        width: 100%;
                        height: 100%;  
                    }
                    @include sp {
                        margin:auto
                    }
                }
               
            }
        }
        
    }
   
}

@keyframes width {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
.main-text-inoType{
    color: #3F666B;
    font-family: AeonikRegular !important;
    font-size: 16px !important;
}
.c-inoType{
    display: flex;
    flex-wrap: wrap; 
    margin-top: 15px;  
    &-item{ 
        display: flex; 
        width: 31%;
        align-items: center;
        padding:15px;
        background:#EDF3F4;
        border-radius: 5px;
        margin:0 10px 10px 0; 
        border:0px;
        &.is_soldout{
            background: #7b9498; 
            border: 1px solid #FFD60A;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px; 
            position: relative;
            opacity: 0.8; 
        }
        &.disable {
            background:#cfdddf;
            .name {
                color: #3F666B !important;
                font-family: AeonikRegular !important;
            }
        }
        &.active {
            background:#EDF3F4;
            border: 2px solid #94b6bb;
            // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            .name{
                color:#3F666B !important;
                font-family: Aeonik !important;
            }
        }
        @include tablet{
            width: 100%;
            margin-right: 0px;
            padding:5px;
        }
        @include sp{
            width: 100%;
            padding:5px;
            margin:0 0px 5px 0; 
        }
        // &:hover{
        //     background: #fdf5d3;
        //     transition: 0.5s;
        //     cursor: pointer;
        //     .name{
        //         color:#000
        //     }
        //     .total{
        //         color:#000
        //     }
        // }
        img{
            width: 50px;
            height: 50px; 
            @include sp{
                width: 30px;
                height: 30px;
            }
        } 
        &-nameTotal{
            display: flex;
            flex-direction: column; 
            margin: auto;
            .name{
                color: #3F666B;
                font-family: AeonikRegular;
                font-size: 18px;
                @include tablet{
                    font-size: 16px;
                }
                @include sp{
                    font-size: 14px;
                }
            }
            .total{
                color: #3F666B;
                font-family: AeonikRegular;
                font-size: 14px;
                @include tablet{
                    font-size: 12px;
                }
                @include sp{
                    font-size: 10px;
                }
            }
        }
    } 
}

.p-airdroplist{
    &__box {
        position: relative;
    }
    &__head{
        display: flex; 
        align-items: center;
        @include tablet{
            display: block;
        }
       
        // .mainText{
        //     width: 50%;
        //     h3{
        //         width: 100%;
        //     }
        //     @include tablet{
        //         width: 100%;
        //         margin-bottom: 20px;
        //     }
        // }
        & .airdrop-tool{
            display: flex;
            align-items: center;
            button{
                background: transparent;
                border: 0px;
            }
            @include sp{ 
                display: block; 
                //margin-bottom: 20px;
            }
            // .p-airdrop__search{
            //     margin-left: 55px; 
            //     position: absolute;
            //     top:-130px;  
            //     right: 0;
            //     @include tablet{
            //         top:-120px;
            //         left:60px
            //     }
            //     @include sp{
            //         position: static; 
            //         margin-top: 10px;
            //         margin-bottom: 20px;
            //         margin-left: 0px;
            //         z-index: 1;
            //     }
            // }
        }
    }
    .c-tabs-airdrop{
        @include tablet{
            display: block;
        }  
        display: flex;
        justify-content: space-between;
        align-items: center; 
    } 
    .airdrop-grid{
        .c-list{
            &__item{
                padding-bottom: 0px;
            }  
        }
        .airdrop-btn{ 
            position: initial;
            padding-top: 40px;
            .c-btn {
                background: #FFD60A  ;
                color:#1A1C1E ;
                &.disable{
                    background: #575759 !important;
                    color:#000 !important;
                }
            }
            .countdown{
                display: flex;
                padding:0;
                justify-content: flex-end;
                img{
                    margin-right: 5px;
                }
            }
        }
    }
    .airdrop-list{
        width: 100%;
        .c-list {
            &__status{
                font-size: 14px;
                padding:5px 0;
            }
            &__img{
                width: 15%;
                @include tablet{
                    width: 20%;
                    img{
                        height: auto;
                    }
                }
                @include sp{
                    width: 50%;
                }
            }
            &__item{
                width: 100%;
                display: flex;
                padding-bottom: 0px;
                justify-content: space-between; 
                align-items: center;
                padding: 10px;
                @include sp{
                    flex-wrap: wrap;
                } 
            }
            &__mainhead{
                width: 15%;
                @include tablet{
                    width: 20%;
                }
                @include sp{
                    width: 48%;
                    margin-left: 5px;
                }
            }
            &__head{
                align-items: flex-start;
                @include tablet{
                    font-size: 16px;
                } 
                @include sp{ 
                    margin-bottom: 5px;
                }
            }
            &__detail{
                text-align: left;
                @include tablet{
                    a{
                        font-size: 14px;
                    } 
                } 
                @include sp{ 
                    margin-top: 0px; 
                    a{
                        font-size: 14px;
                    }
                }
            } 
            &__body{
                display: flex;
                width: calc(70% - 185px); 
                @include tablet{
                    flex-wrap: wrap;
                    width: 60%;
                    padding: 10px 10px;
                }
                @include sp{ 
                    width: 100%;
                }
            }
            &__row{
                display: block;
                margin-left: 20px;
                width: calc(100%/2 - 75px);
                
                @include tablet{ 
                    width: 44%;
                }
                @include sp{
                    width: 45%;
                    margin-left: 10px;
                    margin-top: 5px;
                }
                &:last-child {
                    width: 90px;
                }
                span{
                    margin-right: 0px;
                    margin-bottom: 10px; 
                    &:first-child{
                        width: 100%;
                    }
                    &:last-child{
                        width: 100%;
                        text-align: left;
                        justify-content: flex-start;
                        line-height: 24px;
                    }
                    @include tablet{ 
                        font-size: 14px;
                    }
                    @include sp{ 
                        font-size: 12px;
                    }
                }
            }
           
            &__footer{ 
                display: flex;
                p{
                    display: blocks;
                }
                &.airdrop-btn{ 
                    width: 185px;
                    position: initial; 
                    .btn-upcoming{
                        width: 100%;
                        padding:8px;
                        span{
                            width: 100%;
                            font-size: 14px;  
                            text-align: center;
                        }
                        img{
                            display: inline-block;
                        }
                        @include tablet{ 
                            img{
                                display: none;
                            }
                            span{
                                width: 100%;
                                font-size: 14px; 
                                text-align: center;
                            }
                        } 
                        @include sp { 
                           position: static;
                        }
                        .upcoming-start{
                            display: flex;
                            align-items: center;  
                        }
                    }
                    .c-btnWallet {
                        font-size: 14px;
                    }
                    @include tablet{  
                        margin-top: auto; 
                        padding:0px;
                        width: 20%;
                        position: absolute;
                        left: 70%;
                        margin-bottom: 20px;
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 50%; 
                        margin-top: -70px;
                        padding-left:0px ; 
                        position: static;
                    }
                    .c-btn {
                        background: #FFD60A  ;
                        color:#1A1C1E ;
                        &.disable{
                            background: #575759 !important;
                            color:rgb(65, 65, 65) !important;
                        }
                    }
                    p{
                        display: block;
                        span {
                            &:first-child{
                                text-align: center;
                                width: 100%;
                                margin-bottom: 5px;
                                }
                            }
                    }
                    .countdown{
                        display: flex; 
                        width: 100%;
                        img{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.c-listCate {
    display: block;
    &:after {
        display: block;
        content: "";
        clear: both;
    }
    &__item {
        float: left;
        width: calc(100% - 380px);
        margin-left: 20px;
        margin-bottom: 17px;
        position: relative;
        padding: 10px;
        background: #1D1E24;
        border-radius: 3px;
        display: flex;
        flex-wrap: wrap;
        @include max(1280px) {
            width: calc(100% - 240px);
            margin-bottom: 10px;
            padding: 6px;
        }
        @include sp {
            width: 100%;
            margin: 20px 0 0;
            float: none;
            padding: 10px;
        }
        &:first-child {
            width: 360px;
            margin: 0;
            padding: 0;
            @include max(1280px) {
                width: 220px;
            }
            @include sp {
                width: 100%;
                margin: 0;
            }
            .c-listCate__body {
                width: 100%;
                padding: 25px 15px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 3;
            }
            .c-listCate__img {
                width: 100%;
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(359.96deg, #000000 0.03%, rgba(0, 0, 0, 0) 99.97%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                }
                img {
                    height: auto;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__img {
        position: relative;
        width: 246px;
        @include max(1280px) {
            width: 155px;
        }
        @include sp {
            width: 100%;
        }
        img {
            width: 100%;
            position: relative;
            z-index: 1;
            object-fit: cover;
            height: 160px;
            @include max(1280px) {
                height: 100px;
            }
            @include sp {
                height: auto;
            }
        }
    }
    &__body {
        width: calc(100% - 246px);
        padding-left: 16px;
        @include max(1280px) {
            width: calc(100% - 155px);
        }
        @include sp {
            width: 100%;
            padding: 16px 0 6px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        padding: 0;
        margin: 0 0 16px;
        @include max(1280px) {
            margin: 0 0 6px;
        }
        &:after {
            content: '';
            width: 16.24px;
            height: 15.49px;
            background: url(../images/gameHub-iconTitle.png) no-repeat center;
            background-size: 100% 100%;
            display: inline-block;
            margin-left: 11px;
            margin-bottom: -3px;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #C4C4C4;
        margin: 0 0 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include max(1280px) {
            -webkit-line-clamp: 2;
            margin: 0 0 16px;
        }
        p {
            margin: 0;
            span {
                color: #C4C4C4 !important;
            }
        }
    }
    &__price {
        display: flex;
        align-items: center;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.3px;
            color: #FFFFFF;
            margin: 0;
            span {
                font-size: 12px;
                line-height: 22px;
                display: flex;
                align-items: center;
                letter-spacing: 0.3px;
                margin-left: 10px;
                &.up {
                    color: #40BA7F;
                }
                &.down {
                    color: #FA4464;
                }
            }
        }
    }
    &__logo {
        display: block;
        width: 31px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        @include max(1280px) {
            width: 20px;
        }
        img {
            display: block;
            width: 100%;
        }
    }
}

.c-listRecommen {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -22px;
    @include max(1280px) {
        margin: 0 -12px;
    }
    @include sp {
        margin: 0;
    }
    &__item {
        width: calc(100%/3 - 44px);
        margin: 0 22px 30px;
        padding: 15px;
        background: #1D1E24;
        border: 1px solid #1D1E24;
        box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.6);
        border-radius: 3px;
        transition: .3s ease-in-out;
        @include max(1280px) {
            width: calc(50% - 24px);
            margin: 0 12px 25px;
        }
        @include sp {
            width: 100%;
            margin: 0 0 20px;
        }
        &:hover {
            border: 1px solid #FFD60A;
        }
    }
    &__img {
        width: 100%;
        img {
            display: block;
            width: 100%;
        }
    }
    &__body {
        padding-top: 16px;
        padding-bottom: 31px;
        position: relative;
    }
    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        padding: 0;
        margin: 0 0 14px;
        position: relative;
        &:after {
            content: '';
            width: 16.24px;
            height: 15.49px;
            background: url(../images/gameHub-iconTitle.png) no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            top: 2px;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #C4C4C4;
        margin: 0 0 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @-moz-document url-prefix() {
            p {
                display: none;
                &:first-child {
                    display: block;
                }
            }
        }
        p {
            margin: 0;
            span {
                color: #C4C4C4 !important;
            }
        }
    }
    &__price {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.3px;
            color: #FFFFFF;
            margin: 0;
            span {
                font-size: 12px;
                line-height: 22px;
                display: flex;
                align-items: center;
                letter-spacing: 0.3px;
                margin-left: 10px;
                &.up {
                    color: #40BA7F;
                }
                &.down {
                    color: #FA4464;
                }
            }
        }
    }
    &__logo {
        display: block;
        width: 31px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        img {
            display: block;
            width: 100%;
        }
    }
}

.c-sliderBanner {
    &__item {
        width: 100%;
        position: relative;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #000000 -6.37%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    &__content {
        width: calc(100% - 600px);
        padding: 20px 20px 40px 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        @include max(1280px) {
            width: 100%;
            bottom: 35%;
        }
        @include tablet {
            bottom: 90px;
        }
        @include sp {
            bottom: 17%;
            padding: 20px 20px 20px 25px;
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            margin: 0 0 16px;
            @include sp {
                font-size: 22px;
                line-height: 32px;
            }
            span {
                display: block;
                margin-bottom: 8px;
            }
        }
        p {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.3px;
            color: #FFFFFF;
            span {
                display: block;
                font-size: 12px;
                line-height: 22px;
                letter-spacing: 0.3px;
                margin-right: 10px;
                &:first-child {
                    width: 31px;
                    margin-right: 10px;
                    img {
                        width: 100%;
                    }
                }
                &:last-child {
                    margin-left: 10px;
                }
                &.up {
                    color: #40BA7F;
                }
                &.down {
                    color: #FA4464;
                }
            }
        }
        button {
            margin-top: 42px;
            appearance: none;
            background: #FFD60A;
            border-radius: 10px;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #000000;
            padding: 10px 25px;
            border: 0;
            cursor: pointer;
            transition: .3s ease-in-out;
            @include max(1280px) {
                margin-top: 20px;
            }
            &:hover {
                opacity: .7;
            }
        }
    }
}

.c-sliderNav {
    width: 550px;
    position: absolute;
    bottom: 30px;
    right: 70px;
    z-index: 3;
    @include max(1280px) {
        width: calc(100% - 164px);
        bottom: 20px;
        right: 82px;
    }
    @include tablet {
        width: 450px;
        right: auto;
        left: 82px;
    }
    @include sp {
        width: calc(100% - 134px);
        bottom: 20px;
        left: 67px;
    }
    .slick-slider {
        .slick-slide {
            width: 180px;
            padding: 0 14px;
            cursor: pointer;
            @include max(1280px) {
                padding: 0 10px;
            }
            img {
                display: block;
                width: 100%;
                border-radius: 2px;
                transition: .3s ease-in-out;
            }
            &.slick-current {
                img {
                    border: 1px solid #FFD60A;
                }
            }
            &:hover {
                img {
                    border: 1px solid #FFD60A;
                }
            }
        }
        .slick-prev,
        .slick-next {
            width: 42px;
            height: 42px;
            transition: .3s ease-in-out;  
            @include max(1280px) {
                width: 32px;
                height: 32px;
            }      
            &:before {
                display: none;
            }
            &:hover {
                opacity: .7;
            }
        }
        .slick-prev {
            left: -42px;
            background: url(../images/icon-prev.png) no-repeat center;
            background-size: cover;         
        }
        .slick-next {
            right: -42px;
            background: url(../images/icon-next.png) no-repeat center;
            background-size: cover;
        }
    }
}

@keyframes spin {
	0% {
    transform: rotate(0deg);
  }
	100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text {
  0%  {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.airdrop-grid{
    .duration{
        white-space: pre-line;
    }
}

.ino_detail_ask{
    display: flex;
    .c-tabs__ask{ 
        position: relative;
        margin-left: 10px;
        top:0;
        right:0;
    } 
}

 .stake{
    height: auto; 
    padding:14px 20px; 
    margin-right: 0px;
    &:hover{
        color:#000
    }
 }

.c-sliderAirdrop {
    margin: -35px -25px 35px -40px;
    position: relative;
    @include min {
        margin: -35px -75px 75px -75px;
    }
    &__item {
        img {
            width: 100%;
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        li {
            width: 11px;
            height: 11px;
            margin: 0 10px;
            &:hover,
            &.slick-active {
                button {
                    background: #F67519;
                    transform: scale(1.25);
                }
            }
            button {
                background: rgba(255, 255, 255, 0.4);
                backdrop-filter: blur(4px);
                width: 11px;
                height: 11px;
                border-radius: 50%;
                padding: 0;
                margin: 0;
                transition: .3s ease-in-out;
                &::before {
                    display: none;
                }
            }
        }
    }
    .slick-next,
    .slick-prev {
        width: 30px;
        height: 54px;
        position: absolute;
        z-index: 2;
        transition: .3s ease-in-out;
        @include tablet {
            width: 19px;
            height: 35px;
        }
        &::before {
            display: none;
        }
    }
    .slick-prev {
        background: url(../images/arrow-slider-airdrop.png) no-repeat center;
        background-size: cover;
        left: 40px;
        &:hover,
        &:focus {
            background: url(../images/arrow-slider-airdrop.png) no-repeat center;
            background-size: cover;
            opacity: .5;
        }
    }
    .slick-next {
        background: url(../images/arrow-slider-airdrop-2.png) no-repeat center;
        background-size: cover;
        right: 40px;
        &:hover,
        &:focus {
            background: url(../images/arrow-slider-airdrop-2.png) no-repeat center;
            background-size: cover;
            opacity: .5;
        }
    }
}

.soldout{
    background: #0F0F0F;
    padding:5px;
    border: 1px solid #F73D1E;
    border-radius: 5px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-16.06deg); 
    @include sp{
        padding:3px;
    }
    &_text{
        color: #F73D1E;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        @include sp{
            font-size: 10px; 
            letter-spacing: 0em;
        }
    }
}

.upcoming-start-airdrop{
    display: flex;
    align-items: center;
    span{
        font-size: 12px !important;
        width: 100% !important;
    }
}
.btn-upcoming-airdrop{
    display: flex;
    justify-content: space-between;
}

.ant-tabs-nav-more{
    display: none;
}
.pagiation-custom{
    .ant-pagination-item{
        min-width: 37px;
        height: 37px;
        line-height: 35px;
    }
    .ant-pagination-item a{
        font-family: AeonikRegular;
        font-size: 16px;
    }
    .ant-pagination-item-active{
        border: none;
        background: linear-gradient(274.71deg, #19B4CD 4.29%, #5EE7D5 97.42%);
        a{
            color: #fff !important;
            font-family: AeonikRegular;
        }
    }
    .ant-pagination-item:hover{
        border: 1PX solid #19B4CD;
    }
}
.pt-0{
    padding-top: 0px !important;
}
.mt-0{
    margin-top: 0px !important;
}
.p-coming-soon{
    margin-top: 70px;
    margin-bottom: 100px;
    height: 100vh;
    background: url(../images/coming-soon.png) no-repeat center;
    background-size: 100% 100%;
    
  
    .coming-title{
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: AeonikBold;
        font-size: 62px;
        padding-top: 20%;
    }
    .coming-description {
        color: #0C434B;
        text-align: center;
        font-family: AeonikRegular;
        font-size: 18px;
    }
}

.p-wrongnet {
    margin-top: 70px;
    margin-bottom: 100px;
    height: 100vh;
    background: url(../images/wrong-net.png) no-repeat center;
    background-size: 100% 100%;


    
    .wrongnet-title {
        background: var(--d, linear-gradient(92deg, #5EE7D5 9.22%, #0C434B 93.62%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: AeonikBold;
        font-size: 62px;
        padding-top: 20%;
    }

    .wrongnet-description {
        color: #0C434B;
        text-align: center;
        font-family: AeonikRegular;
        font-size: 18px;
    }
}

.spin-loader{
    animation-name: spinLoader;
    animation-duration: 12s, 12s, 12s;
    animation-iteration-count: infinite, infinite, infinite;
    -webkit-animation-timing-function: ease-in-out, linear, linear;
    transform: rotateX(6deg) rotateY(6deg) rotateZ(6deg);
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
@keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}